import { IDBCity, IDBScarf, IDBStadium, IDBTeam } from '../interfaces/dbInterfaces';
import { IStadium, ITeam, IHonours, ICity, IScarf } from '../interfaces/clientInterfaces';
import { Localizer } from './localizer';


export class Converters {

	public static getClientTeamFromDBTeam = (
		dbTeam: IDBTeam,
		dbStadium: IDBStadium,
		dbCity: IDBCity,
		overallRanking: number,
		seasonsTopPercentage: number,
		honours: IHonours,
		noTitleRanking: number,
		seasonsTopTotal: number,
		seasonsTopTotal2: number
	): ITeam => {

		let category = 0;
		let categoryString = '';
		const categoryParsed = typeof dbTeam.category !== 'undefined'
			? parseInt(dbTeam.category)
			: null;
		if (categoryParsed !== null && !Number.isNaN(categoryParsed)) {
			category = categoryParsed;
			categoryString = Localizer.getCategoryString(dbTeam.country_code, category, dbCity, dbTeam.country_code2) || '';
		} else {
			category = 10; // ???
			categoryString = typeof dbTeam.dissolved === 'undefined' ? 'Amateur' : '';
		}
		return {
			teamId: dbTeam.team_id,
			teamCode: dbTeam.team_code,
			countryCode: dbTeam.country_code,
			name: dbTeam.name,
			founded: typeof dbTeam.founded !== 'undefined' ? dbTeam.founded : null,
			dissolved: typeof dbTeam.dissolved !== 'undefined' ? dbTeam.dissolved : null,
			category,
			categoryString,
			firstColor: dbTeam.firstColor,
			secondColor: dbTeam.secondColor,
			thirdColor: dbTeam.thirdColor,
			seasonsTop: dbTeam.seasonsTop || 0,
			seasonsTop2: dbTeam.seasonsTop2 || 0,
			countryCode2: dbTeam.country_code2,
			stadium: Converters.getClientStadiumFromDBStadium(dbStadium),
			city: Converters.getClientCityFromDBCity(dbCity),
			overallRanking,
			seasonsTopPercentage,
			honours,
			noTitleRanking,
			seasonsTopTotal,
			seasonsTopTotal2,
			shields: dbTeam.shields?.split(';') ?? []
		};
	}

	public static getClientCityFromDBCity = (
		dbCity: IDBCity
	): ICity => {
		return {
			cityId: dbCity.city_id,
			name: dbCity.name,
			population: dbCity.population,
			region: dbCity.region,
			country: dbCity.country
		};
	}

	public static getClientStadiumFromDBStadium = (
		dbStadium: IDBStadium
	): IStadium => {
		return {
			stadiumId: dbStadium.stadium_id,
			cityId: dbStadium.city_id,
			name: dbStadium.name,
			north: dbStadium.north,
			east: dbStadium.east,
			capacity: dbStadium.capacity,
			opened: dbStadium.opened
		};
	}

	public static getClientScarfFromDBScarf = (
		dbScarf: IDBScarf
	): IScarf => {
		return {
			scarfCode: dbScarf.scarfCode,
			teamId: dbScarf.team_id,
			matchId: dbScarf.matchId,
			exchangeable: dbScarf.exchangeable,
			date: dbScarf.date
		};
	}
}
