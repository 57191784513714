import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { ITeamInfoRendererProps } from '../../interfaces';
import { EnumSection, IScarfInfo } from '../../interfaces/clientInterfaces';
import { Link } from 'react-router-dom';
import ShieldsRenderer from './ShieldsRenderer';
import { StyledAnimatedOnHover } from './SideTree';
import { ALL_COUNTRIES_NAME, SHOW_MAP_STADIUM_KEY } from '../constants';
import { Competitions } from '../../utils/competitions';
import { Localizer } from '../../utils/localizer';

//#region styled Components
interface IGroupContainer {
	isMapInfo?: boolean;
	isHomeSection?: boolean;
	isReducedView?: boolean;
	isMobileViewActive?: boolean;
}
const GroupContainer = styled.div<IGroupContainer>`
	margin: ${props => props.isMapInfo
		? 'auto auto 0px auto'
		: props.isHomeSection
			? 'auto'
			: props.isReducedView
				? 'auto auto 30px auto'
				: props.isMobileViewActive
					? 'auto auto 25px auto'
					: 'auto auto 70px auto'
};
	width: ${props => props.isMapInfo ? 'auto' : '100%'};
	user-select: none;
`;

interface ITeamFlexContainer {
	isReducedView: boolean;
}
const TeamFlexContainer = styled.div<ITeamFlexContainer>`
	background-color: transparent;
	flex: 1;
	display: flex;
	flex-direction: ${props => props.isReducedView ? 'row' : 'column'};
	${props => props.isReducedView ? 'max-width: 300px; margin: auto;' : ''}
`;

const TeamInfoIcon = styled.i`
	cursor: pointer;
`;

interface ITeamShieldsContainer {
	isReducedView: boolean;
	isMobileViewActive: boolean;
}
const TeamShieldsContainer = styled.div<ITeamShieldsContainer>`
	flex: 1;
	display: flex;
	margin-top: ${props => props.isReducedView ? '0' : props.isMobileViewActive ? '10px' : '15px'};

`;

interface IScarfContainer {
	isMapInfo?: boolean;
	isHomeSection?: boolean;
	isMobileViewActive: boolean;
}
const ScarfContainer = styled.div<IScarfContainer>`
	display: flex; 
	flex-direction: column;
	margin-top: ${props => props.isMapInfo || props.isMobileViewActive ? '0px' : '15px'};
	max-height: ${props => props.isMapInfo ? '150px' : props.isHomeSection ? '100px' : 'auto'};
	overflow: ${props => props.isMapInfo || props.isHomeSection ? 'auto' : 'hidden'};
`;

interface INameContainer {
	color: string;
	backgroundColor: string; 
	borderColor: string;
	isMobileViewActive: boolean;
}
const NameContainer = styled.div<INameContainer>`
	color: ${props => props.color};
	background-color: ${props => props.backgroundColor};
	border: ${props => `3px solid ${props.borderColor}`};
	height: ${props => props.isMobileViewActive ? '25px' : '30px'};
	line-height: ${props => props.isMobileViewActive ? '25px' : '30px'};
	width: 220px;
	text-align: center;
	font-weight: 700;
	margin: auto;
	user-select: none;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	padding: 0 5px 0 5px;
`;

interface IInfoFlexContainer {
	isMobileViewActive: boolean;
}
const InfoFlexContainer = styled.div<IInfoFlexContainer>`
	display: flex;
	margin: auto;
	font-size: ${props => props.isMobileViewActive ? '11px' : '12px'};
`;

const TrohpiesInfoFlexContainer = styled.div`
	margin: auto 7px auto 7px;
	display: flex;
	font-size: 12px;
	flex-direction: column;
`;

interface IInfoFlexContainer {
	textAlign?: string;
}
const InfoContainerColumn = styled.div<IInfoFlexContainer>`
	flex: 1;
	text-align: ${props => props.textAlign || 'left'}
	padding: 7px;
	text-shadow: 2px 2px 2px black;
`;

const InfoContainerColumnRow = styled.div`
	flex: 1;
`;

const StadiumInfoContainerColumnRow = styled(InfoContainerColumnRow)`
	display: inline-block;
`;

interface IImage {
	isRunnerUp?: boolean;
	isMobileViewActive: boolean;
}
const Image = styled.img<IImage>`
	max-height: ${props => props.isMobileViewActive ? '55px' : '70px'};
	filter: ${props => props.isRunnerUp ? 'opacity(0.7)' : 'drop-shadow(2px 8px 7px gold)'};
`;

interface ITrophiesContainer {
	isHomeSection?: boolean;
	isMobileViewActive?: boolean;
}
const TrophiesContainer = styled.div<ITrophiesContainer>`
	display: flex;
	margin: auto;
	width: 100%;
	flex-flow: ${props => props.isHomeSection ? 'row' : 'row wrap'};
	justify-content: ${props => props.isHomeSection ? 'space-between' : 'center'};
	overflow-x: ${props => props.isHomeSection ? 'auto' : 'hidden'};
	max-width: ${props => props.isMobileViewActive ? '100%' : '90%'}
`;

const TrophyContainer = styled.div`
	margin-right: 16px;
`;

interface ICircleContainer {
	isRunnerUp: boolean;
}
const CircleContainer = styled.div<ICircleContainer>`
	align-items: center;
	display: flex;
	height: 23px;
	justify-content: center;
	max-width: 100%;
	overflow: auto;
	position: relative;
	top: -8px;
	left: 8px;
	svg {
		height: 100%;
	}
	circle {
		fill: ${props => props.isRunnerUp ? '#6c7a86' : '#DAA520'};
	}
	text { 
		fill: ${props => props.isRunnerUp ? '#fff' : '#222'};
		font-size: 4rem;
		font-family: Europa-Big;
	}
`;
interface IImageRenderer {
	isMapInfo?: boolean;
	isHomeSection?: boolean;
	isMobileViewActive?: boolean;
}
const ImageRenderer = styled.img<IImageRenderer>`
	width: ${props => props.isHomeSection ? '90%' : props.isMobileViewActive ? '90vw' : props.isMapInfo ? '400px' : '575px;'};
	max-width: 575px;
	margin: auto auto 10px auto;
`;

const MapIconContainer = styled(StyledAnimatedOnHover)`
	display: inline-block;
	margin-left: 5px;
	:hover {
		color: black;
		i {
			color: #F89504;
		}
	}
	a {
		color: transparent;
	}
	i {
		font-size: 12px;
		line-height: 12px;
		cursor: pointer;
		color: white;
		text-shadow: 2px 2px 2px black;
	}
`;

const ImageLinkContainer = styled.img`
	max-height: 11px;
	margin: auto 0 auto 5px;
	display: flex;
`;

const ContainerNameAndFlag = styled.div`
	display: flex;
`;

const DetailSymbolContainer = styled.div`
	display: flex;
`;

const DetailSymbol = styled.div`
	height: 15px;
  	width: 15px;
  	background-color: #F89504;
  	border-radius: 50%;
	display: flex;
	cursor: pointer;
	margin: auto 5px auto 0;
	position: relative;
	i {
		font-size: black;
		color: black;
		margin: auto;
		font-size: 10px;
	}
`;

//#endregion
class TitleCircle extends PureComponent<{ number: number, isRunnerUp: boolean }, { }> {
	public render() {
		return (
			<CircleContainer isRunnerUp={this.props.isRunnerUp}>
				<svg viewBox='0 0 140 140' preserveAspectRatio='xMinYMin meet'>
					<g>
						<circle r='50%' cx='50%' cy='50%' className='circle-back' />
						<text x='50%' y='50%' textAnchor='middle' dy='0.3em'>
							{this.props.number}	
						</text>
					</g>
				</svg> 
			</CircleContainer>
		);
	}
}

interface ITeamInfoRendererDevState {
	clickIndex: number;
}

export default class TeamInfoRenderer extends PureComponent<ITeamInfoRendererProps, ITeamInfoRendererDevState> {

	constructor(props: ITeamInfoRendererProps) {
		super(props);
		this.state = {
			clickIndex: 0
		};
	}

	private _onShieldClick = () => {
		let clickIndex = this.state.clickIndex;
		if (this.state.clickIndex % 2 === 0) {
			clickIndex++;
			this.setState({
				clickIndex
			});
		}
	}

	private _onTitleClick = () => {
		let clickIndex = this.state.clickIndex;
		if (this.state.clickIndex % 2 !== 0) {
			clickIndex++;
			if (clickIndex > 3) {
				this.setState({
					clickIndex: 0
				});
				if (this.props.onSetHonoursVisibility) {
					this.props.onSetHonoursVisibility();
				}
			} else {
				this.setState({
					clickIndex
				});
			}
		}
	}

	private _onTrophyClick = (e: React.MouseEvent<HTMLDivElement>, title: string, editions: string, description: string | undefined) => {
		e.persist();
		if (this.props.onTrophyClickCallback) {
			this.props.onTrophyClickCallback(e.clientX, e.clientY, title, editions, description);
		}
	}

	private _renderChampionHonours = () => {
		return this.props.scarfInfo.team.honours.champion.map((x, i) => {
			const title = `${x.name} Champion: `;
			const editions = x.editions.map(e => e.text).join(', ');
			return (
				<TrophyContainer key={`championTrophy-${i}`} onClick={e => this._onTrophyClick(e, title, editions, x.description)}>
					<Image
						src={`./../../img/trophy/${x.competitionCode}${x.lastTrophy ? `_${x.lastTrophy}`: ''}.png`}
						title={title}
						isMobileViewActive={this.props.isMobileViewActive}
					/>
					<TitleCircle number={x.editions.length} isRunnerUp={false} />
				</TrophyContainer>
			);
		});
	}

	private _renderRunnerUpHonours = () => {
		return this.props.scarfInfo.team.honours.runnerUp.map((x, i) => {
			const title = `${x.name} Runner Up: `;
			const editions = x.editions.map(e => e.text).join(', ');
			return (
				<TrophyContainer key={`runnerUpTrophy-${i}`} onClick={e => this._onTrophyClick(e, title, editions, x.description)}>
					<Image
						src={`./../../img/trophy/${x.competitionCode}${x.lastTrophy ? `_${x.lastTrophy}`: ''}.png`}
						title={title}
						isRunnerUp={true}
						isMobileViewActive={this.props.isMobileViewActive}
					/>
					<TitleCircle number={x.editions.length} isRunnerUp={true} />
				</TrophyContainer>
			);
		});
	}

	private _onCloseClick = () => {
		if (this.props.closeTeamRenderer) {
			this.props.closeTeamRenderer();
		}
	}

	private _renderCloser = () => {
		if (!this.props.isMapInfo) {
			return null;
		}
		return (
			<TeamInfoIcon
				className='fa fa-times'
				onClick={this._onCloseClick}
			/>
		);
	}
	// LEGACY
	// private _setHighlightsPopUp = () => {
	// 	if (this.props.setHighlightsPopUp) {
	// 		this.props.setHighlightsPopUp(this.props.scarfInfo.team.teamId);
	// 	}
	// }
	// private _renderTeamHighlightsIcon = () => {
	// 	if (this.props.scarfInfo.team.highlights.length === 0 || this.props.section === EnumSection.home || this.props.section === EnumSection.map) {
	// 		return null;
	// 	}
	// 	return (
	// 		<HighlightsIconCotainer>
	// 			<TeamInfoIcon
	// 				className='fa fa-video'
	// 				title={`Highlights of ${this.props.scarfInfo.team.name} history`}
	// 				onClick={this._setHighlightsPopUp}
	// 			/>
	// 		</HighlightsIconCotainer>
	// 	);
	// }

	private _renderMapIcon = () => {
		if (this.props.isMapInfo || this.props.section === EnumSection.home) {
			return null;
		}
		return (
			<MapIconContainer>
				<Link to={`/map?center=${this.props.scarfInfo.team.name}`}>
					<i className='fa fa-map-marker' title={SHOW_MAP_STADIUM_KEY}/>
				</Link>
			</MapIconContainer>
		);
	}

	private _renderStadiumInfo = () => {
		return (
			<StadiumInfoContainerColumnRow>
				{`Stadium: ${this.props.scarfInfo.stadium.name}`}
				{this._renderMapIcon()}
			</StadiumInfoContainerColumnRow>
		);
	}

	private _renderNameContainer = () => {
		return (
			<NameContainer
				color={`#${this.props.scarfInfo.team.secondColor}`}
				backgroundColor={`#${this.props.scarfInfo.team.firstColor}`}
				borderColor={`#${this.props.scarfInfo.team.thirdColor}`}
				title={this.props.scarfInfo.team.name}
				onClick={this._onTitleClick}
				isMobileViewActive={this.props.isMobileViewActive}
			>
				{this.props.scarfInfo.team.name}
			</NameContainer>
		);
	}

	private _renderShieldContainer = () => {
		return (
			<TeamFlexContainer
				isReducedView={this.props.isReducedView ? true : false}
				onClick={this._onShieldClick}
			>
				<TeamShieldsContainer
					isReducedView={this.props.isReducedView ? true : false}
					isMobileViewActive={this.props.isMobileViewActive}
				>
					<ShieldsRenderer
						teamId={this.props.scarfInfo.team.teamId}
					/>
				</TeamShieldsContainer>
				{this.props.isReducedView ? this._renderNameContainer() : null}
			</TeamFlexContainer>
		);
	}

	private _renderInfoAndTrophiesContainer= () => {
		if (this.props.isReducedView) {
			return null;
		}
		const seasonsTop = Competitions.getSeasonsInTopCategoryString(this.props.scarfInfo.team);
		return (
			<>
				<InfoFlexContainer
					isMobileViewActive={this.props.isMobileViewActive}
				>
					<InfoContainerColumn
						isMobileViewActive={this.props.isMobileViewActive}
						textAlign={'right'}
					>
						{this.props.scarfInfo.city.name ? this._renderCityAndFlag(this.props.scarfInfo) : null}
						{this.props.scarfInfo.team.founded ? <InfoContainerColumnRow>{`Founded: ${this.props.scarfInfo.team.founded}`}</InfoContainerColumnRow> : null}
						{seasonsTop ? <InfoContainerColumnRow>{`Season in top category: ${seasonsTop}`}</InfoContainerColumnRow> : null}
						{/* {<InfoContainerColumnRow>{`Seasons Top Percentage: ${this.props.scarfInfo.team.seasonTopPercentage}`}</InfoContainerColumnRow>} */}
						{this.props.areTeamHonoursVisible && this.props.scarfInfo.team.overallRanking ? <InfoContainerColumnRow>{`Ranking: ${this.props.scarfInfo.team.overallRanking}`}</InfoContainerColumnRow> : null}
						{this.props.areTeamHonoursVisible && this.props.scarfInfo.team.honours.numberOfTitles ? <InfoContainerColumnRow>{`Number of titles: ${this.props.scarfInfo.team.honours.numberOfTitles}`}</InfoContainerColumnRow> : null}
						{this.props.areTeamHonoursVisible ? <InfoContainerColumnRow>{`Id: ${this.props.scarfInfo.team.teamId}`}</InfoContainerColumnRow>: null}
					</InfoContainerColumn>
					<InfoContainerColumn
						isMobileViewActive={this.props.isMobileViewActive}
					>
						{this.props.scarfInfo.stadium.name ? this._renderStadiumInfo() : null}
						{this.props.scarfInfo.stadium.capacity ? <InfoContainerColumnRow>{`Capacity: ${this.props.scarfInfo.stadium.capacity}`}</InfoContainerColumnRow> : null}
						{/* {this.props.scarfInfo.stadium.opened ? <InfoContainerColumnRow>{`Opened: ${this.props.scarfInfo.stadium.opened}`}</InfoContainerColumnRow> : null} */}
						{this.props.scarfInfo.team.categoryString ? <InfoContainerColumnRow>{`Category: ${this.props.scarfInfo.team.categoryString}`}</InfoContainerColumnRow> : null}
						{this.props.scarfInfo.team.dissolved ? <InfoContainerColumnRow>{`Dissolved: ${this.props.scarfInfo.team.dissolved}`}</InfoContainerColumnRow> : null}
						{this.props.areTeamHonoursVisible && this.props.scarfInfo.team.noTitleRanking ? <InfoContainerColumnRow>{`No Title Ranking: ${this.props.scarfInfo.team.noTitleRanking}`}</InfoContainerColumnRow> : null}
						{this.props.areTeamHonoursVisible ? <InfoContainerColumnRow>{`Seasons Top Percentage: ${this.props.scarfInfo.team.seasonsTopPercentage}`}</InfoContainerColumnRow>: null}
						{}
					</InfoContainerColumn>
				</InfoFlexContainer>
				<TrohpiesInfoFlexContainer>
					<TrophiesContainer
						isHomeSection={this.props.section === EnumSection.home && (this.props.scarfInfo.team.honours.completeChampion.length + this.props.scarfInfo.team.honours.runnerUp.length > 4)}
						isMobileViewActive={this.props.isMobileViewActive}
					>
						{this._renderDetailSymbol()}
						{this._renderChampionHonours()}
						{this._renderRunnerUpHonours()}
					</TrophiesContainer>
				</TrohpiesInfoFlexContainer>
			</>
		);
	}

	private _onTitleDetail = (e: React.MouseEvent<HTMLDivElement>) => {
		this.props.onSetDetailTrophyTooltip(this.props.scarfInfo.team);
	}

	private _renderDetailSymbol = () => {
		if (this.props.scarfInfo.team.honours.completeChampion.length === 0 && this.props.scarfInfo.team.honours.runnerUp.length === 0) {
			return null;
		}
		return (
			<DetailSymbolContainer
				title={'See detailed titles'}
				onClick={this._onTitleDetail}
			>
				<DetailSymbol>
					<i className='fa fa-plus'/>
				</DetailSymbol>
			</DetailSymbolContainer>
		);
	}

	private _renderCityAndFlag = (scarfInfo: IScarfInfo) => {
		const countryName = Localizer.getLocalizedCountryName(scarfInfo.team.countryCode);
		return (
			<ContainerNameAndFlag>
				<InfoContainerColumnRow>{`Town: ${scarfInfo.city.name}, ${scarfInfo.city.region}`}</InfoContainerColumnRow>
				{
					this.props.section === EnumSection.gallery && this.props.currentCountry === ALL_COUNTRIES_NAME
						? <ImageLinkContainer src={`../../../img/flag/${scarfInfo.team.countryCode}.png`} alt={countryName} title={countryName} />
						: null
				}
			</ContainerNameAndFlag>
		);
	}

	public render() {
		return (
			<>	
				{this._renderCloser()}
				<GroupContainer
					isMapInfo={this.props.isMapInfo}
					isHomeSection={this.props.section === EnumSection.home}
					isReducedView={this.props.isReducedView ? true : false}
					isMobileViewActive={this.props.isMobileViewActive}
				>
					{!this.props.isReducedView ? this._renderNameContainer() : null}
					{this._renderShieldContainer()}
					{this._renderInfoAndTrophiesContainer()}
					<ScarfContainer
						isMapInfo={this.props.isMapInfo}
						isHomeSection={this.props.section === EnumSection.home}
						isMobileViewActive={this.props.isMobileViewActive}
					>
						{
							this.props.scarfInfo.scarves.map((x, i) => {
								return (
									<ImageRenderer
										key={`${this.props.scarfInfo.team.name} scarf - ${i}`}
										alt={`${this.props.scarfInfo.team.name} scarf`}
										src={`../../../img/scarf/${x.scarfCode}.jpg`}
										title={`${this.props.scarfInfo.team.name} scarf`}
										isMapInfo={this.props.isMapInfo}
										isHomeSection={this.props.section === EnumSection.home}
										isMobileViewActive={this.props.isMobileViewActive}
									/>
								);
							})
						}
					</ScarfContainer>
				</GroupContainer>
			
			</>
		);
	}
}
