import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import rootSelector from '../selectors/header';
import { IHeaderCombinedProps } from '../../interfaces';
import { EnumSection } from '../../interfaces/clientInterfaces';
import { StyledAnimatedOnHover } from './SideTree';
import * as actions from '../../actions';
import { FILTER_MATCHES_TOOLTIP, FILTER_TEAMS_TOOLTIP } from '../constants';

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
	margin: auto;
`;
interface IStyledHeader {
	isMobileViewActive?: boolean;
}
export const StyledHeader = styled(StyledAnimatedOnHover)<IStyledHeader>`
	font-family: Europa-Big;
	font-size: ${props => props.isMobileViewActive ? '15px' : '18px'};
	text-align: center;
	margin: auto;
	cursor: pointer;
	text-align: left;
	padding: 5px 7px 5px 7px;
	user-select: none;
	color: white;
	a {
		text-decoration: none;
		color: white;
		text-align: center;
	}
	:hover {
		background-color: #F89504;
		a {
			color: black;
		}
		i {
			color:  #F89504;
		}
	}
`;

const StyledRightHeader = styled(StyledAnimatedOnHover)`
	cursor: pointer;
	margin: auto;
	:hover {
		color: #F89504;
	}
`;

const FlexIconContainer = styled.div`
	flex: 0 1 40px;
	display: flex;
`;


const FlexCentralContainer = styled.div`
	flex: 1;
	display: flex;
	div {
		margin: auto;
		display: flex;
		flex-direction: row;
	}
`;

class Header extends PureComponent<IHeaderCombinedProps, { }> {

	private _onClickFilterIcon = () => {
		this.props.dispatch(actions.showFilterArea());
	}

	private _onClickTreeMapIcon = () => {
		if (this.props.showTreeMap) {
			this.props.dispatch(actions.hideTreeMap());
		} else {
			this.props.dispatch(actions.showTreeMap());
		}
	}

	private _renderTreeMapIcon = () => {
		if (!this.props.isMobileViewActive) {
			return null;
		}
		return (
			<StyledRightHeader onMouseDown={this._onClickTreeMapIcon}>
				<i className='fa fa-list'/>	
			</StyledRightHeader>
		);
	}

	private _renderFilterIcon = () => {
		if (this.props.section === EnumSection.map || this.props.section === EnumSection.home) {
			return null;
		}
		return (
			<StyledRightHeader title={this.props.section === EnumSection.rm || this.props.section === EnumSection.matches ? FILTER_MATCHES_TOOLTIP : FILTER_TEAMS_TOOLTIP}>
				<i className='fa fa-filter' onClick={this._onClickFilterIcon}/>	
			</StyledRightHeader>
		);
	}

	public render() {
		return (
			<HeaderContainer>
				<FlexIconContainer>
					{this._renderTreeMapIcon()}
				</FlexIconContainer>
				<FlexCentralContainer>
					<div>
						<StyledHeader isMobileViewActive={this.props.isMobileViewActive}>
							<Link to={'/'}>
								{'Home'}
							</Link>
						</StyledHeader>
						<StyledHeader isMobileViewActive={this.props.isMobileViewActive}>
							<Link to={'/map'}>
								{'Map'}
							</Link>
						</StyledHeader>
						<StyledHeader isMobileViewActive={this.props.isMobileViewActive}>
							<Link to={'/exchange'}>
								{'Exchange'}
							</Link>
						</StyledHeader>
						<StyledHeader isMobileViewActive={this.props.isMobileViewActive}>
							<Link to={'/links'}>
								{'Hall of fame'}
							</Link>
						</StyledHeader>
					</div>
				</FlexCentralContainer>
				<FlexIconContainer>
					{this._renderFilterIcon()}
				</FlexIconContainer>
			</HeaderContainer>
		);
	}
}

export default connect(rootSelector)(Header);

