import React, { useMemo } from 'react';
import styled from 'styled-components';
import { INumberDictionary, IStore, IStringDictionary } from '../../interfaces';
import { IAvailableCompetition, ICompetitionEvolution, ISelectedEvolutions, ITeam } from '../../interfaces/clientInterfaces';
import { useSelector } from 'react-redux';
import { Countries as CountriesUtils } from '../../utils/countries';
import { Localizer } from '../../utils/localizer';

//#region styled Components
const EvolutionContainer = styled.div`
	display: flex;
	margin: 10px;
	flex: 1;
	flex-direction: column;
	overflow-y: auto;
`;

interface ITable {
	isMobileViewActive: boolean;
}
const Table = styled.table<ITable>`
	border-collapse: collapse;
	width: 100%;
	margin: ${props => props.isMobileViewActive ? 5 : 15}px;
	background-color: rgba(255, 255, 255, 0.4);
	border-radius: 4px;
	padding: 10px;
	color: black;
	thead {
		border-bottom: 2px solid white;
		margin: 0;
		text-align: left;
	}
	tbody {
		margin: 0;
	}
`;

const TeamContainer = styled.span`
	display: flex;
	flex-direction: column;
`;

const ShieldAndFlagContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const RankingHeader = styled.th`
	max-width 15px;
`;

const Row = styled.tr`
	border-bottom: 1px solid white;
`;

const FlagImage = styled.img`
	width: 30px;
	height: auto;
	margin: auto 5px auto 0;
`;

interface IShieldImage {
	isMobileViewActive: boolean;
}
const ShieldImage = styled.img<IShieldImage>`
	width: auto;
	height: ${props => props.isMobileViewActive ? '25px' : '40px'};
	margin: auto 5px auto 0;
`;

const ScarfContainer = styled.div`
`;

interface ITable {
	isMobileViewActive: boolean;
}
const ScarfImage = styled.img<ITable>`
	width: ${props => props.isMobileViewActive ? '120px' : '300px'};
	height: auto;
	margin: auto 5px;
`;

const NameContainer = styled.div`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

`;

const TeamCell = styled.td`
	display: flex;
	padding: 10px 0;
	div {
		margin: auto 0;
	}
`;

const ResultTd = styled.td`
	padding: 15px 0;
	font-size: 20px;
`;

interface IEditionsInjectedProps {
	teamsById: INumberDictionary<ITeam>;
	evolutions: INumberDictionary<ICompetitionEvolution>; // Evolutions by competition
	availableCompetitions: IAvailableCompetition[];
	selectedEvolutions: ISelectedEvolutions[];
	isMobileViewActive: boolean;
}

interface IProcessedCountry {
	imageCode: string;
	name: string;
	championTimes: number;
	runnerUpTimes: number;
	processedCountries: IStringDictionary<ICountryTimes>;
}

interface ICountry {
	imageCode: string;
	name: string;
	championTimes: number;
	runnerUpTimes: number;
	orderedTeams: ICountryTimes[];
}

interface ICountryTimes {
	team: ITeam;
	championTimes: number;
	runnerUpTimes: number;
}

export default function Countries() {
	const {
		teamsById,
		evolutions,
		availableCompetitions,
		selectedEvolutions,
		isMobileViewActive,
	} = useSelector(
		(completeState: IStore): IEditionsInjectedProps => {
			return {
				teamsById: completeState.teams.teamsById,
				availableCompetitions: completeState.competitions.availableCompetitions,
				evolutions: completeState.competitions.evolutions,
				selectedEvolutions: completeState.competitions.selectedEvolutions,
				isMobileViewActive: completeState.ui.isMobileViewActive,
			}
		});

	const { orderedCountries } = useMemo(
		(): {
			orderedCountries: ICountry[];
		 } => {
			if (selectedEvolutions.length < 1) {
				return {
					orderedCountries: []
				};
			}
			const competitionName = selectedEvolutions[0].competitionName;
			const competition = availableCompetitions.find(competition => competition.name === competitionName);
			if (!competition) {
				return {
					orderedCountries: []
				};
			}
			const evolution = evolutions[competition.id];
			if (!evolution) {
				return {
					orderedCountries: []
				};
			}

			let processedCountries: IStringDictionary<IProcessedCountry> = { };
			for (const championId in evolution.editions[evolution.editions.length - 1].champions) {
				const team = teamsById[championId];
				if (!team) {
					continue;
				}
				const numberOfChampions = evolution.editions[evolution.editions.length - 1].champions[championId];
				if (!numberOfChampions) {
					continue;
				}
				const countries = CountriesUtils.getTitlesOfFormerCountries(team.countryCode, team.countryCode2 || '', numberOfChampions); 
				for (const country of countries) {
					if (country.numberOfTitles === 0) {
						continue;
					}
					const processedCountry = processedCountries[country.countryCode];
					if (typeof processedCountry === 'undefined') {
						processedCountries = {
							...processedCountries,
							[country.countryCode]: {
								imageCode: country.countryCode,
								name: Localizer.getLocalizedCountryName(country.countryCode),
								championTimes: country.numberOfTitles,
								runnerUpTimes: 0,
								processedCountries: {
									[team.teamId]: {
										team,
										runnerUpTimes: 0,
										championTimes: country.numberOfTitles
									}
								}
							}
						};
					} else {
						processedCountries = {
							...processedCountries,
							[country.countryCode]: {
								...processedCountry,
								championTimes: country.numberOfTitles + processedCountry.championTimes,
								processedCountries: {
									...processedCountry.processedCountries,
									[team.teamId]: {
										team,
										runnerUpTimes: 0,
										championTimes: country.numberOfTitles
									}
								}
							}
						};
					}
				}
				
			}
			for (const runnerUpId in evolution.editions[evolution.editions.length - 1].runnerUps) {
				const team = teamsById[runnerUpId];
				if (!team) {
					continue;
				}
				const numberOfRunnerUps = evolution.editions[evolution.editions.length - 1].runnerUps[runnerUpId];
				if (!numberOfRunnerUps) {
					continue;
				}
				const countries = CountriesUtils.getTitlesOfFormerCountries(team.countryCode, team.countryCode2 || '', numberOfRunnerUps);
				for (const country of countries) {
					if (country.numberOfTitles === 0) {
						continue;
					}
					const processedCountry = processedCountries[country.countryCode];
					if (typeof processedCountry === 'undefined') {
						processedCountries = {
							...processedCountries,
							[country.countryCode]: {
								imageCode: country.countryCode,
								name: Localizer.getLocalizedCountryName(country.countryCode),
								championTimes: 0,
								runnerUpTimes: country.numberOfTitles,
								processedCountries: {
									[team.teamId]: {
										team,
										runnerUpTimes: country.numberOfTitles,
										championTimes: 0
									}
								}
							}
						};
					} else {
						const runnerUpTeam = processedCountry.processedCountries[team.teamId] || {
							team,
							runnerUpTimes: 0,
							championTimes: 0	
						};
						processedCountries = {
							...processedCountries,
							[country.countryCode]: {
								...processedCountry,
								runnerUpTimes: country.numberOfTitles + processedCountry.runnerUpTimes,
								processedCountries: {
									...processedCountry.processedCountries,
									[team.teamId]: {
										...runnerUpTeam,
										runnerUpTimes: country.numberOfTitles
									}
								}
							}
						};
					}
				}
			}
			const unorderedCountries: ICountry[] = [];
			for (const processedCountry in processedCountries) {
				const country = processedCountries[processedCountry];
				if (!country) {
					continue;
				}
				const teams: ICountryTimes[] = [];
				for (const processedTeam in country.processedCountries) {
					const countryTime = country.processedCountries[processedTeam];
					if (!countryTime) {
						continue;
					}
					teams.push(countryTime)
				}
				const orderedTeams = teams.sort((a: ICountryTimes, b: ICountryTimes) => {
					if (a.championTimes > b.championTimes) {
						return -1;
					} else if (a.championTimes < b.championTimes) {
						return 1;
					} else {
						if (a.runnerUpTimes > b.runnerUpTimes) {
							return -1;
						} else if (a.runnerUpTimes <= b.runnerUpTimes) {
							return 1;
						}
					}
					return 1;
				});
				unorderedCountries.push({
					...country,
					orderedTeams
				})
			}
			const orderedCountries = unorderedCountries.sort((a: ICountry, b: ICountry) => {
				if (a.championTimes > b.championTimes) {
					return -1;
				} else if (a.championTimes < b.championTimes) {
					return 1;
				} else {
					if (a.runnerUpTimes > b.runnerUpTimes) {
						return -1;
					} else if (a.runnerUpTimes <= b.runnerUpTimes) {
						return 1;
					}
				}
				return -1;
			});
			return {
				orderedCountries
			};
		},
		[teamsById, selectedEvolutions, evolutions, availableCompetitions]
	);

	const renderCountry = (year: number, countryCode: string, countryName: string) => {
		return (
			<>
				<FlagImage src={`../../../img/flag/${CountriesUtils.getFlagOfCountry(year, countryCode, countryCode, countryCode, false)}.png`}/>
				<NameContainer>{countryName}</NameContainer>
			</>
		);
	}

	const renderTeam = (team: ITeam) => {
		return (
			<TeamContainer>
				<ShieldAndFlagContainer>
					<ShieldImage src={`../../../img/shield/${team.countryCode}_${team.teamCode}.png`} isMobileViewActive={isMobileViewActive}/>
					<NameContainer>{team.name}</NameContainer>
				</ShieldAndFlagContainer>
				<ScarfContainer>
					<ScarfImage src={`../../../img/scarf/${team.countryCode}_${team.teamCode}.jpg`} isMobileViewActive={isMobileViewActive}/>
				</ScarfContainer>
			</TeamContainer>
		);
	}

	return (
		<EvolutionContainer>
			<Table isMobileViewActive={isMobileViewActive}>
				<thead>
					<RankingHeader scope='col'>
						{'RANK'}
					</RankingHeader>
					<th scope='col'>
						{'COUNTRY'}
					</th>
					<th scope='col'>
						{'CLUB'}
					</th>
					<th scope='col'>
						{'TITLES'}
					</th>
					<th scope='col'>
						{'RUNNER-UP'}
					</th>
				</thead>
				<tbody>
					{orderedCountries.map((orderedCountry, countryIndex) => {
						return (
							<>
								{orderedCountry.orderedTeams.map((team, idx) => {
									if (idx === 0) {
										return (
											<Row>
												<RankingHeader scope='row' rowSpan={orderedCountry.orderedTeams.length > 1 ? orderedCountry.orderedTeams.length + 1 : 1}>
													{countryIndex + 1}
												</RankingHeader>
												<td rowSpan={orderedCountry.orderedTeams.length > 1 ? orderedCountry.orderedTeams.length + 1 : 1}>
													{renderCountry(2023, orderedCountry.imageCode, orderedCountry.name)}
												</td>
												<TeamCell>
													{renderTeam(team.team)}
												</TeamCell>
												<td>
													{team.championTimes}
												</td>
												<td>
													{team.runnerUpTimes}
												</td>
											</Row>
										);
									}
									return (
										<Row>
											<TeamCell>
												{renderTeam(team.team)}
											</TeamCell>
											<td>
												{team.championTimes}
											</td>
											<td>
												{team.runnerUpTimes}
											</td>
										</Row>
									);
								})}
								{
									orderedCountry.orderedTeams.length > 1 
										? <Row>
											<ResultTd>
												{`${orderedCountry.orderedTeams.length} teams`}
											</ResultTd>
											<ResultTd>
												{orderedCountry.championTimes}
											</ResultTd>
											<ResultTd>
												{orderedCountry.runnerUpTimes}
											</ResultTd>
										</Row>
										: null
								}
							</>
						);
					})}
				</tbody>
			</Table>
		</EvolutionContainer>
	);
}

