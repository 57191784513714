// Store configuration for production environment.

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from '../reducers';
import rootSagaCreator from '../sagas';

export default function configureStore() {
	// Create saga middleware
	const sagaMiddleware = createSagaMiddleware();

	// Create store with saga middleware
	const store = createStore(
		rootReducer,
		{},
		compose(
			applyMiddleware(sagaMiddleware)
		)
	);

	// Run root saga
	sagaMiddleware.run(rootSagaCreator());

	return store;
}
