import React, { useMemo } from 'react';
import styled from 'styled-components';
import { INumberDictionary, IStore } from '../../interfaces';
import { IAvailableCompetition, ICompetitionEvolution, ISelectedEvolutions, ITeam } from '../../interfaces/clientInterfaces';
import { useSelector } from 'react-redux';
import { Countries } from '../../utils/countries';

//#region styled Components
interface IEvolutionContainer {
	isMobileViewActive: boolean;
}
const EvolutionContainer = styled.div<IEvolutionContainer>`
	display: flex;
	margin: 10px;
	flex: 1;
	flex-direction: column;
	overflow-y: auto;
	margin: ${props => props.isMobileViewActive ? 5 : 15}px;
	background-color: rgba(255, 255, 255, 0.4);
	border-radius: 4px;
	padding: 10px;
	color: black;
`;

const Table = styled.table`
	border-collapse: collapse;
	width: 100%;
	thead {
		border-bottom: 2px solid #000;
		margin: 0;
		text-align: left;
	}
	tbody {
		margin: 0;
	}
`;

interface IFlagImage {
	width: number;
}
const FlagImage = styled.img<IFlagImage>`
	width: ${props => props.width}px;
	height: auto;
	margin: auto 5px auto 0;
`;

const ShieldImage = styled.img`
	width: auto;
	height: 40px;
	max-width: 60px;
	margin: auto 5px auto 0;
`;

const TeamCellHeader = styled.th`
	padding: 5px 10px;
`;

const TeamCell = styled.td`
	padding: 5px 10px;
	div {
		margin: auto 0;
	}
`;

const NameContainer = styled.div`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

const Row = styled.tr`
	border-bottom: 1px solid #000;
`;

const THead = styled.thead`
	font-family: 'Europa-big';
`;

const ShieldAndFlagContainer = styled.span`
	display: flex;
	margin: 5px auto;
`;

const ScarfContainer = styled.span`
	display: flex;
	margin: 5px auto;
`;

interface IScarfImage {
	isMobileViewActive: boolean;
}
const ScarfImage = styled.img<IScarfImage>`
	width: ${props => props.isMobileViewActive ? '120' : '200'}px;
	height: auto;
`;

interface ITBody {
	isMobileViewActive: boolean;
}
const TBody = styled.tbody<ITBody>`
	font-size: ${props => props.isMobileViewActive ? '12' : '16'}px;
`;

interface IEditionsInjectedProps {
	teamsById: INumberDictionary<ITeam>;
	evolutions: INumberDictionary<ICompetitionEvolution>; // Evolutions by competition
	availableCompetitions: IAvailableCompetition[];
	selectedEvolutions: ISelectedEvolutions[];
	isMobileViewActive: boolean;
}

interface IEditionCell {
	lastChampionId: number;
	lastRunnerUpId: number;
	description: string;
	endYear: number;
	timesChampion: number;
}

export default function Editions() {
	const {
		teamsById,
		evolutions,
		availableCompetitions,
		selectedEvolutions,
		isMobileViewActive,
	} = useSelector(
		(completeState: IStore): IEditionsInjectedProps => {
			return {
				teamsById: completeState.teams.teamsById,
				availableCompetitions: completeState.competitions.availableCompetitions,
				evolutions: completeState.competitions.evolutions,
				selectedEvolutions: completeState.competitions.selectedEvolutions,
				isMobileViewActive: completeState.ui.isMobileViewActive
			}
		});

	const { editions, competition } = useMemo(
		(): {
			editions: IEditionCell[];
			competition: IAvailableCompetition | null;
		 } => {
			if (selectedEvolutions.length < 1) {
				return {
					editions: [],
					competition: null,
				};
			}
			const competitionName = selectedEvolutions[0].competitionName;
			const competition = availableCompetitions.find(competition => competition.name === competitionName);
			const editions: IEditionCell[] = [];
			if (!competition) {
				return {
					editions,
					competition: null,
				};
			}
			const evolution = evolutions[competition.id];
			if (!evolution) {
				return {
					editions,
					competition: null,
				};
			}
			const timesChampion = new Map<number, number>();
			for (const edition of evolution.editions) {
				let timesChampionUntilEdition = timesChampion.get(edition.lastChampionId);
				if (!timesChampionUntilEdition) {
					timesChampionUntilEdition = 0;
				}
				timesChampion.set(edition.lastChampionId, timesChampionUntilEdition + 1);
				editions.unshift({
					lastChampionId: edition.lastChampionId,
					lastRunnerUpId: edition.lastRunnerUpId,
					description: edition.description,
					endYear: edition.endYear,
					timesChampion: timesChampionUntilEdition + 1
				});

			}
			return {
				editions,
				competition,
			};
		},
		[selectedEvolutions, evolutions, availableCompetitions]
	);

	const renderFlag = (year: number, team: ITeam | undefined, competition: IAvailableCompetition | null) => {
		if (!team || !competition) {
			return null;
		}
		if (competition.regionCode === team.countryCode) {
			return null;
		}
		return (
			<FlagImage
				width={team.teamCode ? 30: 35}
				src={`../../../img/flag/${Countries.getFlagOfCountry(year, competition.regionCode, team.countryCode, team.countryCode2, !team.teamCode)}.png`}
			/>
		);
	}

	const renderTeam = (year: number, team: ITeam | undefined, competition: IAvailableCompetition | null, timesChampion: number | null) => {
		if (!team) {
			return null;
		}
		const teamShield = [...team.shields].reverse().find((shield: string) => Number.parseInt(shield) >= year);
		const shieldCode = `${team.countryCode}_${team.teamCode}${teamShield ? `_${teamShield}` : ''}`;
		return (
			<>
				<ShieldAndFlagContainer>
					{renderFlag(year, team, competition)}
					{
						team.teamCode
							? <ShieldImage src={`../../../img/shield/${shieldCode}.png`}/>
							: null
					}
					{!isMobileViewActive && <NameContainer>{`${team.name} ${timesChampion !== null ? `(${timesChampion})` : ''}`}</NameContainer>}
				</ShieldAndFlagContainer>
				{isMobileViewActive && <NameContainer>{`${team.name} ${timesChampion !== null ? `(${timesChampion})` : ''}`}</NameContainer>}
				<ScarfContainer>
					<ScarfImage src={`../../../img/scarf/${team.countryCode}_${team.teamCode}.jpg`} isMobileViewActive={isMobileViewActive}/>
				</ScarfContainer>
			</>
			
		);
	}

	return (
		<EvolutionContainer isMobileViewActive={isMobileViewActive}>
			<Table>
				<THead>
					<th scope='column'>
						{'YEAR'}
					</th>
					<TeamCellHeader scope='column'>
						{'CHAMPION'}
					</TeamCellHeader>
					<TeamCellHeader scope='column'>
						{'RUNNER UP'}
					</TeamCellHeader>
				</THead>
				<TBody isMobileViewActive={isMobileViewActive}>
					{editions.map(edition => {
						return (
							<Row>
								<td>
									{edition.description}
								</td>
								<TeamCell>
									{renderTeam(edition.endYear, teamsById[edition.lastChampionId], competition, edition.timesChampion)}
								</TeamCell>
								<TeamCell>
									{renderTeam(edition.endYear, teamsById[edition.lastRunnerUpId], competition, null)}
								</TeamCell>
							</Row>
						);
					})}
				</TBody>
			</Table>
		</EvolutionContainer>
	);
}

