import React, { Component } from 'react';
import { connect } from 'react-redux';
import rootSelector from '../selectors/highlightsPopUp';
import { IHighlightsPopUpCombinedProps } from '../../interfaces';
import * as actions from '../../actions';
import styled from 'styled-components';
import PopUp from './PopUp';
import { HighlightsGallery } from './HighlightsGallery';

//#region Styled components
interface IPopUpContainerDiv {
	isMobileViewForPopUp: boolean;
}
export const PopUpContainerDiv = styled.div<IPopUpContainerDiv>`
	padding: 15px;	
	position: absolute;
	height: auto;
	min-height: 500px;
	max-width: 500px;
	width: 100%;
	top: calc(50% - 225px);
	left: ${props => props.isMobileViewForPopUp ? '0' : 'calc(50% - 250px)'};
	z-index: 51;
	display: flex;
	flex-direction: column;
	outline: none;
	transition: opacity ease-in-out .15s, opacity ease-in-out .15s;
	white-space: pre-line;
	background: url('../img/background/bck_theme.jpg') no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	color: white;
	box-sizing: border-box;
	user-select: none;
	i {
		color: white;
	}
`;

const Title = styled.div`
	font-size: 20px;
	color: white;
	font-family: Champions;
	text-align: center;
	margin-bottom: 10px;
`;

export const PopUpIcon = styled.i`
	cursor: pointer;
`;

//#endregion

class HighlightsPopUp extends Component<IHighlightsPopUpCombinedProps, { }> {


	private _onCloseClick = () => {
		this.props.dispatch(actions.setHighlightPopUp(-1));
	}

	public render() {
		if (this.props.highlightPopUp) {
			return (
				<PopUp onOverlayClick={this._onCloseClick}>
					<PopUpContainerDiv isMobileViewForPopUp={this.props.isMobileViewForPopUp}>
						<PopUpIcon
							className='fa fa-times'
							onClick={this._onCloseClick}
						/>
						<Title>{`Highlights of ${this.props.highlightPopUp.name}`}</Title>
						<HighlightsGallery
							highlights={this.props.highlightPopUp.highlights}
							teamId={this.props.highlightPopUp.teamId.toString()}
						/>
					</PopUpContainerDiv>
				</PopUp>
			);
		}
		return null;
	}
}

export default connect(rootSelector)(HighlightsPopUp);
