
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { IHighlightGalleryProps } from '../../interfaces';

const WIDTH = 440;
const HEIGHT = 275;
const GalleryContainer = styled.div`
	font-family: Champions;
	max-width: ${WIDTH}px;
	width: 100%;
	height: ${HEIGHT}px;
	margin: 10px auto 10px auto;
`;

const ContentContainer = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	position: relative;
`;

interface IHighlightContainer {
	display: string;
}
const HighlightContainer = styled.div<IHighlightContainer>`
	width: 100%;
	display: ${props => props.display};
	-webkit-animation-name: fade;
	-webkit-animation-duration: 1.5s;
	animation-name: fade;
	user-select: none;
`;

const VideoContainer = styled.div`
	position: relative;
	iframe {
		width: 100% !important;
		height: ${HEIGHT}px !important;
	}
`;

const NavigatorContainer = styled.div`
	font-size: 20px;
	cursor: pointer;
	z-index: 5;
	width: 20px;
`;

const NavigatorContainerRight = styled(NavigatorContainer)`
	text-align: right;
`;

const NavigatorContainerLeft = styled(NavigatorContainer)`
	text-align: left;
`;

const VideoGalleryContainer = styled.div`
	flex: 1;
	margin: auto;
	position: relative;
`;

const NavigateIcon = styled.i`
	line-height: ${HEIGHT}px !important;
`;

const Description = styled.div`
	text-align: center;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px rgb(248,149,4);
`;

interface IHighlightState {
	currentIndex: number;
}

export class HighlightsGallery extends PureComponent<IHighlightGalleryProps, IHighlightState> {

	constructor(props: IHighlightGalleryProps, context: any) {
		super(props, context);
		this.state = {
			currentIndex: 0
		};
	}

	public componentDidUpdate(prevProps: IHighlightGalleryProps) {
		if (this.props.teamId !== prevProps.teamId) {
			this.setState({
				currentIndex: 0
			});
		}
	}

	private _renderHighlight = (highlight: any, highlightIndex: number) => {
		const firstDescriptionPart = highlight.shortDescription.substr(0, highlight.shortDescription.indexOf(': '));
		const secondDecriptionPart = firstDescriptionPart ? highlight.shortDescription.substr(highlight.shortDescription.indexOf(': ') + 1) : highlight.shortDescription;
		return (
			<HighlightContainer key={highlightIndex} display={highlightIndex === this.state.currentIndex ? 'block' : 'none'}>
				{firstDescriptionPart ? <Description>{firstDescriptionPart}</Description> : null}
				{secondDecriptionPart ? <Description>{secondDecriptionPart}</Description> : null}
				<VideoContainer>
					<iframe
						title={highlight.shortDescription}
						src={highlight.link}
						frameBorder='0'
						allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen={true}
					/>
				</VideoContainer>
			</HighlightContainer>
		);
	}

	private _onNavigateToRight = () => {
		let currentIndex = this.state.currentIndex;
		if (currentIndex + 1 === this.props.highlights.length) {
			currentIndex = 0;
			this.setState({
				currentIndex
			});
		} else {
			currentIndex = currentIndex + 1;
			this.setState({
				currentIndex
			});
		}
	}

	private _onNavigateToLeft = () => {
		let currentIndex = this.state.currentIndex;
		if (currentIndex - 1 < 0) {
			currentIndex = this.props.highlights.length - 1;
			this.setState({
				currentIndex
			});
		} else {
			currentIndex = currentIndex - 1;
			this.setState({
				currentIndex
			});
		}
	}

	public render() {
		return (
			<GalleryContainer>
				<ContentContainer>
					{
						this.props.highlights.length > 1
							? <NavigatorContainerLeft onClick={this._onNavigateToLeft}>
								<NavigateIcon className='fa fa-caret-left'/>
							</NavigatorContainerLeft>
							: null
					}					
					<VideoGalleryContainer>
						{this.props.highlights.map((x, i) => this._renderHighlight(x, i))}
					</VideoGalleryContainer>
					{
						this.props.highlights.length > 1
							? <NavigatorContainerRight onClick={this._onNavigateToRight}>
								<NavigateIcon className='fa fa-caret-right'/>
							</NavigatorContainerRight>
							: null
					}
				</ContentContainer>
			</GalleryContainer>
		);
	}
}
