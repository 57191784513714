import { createSelector } from 'reselect';
import { IHeaderInjectedProps, IStore } from '../../interfaces';

const stateSelector = (state: any) => state ? state : {};

export default createSelector(
	[
		stateSelector
	],
	(
		state: IStore
	): IHeaderInjectedProps => {
		return {
			showTreeMap: !!state.ui.showTreeMap,
			section: state.ui.section,
			isMobileViewActive: state ? state.ui.isMobileViewActive : false
		};
	}
);
