import React, { PointerEvent, PureComponent, useCallback } from 'react';
import { ICompetitionsTimeSliderProps, ICompetitionsTimeSliderState, IStore } from '../../interfaces';
import styled from 'styled-components';
import { StyledAnimatedOnHover } from './SideTree';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../actions';
import { ISelectedEvolutions, ITeamTimeHonours } from '../../interfaces/clientInterfaces';

const COMPETITION_SLIDER_CONTROL_WIDTH = 20;
const CompetitionsTimeSliderContainer = styled.div`
	display: flex;
	height: 20px;
	position: relative;
	margin: 30px 0;
	flex-direction: column;
`;
interface CompetitionSliderControl {
	left: number;
	isSelected: boolean;
}
const CompetitionSliderControlContainer = styled(StyledAnimatedOnHover)<CompetitionSliderControl>`
	height: ${COMPETITION_SLIDER_CONTROL_WIDTH}px;
  	width: ${COMPETITION_SLIDER_CONTROL_WIDTH}px;
  	background-color: ${props => props.isSelected ? '#F89504' : '#000'};
	border: 2px solid #F89504;
  	border-radius: 50%;
  	display: inline-block;
	cursor: grab;
	position: absolute;
	z-index: 2;
	left: ${props => props.left - COMPETITION_SLIDER_CONTROL_WIDTH / 2}px;
	:hover {
		background-color: #F89504;
		a {
			color: black;
		}
		i {
			color:  #F89504;
		}
	}
`;

const MidBarContainer = styled.div`
	width: 100%;
	height: 10px;
	border: 2px solid #F89504;
	background-color: #000;
	z-index: 1;
	top: 5px;
	position: absolute;
	display: flex;
`;

const YearContainer = styled.div`
	user-select: none;
	font-famnily: Europa-regular;
	font-size: 15px;
	color: black;
	position: absolute;
	top: 15px;
	background-color: #F89504;
	border-radius: 4px;
	padding 3px;
	display: inline-table;
`;

const StartYearContainer = styled(YearContainer)`
	left: 0;
	background-color: black;
	color: #F89504;
	position: relative;
`;

const EndYearContainer = styled(YearContainer)`
	right: 0px;
	background-color: black;
	color: #F89504;
	position: relative;
`;

const PointYearContainer = styled(YearContainer)`
	bottom: 23px !important;
	left: -10px;
	top: unset;
`;

const ArrowIconContainer = styled.div`
	flex: 1;
	margin: 4px;
	display: flex;
	position: relative;
	top: 5px;
`;

const ArrowIconContainerRight = styled(ArrowIconContainer)`
	flex-direction: row-reverse;
`;

const ArrowIcon = styled.i`
	position: relative; 
	font-size: 25px;
	color: #F89504;
	transition: color, color;
	transition-property: color, color;
	transition-duration: 0.4s;
	transition-timing-function: ease-out;
	cursor: pointer;
	top: 15px;
	padding: 2px;
	:hover {
		color: #F89504;
	}
`;

const DragAndDropContainer = styled.div`
	flex: 1;
	position: relative;
	margin: 0 30px;
`;

const ArrowsContainer = styled.div`
	flex: 1;
	position: relative;
	flex-direction: row;
	display: flex;
`;

const EvolutionStatusContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;
`;


const TeamTimeHonourContainer = styled.div`
	flex-direction: row;
	margin: 10px;
	display: flex;
`;
interface ITeamTimeHonourNameAndImage {
	isMobileViewActive: boolean;
}
const TeamTimeHonourNameAndImage = styled.div<ITeamTimeHonourNameAndImage>`
	max-width: ${props => props.isMobileViewActive ? '140px' : '180px'};
	min-width: ${props => props.isMobileViewActive ? '140px' : '180px'};
	display: flex;
`;

interface ITeamShieldImage {
	isMobileViewActive: boolean;
	doesRegionFlagExist: boolean
}
const TeamShieldImage = styled.img<ITeamShieldImage>`
	max-height: ${props => props.isMobileViewActive ? '25px' : '30px'};
	margin: auto ${props => props.doesRegionFlagExist ? '0' : '3px'};;
`;

const RegionImage = styled.img`
	width: 20px;
	margin: auto 5px;
`;

interface ITeamScarfImage {
	isMobileViewActive: boolean;
}
const TeamScarfImage = styled.img<ITeamScarfImage>`
	max-width: ${props => props.isMobileViewActive ? '120px' : '160px'};
	margin: auto;
`;

const TeamNameContainer = styled.div<ITeamScarfImage>`
	font-size: ${props => props.isMobileViewActive ? '10px' : '12px'};
	line-height: 30px;
	font-family: 'Europa-big';
	color: white;
	margin: 0 5px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	border-radius: 10px;
`;


interface ITitlesBarContainer {
	isRunnerUp: boolean;
}
const TitlesBarContainer = styled.div<ITitlesBarContainer>`
	margin: 0 5px;
	height: 30px;
	width: 100%;
	position: relative;
	opacity: ${props => props.isRunnerUp ? 0.7 : 1};
`;

interface IChampionsBarContainer {
	width: number;
}
const ChampionsBarContainer = styled.div<IChampionsBarContainer>`
	position: relative;
	height: 100%;
	width: ${props => props.width}%;
	background-color: #F89504;
	-webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;
	display: flex;
	flex-direction: row;
`;

interface ITitlesNumberContainer {
	left: number;
}

const TitlesNumberContainer = styled.div<ITitlesNumberContainer>`
	font-size: 14px;
	line-height: 30px;
	font-family: 'Europa-big';
`;

const ContentOfBar = styled.div`
	background-color: transparent;
	width: 100%;
	position: absolute;
	color: white;
	text-shadow: 2px 2px 2px black;
	display: flex;
	flex-direction: row;
	z-index: 10;
	padding: 0 2px;
`;

const TeamContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 10px;
`;

interface ITitleTeamNameContainer {
	isMobileViewActive: boolean;
}
const TitleTeamNameContainer = styled.div<ITitleTeamNameContainer>`
	margin: auto 5px;
	font-size: ${props => props.isMobileViewActive ? 12 : 15}px;
`;

const TrophyAndShieldsContainer = styled.div<ITitleTeamNameContainer>`
	display: flex;
	margin: auto;
	flex-direction: row;
	background-color: rgba(255, 255, 255, 0.4);
	border-radius: 4px;
	padding: 10px;
	margin: ${props => props.isMobileViewActive ? '5px' : '5px auto'};
	color: black;
	font-family: 'Europa-big';
`;

interface ILastChampionShieldContainer {
	isMobileViewActive: boolean;
}
const LastChampionShieldContainer = styled.img<ILastChampionShieldContainer>`
	height: ${props => props.isMobileViewActive ? 30 : 45}px;
	object-fit: contain;
	filter: drop-shadow(1px 6px 6px gold);
	margin: auto;
`;

interface ILastRunnerUpShieldContainer {
	isMobileViewActive: boolean;
}
const LastRunnerUpShieldContainer = styled.img<ILastRunnerUpShieldContainer>`
	height: ${props => props.isMobileViewActive ? 30 : 45}px;
	filter: opacity(0.6);
	object-fit: contain;
	margin: auto;
`;

interface ITrophyImage {
	isMobileViewActive: boolean;
}
const TrophyImage = styled.img<ITrophyImage>`
	height: ${props => props.isMobileViewActive ? 70 : 90}px;
`;

interface IShield {
	isMobileViewActive: boolean;
}
const ChampionRegionShieldImage = styled.img<IShield>`
	height: ${props => props.isMobileViewActive ? 14 : 20}px;
	margin: auto;
	position: relative;
`;

const RunnerUpRegionShieldImage = styled.img<IShield>`
	height: ${props => props.isMobileViewActive ? 14 : 20}px;
	margin: auto;
	opacity: 0.8;
	position: relative;
`;

export class CompetitionEvolution extends PureComponent<ICompetitionsTimeSliderProps, ICompetitionsTimeSliderState> {
	private readonly _containerRef = React.createRef<HTMLDivElement>();
	private readonly _startControlRef = React.createRef<HTMLDivElement>();
	private readonly _endControlRef = React.createRef<HTMLDivElement>();

	constructor(props: ICompetitionsTimeSliderProps) {
		super(props);
		this.state = {
			currentStartIndex: props.initStartIndex,
			currentEndIndex: props.initEndIndex,
			gap: 0,
			startPointLeft: 0,
			endPointLeft: 0,
			isStartDragActive: false,
			isEndDragActive: false,
			containerLeft: 0,
			containerRight: 0,
			isLeftControlSelected: false,
			isRightControlSelected: false,
			deltaDragX: 0,
			currentStartYearText: props.currentStartYearText,
			currentEndYearText: props.currentEndYearText
		};
	}

	public componentDidMount() {
		this._onSetDimensions(this.props.initStartIndex, this.props.initEndIndex);
	}

	public componentWillUnmount() {
		this._removeEventListeners();
	}

	public componentDidUpdate(prevProps: Readonly<ICompetitionsTimeSliderProps>): void {
		if (
			this.props.totalGaps !== prevProps.totalGaps ||
			this.props.startYear !== prevProps.startYear ||
			this.props.endYear !== prevProps.endYear
		) {
			const currentEndIndex = this.props.initEndIndex;
			this.setState({
				currentStartIndex: this.props.initStartIndex,
				currentEndIndex,
				currentStartYearText: this.props.currentStartYearText,
				currentEndYearText: this.props.currentEndYearText
			});
			this._onSetDimensions(this.props.initStartIndex, currentEndIndex);
		}
		if (
			(prevProps.viewPortX !== this.props.viewPortX || prevProps.viewPortY !== this.props.viewPortY)
		 ) {
			this._onSetDimensions(this.state.currentStartIndex, this.state.currentEndIndex);
		}
	}

	private _addEventListeners = () => {
		document.addEventListener('mousemove', this._onMouseMove, false);
		document.addEventListener('mouseup', this._onMouseUp, false);
		document.addEventListener('touchmove', this._onTouchMove, false);
		document.addEventListener('touchend', this._onTouchEnd, false);
	}

	private _removeEventListeners = () => {
		document.removeEventListener('mousemove', this._onMouseMove, false);
		document.removeEventListener('mouseup', this._onMouseUp, false);
		document.removeEventListener('touchmove', this._onTouchMove, false);
		document.removeEventListener('touchend', this._onTouchEnd, false);
	}

	private _onSetDimensions = (startIndex: number, endIndex: number) => {
		if (this._containerRef.current) {
			const containerBoundingClientRect = this._containerRef.current.getBoundingClientRect();
			const gap = (containerBoundingClientRect.width) / (this.props.totalGaps - 1);
			this.setState({
				containerLeft: containerBoundingClientRect.left,
				containerRight: containerBoundingClientRect.left + containerBoundingClientRect.width,
				gap,
				startPointLeft: startIndex * gap,
				endPointLeft: endIndex * gap
			});
		}
	}

	private _onTouchEnd = (event: TouchEvent) => {
		const deltaDragX = event.changedTouches[0].clientX- this.state.deltaDragX;
		this.setState({
			isStartDragActive: false,
			isEndDragActive: false,
			isRightControlSelected: deltaDragX === 0 ? this.state.isRightControlSelected : false,
			isLeftControlSelected: deltaDragX === 0 ? this.state.isLeftControlSelected : false,
			deltaDragX: 0
		});
		this._removeEventListeners();
	}

	private _onMouseUp = (event: MouseEvent) => {
		const deltaDragX = event.clientX- this.state.deltaDragX;
		this.setState({
			isStartDragActive: false,
			isEndDragActive: false,
			isRightControlSelected: deltaDragX === 0 ? this.state.isRightControlSelected : false,
			isLeftControlSelected: deltaDragX === 0 ? this.state.isLeftControlSelected : false,
			deltaDragX: 0
		});
		this._removeEventListeners();
	}

	private _onChangeRangeDebounced = debounce((currentStartIndex: number, currentEndIndex: number): void => {
		this.props.onChangeRange(currentStartIndex, currentEndIndex);
	}, 150);

	private _onMoveStartControl = (newStartIndex: number, maintainSelection?: boolean) => {
		const currentStartIndex = newStartIndex <= 0
			? 0
			: newStartIndex >= this.props.totalGaps - 1
				? this.props.totalGaps - 1
				: newStartIndex;
		const calculatedStartPointLeft = currentStartIndex * this.state.gap;
		const startPointLeft = calculatedStartPointLeft < 0
			? 0
			: calculatedStartPointLeft > (this.state.containerRight - this.state.containerLeft)
				? this.state.containerRight - this.state.containerLeft
				: calculatedStartPointLeft;

		let currentEndIndex = this.state.currentEndIndex;
		let endPointLeft = this.state.endPointLeft;
		if (currentEndIndex < currentStartIndex) {
			currentEndIndex = currentStartIndex + 1;
			const calculatedEndPointLeft = currentEndIndex * this.state.gap;
			endPointLeft = calculatedEndPointLeft < 0
				? 0
				: calculatedEndPointLeft > (this.state.containerRight - this.state.containerLeft)
					? this.state.containerRight - this.state.containerLeft
					: calculatedEndPointLeft;
		}
		
		this._onChangeRangeDebounced(currentStartIndex, currentEndIndex);
		this.setState({
			currentStartIndex,
			currentEndIndex,
			startPointLeft,
			endPointLeft,
			isRightControlSelected: maintainSelection ? this.state.isRightControlSelected : false,
			isLeftControlSelected: maintainSelection ? this.state.isLeftControlSelected : false
		});
	}

	private _onMoveEndControl = (newEndIndex: number, maintainSelection?: boolean) => {
		const currentEndIndex = newEndIndex < 0
			? 0
			: newEndIndex >= this.props.totalGaps - 1
				? this.props.totalGaps - 1
				: newEndIndex;
		const calculatedEndPointLeft = currentEndIndex * this.state.gap;
		const endPointLeft = calculatedEndPointLeft < 0
			? 0
			: calculatedEndPointLeft > (this.state.containerRight - this.state.containerLeft)
				? this.state.containerRight - this.state.containerLeft
				: calculatedEndPointLeft;

		let currentStartIndex = this.state.currentStartIndex;
		let startPointLeft = this.state.startPointLeft;	
		if (currentStartIndex > currentEndIndex) {
			currentStartIndex = currentEndIndex - 1;
			const calculatedStartPointLeft = currentStartIndex * this.state.gap;
			startPointLeft = calculatedStartPointLeft < 0
				? 0
				: calculatedStartPointLeft > (this.state.containerRight - this.state.containerLeft)
					? this.state.containerRight - this.state.containerLeft
					: calculatedStartPointLeft;
		}
		this._onChangeRangeDebounced(currentStartIndex,currentEndIndex);
		this.setState({
			currentEndIndex,
			endPointLeft,
			startPointLeft,
			currentStartIndex,
			isRightControlSelected: maintainSelection ? this.state.isRightControlSelected : false,
			isLeftControlSelected: maintainSelection ? this.state.isLeftControlSelected : false
		});
	}

	private _onMoveControl = (x: number) => {
		if (x > this.state.containerLeft && x < this.state.containerRight) {
			if (this.state.isStartDragActive) {
				const difference = Math.round((x - (this.state.startPointLeft + this.state.containerLeft)) / this.state.gap);
				const newStartIndex = this.state.currentStartIndex + difference;
				this._onMoveStartControl(newStartIndex);
			} else if (this.state.isEndDragActive) {
				const difference = Math.round((x - (this.state.endPointLeft + this.state.containerLeft)) / this.state.gap);
				const newEndIndex = this.state.currentEndIndex + difference;
				this._onMoveEndControl(newEndIndex);
			}
		}
	}

	private _onTouchMove = (event: TouchEvent) => {
		this._onMoveControl(event.changedTouches[0].clientX);
	}

	private _onMouseMove = (event: MouseEvent) => {
		this._onMoveControl(event.clientX);
	}

	private _onStartStartControlDrag = (event: PointerEvent<HTMLDivElement>) => {
		this.setState({
			isStartDragActive: true,
			isLeftControlSelected: !this.state.isLeftControlSelected,
			isRightControlSelected: false,
			deltaDragX: event.clientX
		});
		this._addEventListeners();
	}

	private _onStartEndControlDrag = (event: PointerEvent<HTMLDivElement>) => {
		this.setState({
			isEndDragActive: true,
			isLeftControlSelected: false,
			isRightControlSelected: !this.state.isRightControlSelected,
			deltaDragX: event.clientX
		});
		this._addEventListeners();
	}

	private _onStartLeftArrowClick = () => {
		if (this.state.currentStartIndex > 0) {
			this._onMoveStartControl(this.state.currentStartIndex - 1);
		}
	}

	private _onStartRightArrowClick = () => {
		if (this.state.currentStartIndex < this.props.totalGaps - 1) {
			this._onMoveStartControl(this.state.currentStartIndex + 1);
		}
	}

	private _onEndLeftArrowClick = () => {
		if (this.state.currentEndIndex > 0) {
			this._onMoveEndControl(this.state.currentEndIndex - 1);
		}
	}

	private _onEndRightArrowClick = () => {
		if (this.state.currentEndIndex < this.props.totalGaps - 1) {
			this._onMoveEndControl(this.state.currentEndIndex + 1);
		}
	}

	private _onMidBarContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if (this._containerRef.current) {
			const containerBoundingClientRect = this._containerRef.current.getBoundingClientRect();
			const eventX = event.clientX - containerBoundingClientRect.left;
			const indexOfEvent = Math.floor(eventX / this.state.gap);
			if (indexOfEvent <= this.state.currentStartIndex) {
				this._onMoveStartControl(indexOfEvent, true);
			} else if (indexOfEvent >= this.state.currentEndIndex) {
				this._onMoveEndControl(indexOfEvent, true);
			} else {
				if (this.state.isLeftControlSelected) {
					this._onMoveStartControl(indexOfEvent >= this.state.currentEndIndex ? this.state.currentEndIndex - 1 : indexOfEvent, true);
				} else if (this.state.isRightControlSelected) {
					this._onMoveEndControl(indexOfEvent <= this.state.currentStartIndex ? this.state.currentEndIndex + 1 : indexOfEvent, true);
				} else {
					const leftDifference = Math.abs(indexOfEvent - this.state.currentStartIndex);
					const rightDifference = Math.abs(indexOfEvent - this.state.currentEndIndex);
					if (rightDifference > leftDifference) {
						this._onMoveStartControl(indexOfEvent >= this.state.currentEndIndex ? this.state.currentEndIndex - 1 : indexOfEvent, true);
					} else {
						this._onMoveEndControl(indexOfEvent <= this.state.currentStartIndex ? this.state.currentEndIndex + 1 : indexOfEvent, true);
					}
				}
			}
		}
	}

	public render() {
		return (
			<CompetitionsTimeSliderContainer>
				<DragAndDropContainer>
					<CompetitionSliderControlContainer
						ref={this._startControlRef}
						left={this.state.startPointLeft}
						onPointerDown={this._onStartStartControlDrag}
						isSelected={this.state.isLeftControlSelected}
					>
						<PointYearContainer>{this.props.currentStartYearText}</PointYearContainer>
					</CompetitionSliderControlContainer>
					<MidBarContainer
						ref={this._containerRef}
						onClick={this._onMidBarContainerClick}
					/>
					<CompetitionSliderControlContainer
						ref={this._endControlRef}
						left={this.state.endPointLeft}
						onPointerDown={this._onStartEndControlDrag}
						isSelected={this.state.isRightControlSelected}
					>
						<PointYearContainer>{this.props.currentEndYearText}</PointYearContainer>
					</CompetitionSliderControlContainer>
				</DragAndDropContainer>
				<ArrowsContainer>
					<ArrowIconContainer>
						<ArrowIcon
							className={'fa fa-caret-left'}
							onClick={this._onStartLeftArrowClick}
						/>
						<StartYearContainer>{this.props.startYear}</StartYearContainer>
						<ArrowIcon
							className={'fa fa-caret-right'}
							onClick={this._onStartRightArrowClick}
						/>
					</ArrowIconContainer>
					<ArrowIconContainerRight>
						<ArrowIcon
							className={'fa fa-caret-right'}
							onClick={this._onEndRightArrowClick}
						/>
						<EndYearContainer>{this.props.endYear}</EndYearContainer>
						<ArrowIcon
							className={'fa fa-caret-left'}
							onClick={this._onEndLeftArrowClick}
						/>
					</ArrowIconContainerRight>
				</ArrowsContainer>
			</CompetitionsTimeSliderContainer>
			
		);
	}
}


export interface ICompetitionsTimeSliderInjectedProps {
	viewPortX: number;
	viewPortY: number;
	selectedEvolution: ISelectedEvolutions | null;
	isMobileViewActive: boolean;
}

export default function CompetitionEvolutionContainer() {

	const dispatch = useDispatch();

	const {
		viewPortX,
		viewPortY,
		selectedEvolution,
		isMobileViewActive
	} = useSelector(
		(completeState: IStore): ICompetitionsTimeSliderInjectedProps => {
			if (completeState.competitions.selectedEvolutions.length > 0) {
				const selectedEvolution = completeState.competitions.selectedEvolutions[0];
				return {
					viewPortX: completeState.ui.viewPort.width,
					viewPortY: completeState.ui.viewPort.height,
					selectedEvolution: selectedEvolution,
					isMobileViewActive: completeState.ui.isMobileViewActive,
				};
			}
			return {
				viewPortX: 0,
				viewPortY: 0,
				selectedEvolution: null,
				isMobileViewActive: completeState.ui.isMobileViewActive,
			};
		});

		
	const onChangeRange = useCallback((startIndex: number, endIndex: number) => {
		dispatch(actions.onChangeRangeOfEvolution(0, startIndex, endIndex));
	}, [dispatch]);


	const returnTitlesOfTeam = (teamTimeHonours: ITeamTimeHonours, selectedEvolution: ISelectedEvolutions) => {
		let width = 100;
		let isRunnerUp = false;
		let numberChampionsText = '';
		if (teamTimeHonours.timesChampion > 0) {
			width = 100 * teamTimeHonours.timesChampion / selectedEvolution.maxTitlesInCurrentStatus;
			numberChampionsText = teamTimeHonours.timesChampion.toString();
		} else {
			width = 100 * teamTimeHonours.timesRunnerUp / selectedEvolution.maxTitlesInCurrentStatus;
			isRunnerUp = true;
			numberChampionsText = isMobileViewActive ? '0' : `${teamTimeHonours.timesRunnerUp} Runner up`;
		}
		return (
			<TeamTimeHonourContainer>
				<TeamTimeHonourNameAndImage isMobileViewActive={isMobileViewActive}>
					<TeamScarfImage
						isMobileViewActive={isMobileViewActive}
						src={`./../../img/scarf/${teamTimeHonours.scarfCode}.jpg`}
					/>
				</TeamTimeHonourNameAndImage>
				<TitlesBarContainer isRunnerUp={isRunnerUp}>
					<ContentOfBar>
						{teamTimeHonours.regionCode
							? <RegionImage
								src={`./../../img/flag/${teamTimeHonours.regionCode}.png`}
							/>
							: null	
						}
						<TeamShieldImage
							doesRegionFlagExist={teamTimeHonours.regionCode ? true : false}
							isMobileViewActive={isMobileViewActive}
							src={`./../../img/shield/${teamTimeHonours.shieldCode}.png`}
							title={teamTimeHonours.teamName}
						/>
						<TeamNameContainer isMobileViewActive={isMobileViewActive}>{teamTimeHonours.teamName}</TeamNameContainer>
						<TitlesNumberContainer left={width}>
							{numberChampionsText}
						</TitlesNumberContainer>
					</ContentOfBar>
					<ChampionsBarContainer width={width}/>
				</TitlesBarContainer>
			</TeamTimeHonourContainer>
		);
	}

	if (!selectedEvolution) {
		return null;
	}
	return (
		<>
			<TrophyAndShieldsContainer isMobileViewActive={isMobileViewActive}>
				{
					selectedEvolution.lastChampionCode && <TitleTeamNameContainer isMobileViewActive={isMobileViewActive}>
						{`${selectedEvolution.lastChampionTooltip}`}
					</TitleTeamNameContainer>
				}
				<TeamContainer>
					<LastChampionShieldContainer
						isMobileViewActive={isMobileViewActive}
						src={`./../../img/shield/${selectedEvolution.lastChampionCode || 'ZZ_UNK'}.png`}
						title={selectedEvolution.lastChampionTooltip}
					/>
					{selectedEvolution.lastChampionRegionCode
						? <ChampionRegionShieldImage
							isMobileViewActive={isMobileViewActive}
							src={`./../../img/flag/${selectedEvolution.lastChampionRegionCode}.png`}
						/>
						: null	
					}
				</TeamContainer>
				<TrophyImage
					isMobileViewActive={isMobileViewActive}
					src={`./../../img/trophy/${selectedEvolution.competitionTrophy}.png`}
				/>
				<TeamContainer>
					<LastRunnerUpShieldContainer
						isMobileViewActive={isMobileViewActive}
						src={`./../../img/shield/${selectedEvolution.lastRunnerUpCode || 'ZZ_UNK'}.png`}
						title={selectedEvolution.lastRunnerUpTooltip}
					/>
					{selectedEvolution.lastRunnerUpRegionCode
						? <RunnerUpRegionShieldImage
							isMobileViewActive={isMobileViewActive}
							src={`./../../img/flag/${selectedEvolution.lastRunnerUpRegionCode}.png`}
						/>
						: null	
					}
				</TeamContainer>
				{
					selectedEvolution.lastRunnerUpCode && <TitleTeamNameContainer isMobileViewActive={isMobileViewActive}>
						{`${selectedEvolution.lastRunnerUpTooltip}`}
					</TitleTeamNameContainer>
				}
			</TrophyAndShieldsContainer>
			<EvolutionStatusContainer>
				{selectedEvolution.selectedTimeStatus.map(sTT => returnTitlesOfTeam(sTT, selectedEvolution))}
			</EvolutionStatusContainer>
			<CompetitionEvolution
				viewPortX={viewPortX}
				viewPortY={viewPortY}
				startYear={selectedEvolution.evolution.startYear}
				endYear={selectedEvolution.evolution.endYear}
				currentStartYear={selectedEvolution.selectedStartYear}
				currentStartYearText={selectedEvolution.selectedStartYearText}
				currentEndYearText={selectedEvolution.selectedEndYearText}
				currentEndYear={selectedEvolution.selectedEndYear}
				initEndIndex={selectedEvolution.selectedEndIndex}
				initStartIndex={selectedEvolution.selectedStartIndex}
				totalGaps={selectedEvolution.evolution.editions.length}
				onChangeRange={onChangeRange}
			/>
		</>
	);
}

