import { createSelector } from 'reselect';
import { IStore, IFilterAreaInjectedProps } from '../../interfaces';

const stateSelector = (state: any) => state ? state : {};

export default createSelector(
	[
		stateSelector
	],
	(
		state: IStore
	): IFilterAreaInjectedProps => {
		return {
			orderBy: state.orderAndPaging.orderBy,
			section: state.ui.section,
			currentCountry: state.ui.currentCountry
		};
	}
);
