import { createSelector } from 'reselect';
import { IHighlightsPopUpInjectedProps, IStore } from '../../interfaces';

const stateSelector = (state: any) => state ? state : {};

export default createSelector(
	[
		stateSelector
	],
	(
		state: IStore
	): IHighlightsPopUpInjectedProps => {
		return {
			highlightPopUp: state.ui.highlightPopUp,
			isMobileViewForPopUp: state.ui.viewPort.width < 500
		};
	}
);
