import { createSelector } from 'reselect';
import { IStore, IMapInjectedProps } from '../../interfaces';
import { EnumSection } from '../../interfaces/clientInterfaces';

const stateSelector = (state: any) => state ? state : {};

export default createSelector(
	[
		stateSelector
	],
	(
		state: IStore
	): IMapInjectedProps => {
		if (state && Array.isArray(state.teams.teams)) {
			return {
				teams: state.teams.teams,
				stadiumCluster: state.map.stadiumCluster,
				mapDetailScarfInfo: state.map.mapDetailScarfInfo,
				isMobileViewActive: state ? state.ui.isMobileViewActive : false,
				section: state.ui.section,
				trophyTooltip: state.ui.trophyTooltip,
				teamStadiumCoordinatesMap: state.map.teamStadiumCoordinatesMap,
				detailTrophyTrophy: state.ui.detailTrophyTooltip
			};
		}
		return {
			teams: [],
			stadiumCluster: {},
			mapDetailScarfInfo: state.map.mapDetailScarfInfo,
			isMobileViewActive: state ? state.ui.isMobileViewActive : false,
			section: EnumSection.home,
			trophyTooltip: null,
			teamStadiumCoordinatesMap: new Map(),
			detailTrophyTrophy: null
		};
	}
);
