import { INumberDictionary } from '../interfaces';
import { IMatchScarvesGroup, MatchOrderCategoriesEnum, OrderMatchCompetition } from '../interfaces/clientInterfaces';
import { IMatch, ITeam } from '../interfaces/clientInterfaces';
import { IDBCompetition, IDBScarfMatch } from '../interfaces/dbInterfaces';

export class Matches {

	public static getMatchScarves = (
		dbMatchScarves: IDBScarfMatch[],
		teamsById: INumberDictionary<ITeam>,
		competitionsDictionary: INumberDictionary<IDBCompetition>
	): {
		matchScarfGroups: IMatchScarvesGroup[];
		rMMatchScarfGroups: IMatchScarvesGroup[];
	} => {
		const allMatchScarfGroups: IMatchScarvesGroup[] = [];
		for (const matchScarf of dbMatchScarves) {
			const indexOfScarfCode = allMatchScarfGroups.findIndex(x => x.scarfCodes.findIndex(y => y === matchScarf.scarfCode) > -1);

			const match: IMatch = {
				matchId: matchScarf.match_id,
				localGoals: matchScarf.localGoals,
				visitantGoals: matchScarf.visitantGoals,
				isExtraTime: matchScarf.isExtraTime,
				localPenaltyGoals: matchScarf.localPenaltyGoals,
				visitantPenaltyGoals: matchScarf.visitantPenaltyGoals,
				round: matchScarf.round,
				isRM: matchScarf.isRM,
				awayKitColor: matchScarf.awayKitColor,
				homeKitColor: matchScarf.homeKitColor
			};
			if (indexOfScarfCode === -1) {
				const indexOfMatch = allMatchScarfGroups.findIndex(x => x.matches.findIndex(y => y.matchId === matchScarf.matchId) > -1);
				if (indexOfMatch === -1) {
					const matchScarfGroup: IMatchScarvesGroup = {
						scarfCodes: [matchScarf.scarfCode],
						matches: [match],
						yearEnd: matchScarf.yearEnd,
						isRM: matchScarf.isRM,
						round: matchScarf.round
					};
					matchScarfGroup.firstTeam = teamsById[matchScarf.firstTeamId];
					matchScarfGroup.secondTeam = teamsById[matchScarf.secondTeamId];
					matchScarfGroup.thirdTeam = matchScarf.thirdTeamId ? teamsById[matchScarf.thirdTeamId as number] : undefined;
					matchScarfGroup.fourthTeam = matchScarf.fourthTeamId ? teamsById[matchScarf.fourthTeamId as number] : undefined;
					matchScarfGroup.competition = competitionsDictionary[matchScarf.competitionId];

					allMatchScarfGroups.push(matchScarfGroup);
				} else {
					allMatchScarfGroups[indexOfMatch].scarfCodes.push(matchScarf.scarfCode);
				}
			} else {
				if (allMatchScarfGroups[indexOfScarfCode].matches.findIndex(x => x.matchId === match.matchId) === -1) {
					allMatchScarfGroups[indexOfScarfCode].matches.push(match);
				}
			}
		}
		const matchScarfGroups: IMatchScarvesGroup[] = [];
		const rMMatchScarfGroups: IMatchScarvesGroup[] = [];
		allMatchScarfGroups.forEach(m => {
			if (m.isRM) {
				if (m.round === 'F') {
					matchScarfGroups.push(m);
					rMMatchScarfGroups.push(m);
				} else {
					rMMatchScarfGroups.push(m);
				}
			} else {
				matchScarfGroups.push(m);
			}
		});

		return {
			matchScarfGroups,
			rMMatchScarfGroups
		};
	}

	public static parseCompetitionTitleByYear(competition: IDBCompetition, year: number): string {
		if (competition.competition_id === 40) {
			const splittedName = competition.name.split('/');
			if (year > 1992) {
				return splittedName[0];
			} else {
				return splittedName[1];
			}
		} else if (competition.competition_id === 41) {
			const splittedName = competition.name.split('/');
			if (year > 2009) {
				return splittedName[0];
			} else {
				return splittedName[1];
			}
		} else {
			return competition.name;
		}
	}

	public static getNameOfPhase(round: string): string {
		switch (round) {
			case 'F':
				return 'Final';
			case 'GS':
				return 'Group Stage';
			case 'GS2':
				return 'Second Group Stage';
			case 'SF':
				return 'Semi-final';
			case 'QF':
				return 'Quarter-final';
			case 'L16':
				return 'Last 16';
			case 'R32':
				return 'Round of 32';
			default:
				return '';
		}
	}


	public static orderMatchScarfGroups = (
		matchScarfGroups: IMatchScarvesGroup[],
		ascending: boolean,
		category: MatchOrderCategoriesEnum[]
	): IMatchScarvesGroup[] => {
		let updatedMatchScarfGroups = [...matchScarfGroups];

		const shouldOrderChronologically = category.findIndex(c => c === MatchOrderCategoriesEnum.chronologically) > -1;
		const shouldOrderByCompetition = category.findIndex(c => c === MatchOrderCategoriesEnum.competition) > -1;
		const shouldOrderByRound = category.findIndex(c => c === MatchOrderCategoriesEnum.round) > -1;

		if (shouldOrderChronologically) {
			updatedMatchScarfGroups = updatedMatchScarfGroups.sort((a, b) => Matches.orderMatchesByRound(a, b, ascending, shouldOrderByCompetition));
			updatedMatchScarfGroups = updatedMatchScarfGroups.sort((a, b) => Matches.orderMatchesByCompetition(a, b, ascending));
			updatedMatchScarfGroups = updatedMatchScarfGroups.sort((a, b) => Matches.orderMatchesChronollogically(a, b, ascending));
		}

		if (shouldOrderByCompetition) {
			updatedMatchScarfGroups = updatedMatchScarfGroups.sort((a, b) => Matches.orderMatchesByCompetition(a, b, ascending));
		}
		if (shouldOrderByRound) {
			updatedMatchScarfGroups = updatedMatchScarfGroups.sort((a, b) => Matches.orderMatchesByRound(a, b, ascending, shouldOrderByCompetition));
		}
		return updatedMatchScarfGroups;

	}


	public static filterMatches = (competition: OrderMatchCompetition[], matchScarfGroups: IMatchScarvesGroup[]): IMatchScarvesGroup[] => {
		const currentMatchScarfGroups = [];
		for (const groupScarf of matchScarfGroups) {
			if (groupScarf.competition) {
				const competitionCode = `${groupScarf.competition.region_code}_${groupScarf.competition.competition_code}`;
				if (competition.findIndex(x => x === competitionCode) > -1) {
					currentMatchScarfGroups.push(groupScarf);
				}
			}
		}
		return currentMatchScarfGroups;
	}


	public static orderMatchesChronollogically = (
		matchA: IMatchScarvesGroup,
		matchB: IMatchScarvesGroup,
		ascending: boolean
	): 1 | -1 => {
		if (matchA.yearEnd > matchB.yearEnd) {
			return ascending ? 1 : -1;
		} if (matchA.yearEnd < matchB.yearEnd) {
			return ascending ? -1 : 1;
		} else {
			return 1;
		}
	}

	public static orderMatchesByCompetition = (
		matchA: IMatchScarvesGroup,
		matchB: IMatchScarvesGroup,
		ascending: boolean
	): 1 | -1 => {

		if (matchA.competition && matchB.competition) {
			if (matchA.competition.competition_id > matchB.competition.competition_id) {
				return 1;
			} else if (matchA.competition.competition_id < matchB.competition.competition_id) {
				return -1;
			} else {
				return Matches.orderMatchesChronollogically(matchA, matchB, ascending);
			}
		}
		return 1;
	}

	public static orderMatchesByRound = (
		matchA: IMatchScarvesGroup,
		matchB: IMatchScarvesGroup,
		ascending: boolean,
		shouldOrderByCompetition: boolean
	): 1 | -1 => {
		const ascendingValueForARound = ascending ? 1 : -1;
		const ascendingValueForBRound = ascending ? -1 : 1;

		const roundA = matchA.matches[matchA.matches.length - 1].round;
		const roundB = matchB.matches[matchB.matches.length - 1].round;
		if (roundA === 'F') {
			if (roundB === 'F') {
				return shouldOrderByCompetition ? Matches.orderMatchesByCompetition(matchA, matchB, ascending) : Matches.orderMatchesChronollogically(matchA, matchB, ascending);
			}
			return ascendingValueForARound;
		} else {
			if (roundB === 'F') {
				return ascendingValueForBRound;
			} else {
				if (roundA === 'SF') {
					if (roundB === 'SF') {
						return shouldOrderByCompetition ? Matches.orderMatchesByCompetition(matchA, matchB, ascending) : Matches.orderMatchesChronollogically(matchA, matchB, ascending);
					}
					return ascendingValueForARound;
				} else {
					if (roundB === 'SF') {
						return ascendingValueForBRound;
					} else {
						if (roundA === 'QF') {
							if (roundB === 'QF') {
								return shouldOrderByCompetition ? Matches.orderMatchesByCompetition(matchA, matchB, ascending) : Matches.orderMatchesChronollogically(matchA, matchB, ascending);
							}
							return ascendingValueForARound;
						} else {
							if (roundB === 'QF') {
								return ascendingValueForBRound;
							} else {
								if (roundA === 'L16') {
									if (roundB === 'L16') {
										return shouldOrderByCompetition ? Matches.orderMatchesByCompetition(matchA, matchB, ascending) : Matches.orderMatchesChronollogically(matchA, matchB, ascending);
									}
									return ascendingValueForARound;
								} else {
									if (roundB === 'L16') {
										return ascendingValueForBRound;
									} else {
										if (roundA === 'R32') {
											if (roundB === 'R32') {
												return shouldOrderByCompetition ? Matches.orderMatchesByCompetition(matchA, matchB, ascending) : Matches.orderMatchesChronollogically(matchA, matchB, ascending);
											}
											return ascendingValueForARound;
										} else {
											if (roundB === 'R32') {
												return ascendingValueForBRound;
											} else {
												if (roundA === 'GS2') {
													if (roundB === 'GS2') {
														return shouldOrderByCompetition ? Matches.orderMatchesByCompetition(matchA, matchB, ascending) : Matches.orderMatchesChronollogically(matchA, matchB, ascending);
													}
													return ascendingValueForARound;
												} else {
													if (roundB === 'GS2') {
														return ascendingValueForBRound;
													} else {
														if (roundA === 'GS') {
															if (roundB === 'GS') {
																return shouldOrderByCompetition ? Matches.orderMatchesByCompetition(matchA, matchB, ascending) : Matches.orderMatchesChronollogically(matchA, matchB, ascending);
															}
															return ascendingValueForARound;
														} else {
															if (roundB === 'GS') {
																return ascendingValueForBRound;
															} else {
																return ascendingValueForARound;
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

}
