import { createSelector } from 'reselect';
import { IStore } from '../../interfaces';

const stateSelector = (state: any) => state ? state : {};

export default createSelector(
	[
		stateSelector
	],
	(
		state: IStore
	) => {
		return {
			entries: state.sideTree.sideTreeEntries,
			showTreeMap: state.ui.showTreeMap,
			isMobileViewActive: state.ui.isMobileViewActive
		};
	}
);
