import { createSelector } from 'reselect';
import { IHomeInjectedProps, IStore } from '../../interfaces';

const stateSelector = (state: any) => state ? state : {};

export default createSelector(
	[
		stateSelector
	],
	(
		state: IStore
	): IHomeInjectedProps => {
		return {
			updates: state.scarves.lastScarves,
			isMobileViewActive: state ? state.ui.isMobileViewActive : false,
			homeDetailScarfInfo: state.general.homeDetailScarfInfo,
			trophyTooltip: state.ui.trophyTooltip,
			isMobileViewForPopUp: state.ui.viewPort.width < 500,
			detailTrophyTrophy: state.ui.detailTrophyTooltip
		};
	}
);
