
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import rootSelector from '../selectors/matchGallery';
import { connect } from 'react-redux';
import { IMatchGalleryCombinedProps } from '../../interfaces';
import { IMatchScarvesGroup, EnumSection } from '../../interfaces/clientInterfaces';
import * as actions from '../../actions';
import { Helmet } from 'react-helmet';
import { IMatch, ITeam } from '../../interfaces/clientInterfaces';
import { Matches } from '../../utils/matches';

interface IGalleryContainer {
	imgSrc: string;
}
const GalleryContainer = styled.div<IGalleryContainer>`
	font-family: Champions;
	flex: 1;
	display: flex;
	flex-direction: column;
	background: ${props => `url('../img/background/${props.imgSrc}.jpg') no-repeat center center fixed`}; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
`;

const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
`;

const GroupContainer = styled.div`
	margin: auto auto 70px auto;
	width: 100%;
	padding: 10px;
`;

const ScarfContainer = styled.div`
	display: flex; 
	flex-direction: column;
	margin-top: 6px;
	max-height: auto;
	overflow: hidden;
`;
const TitleContainer = styled.div`
	display: flex;
	font-size: 20px;
	flex-direction: column;
	img {
		max-height: 65px !important;
		object-fit: contain;
		margin: 0;
	}
`;
const TitleSectionContainer = styled.div`
	margin: auto;
	text-shadow: 2px 2px 2px black;
`;

interface IImageRenderer {
	isMobileViewActive: boolean;
}
const ImageRenderer = styled.img<IImageRenderer>`
	max-width: ${props => props.isMobileViewActive ? '90vw' : '575px'}
	margin: 10px auto 5px auto;
`;

const ShieldImageRenderer = styled.img`
	max-width: 575px;
	margin: 10px auto 5px auto;
`;

const RESULT_CONTAINER_SIDE = 130;
const ResultContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: ${RESULT_CONTAINER_SIDE}px;
	height: ${RESULT_CONTAINER_SIDE}px;
	position: relative;
	font-size: 45px;
	font-family: 'Europa-Big';
	margin: 5px auto 5px auto;
`;

const ResultContainerRow = styled.div`
	display: flex;
	height: ${RESULT_CONTAINER_SIDE / 2}px;
`;

const ResultContainerQuarter = styled.div`
	display: flex;
	width: ${RESULT_CONTAINER_SIDE / 2}px;
	img {
		max-height: 50px;
		max-width: 60px;
		object-fit: contain;
	}
`;
const SEPARATOR_WIDTH = 18;
const SEPARATOR_HEIGHT = 8;
const Separator = styled.div`
	position: absolute;
	width: ${SEPARATOR_WIDTH}px;
	height: ${SEPARATOR_HEIGHT}px;
	left: calc(50% - ${SEPARATOR_WIDTH / 2}px);
	top: calc(50% + ${SEPARATOR_HEIGHT * 3 + SEPARATOR_HEIGHT / 2}px);
	background-color: #F89504;
`;

const TieContainer = styled.div`
	position: absolute;
	width: ${SEPARATOR_WIDTH}px;
	height: ${SEPARATOR_HEIGHT}px;
	left: calc(50% - ${2 * SEPARATOR_WIDTH / 3}px);
	top: calc(50% + ${5 * SEPARATOR_HEIGHT}px);
	color: #F89504;
	background-color:#212121;
	font-size: 12px;
`;
const ExtraTimeContainer = styled.div`
	position: relative;
	top: -1px
`;
const PenaltyResultContainer = styled.div`
	position: relative;
`;
const PenaltyRenderer = styled.div`
	position: absolute;
	top: -6px;
	font-size: 11px;
`;
const LocalPenaltyRenderer = styled(PenaltyRenderer)`
	left: -12px;
`;

const VisitantPenaltyRenderer = styled(PenaltyRenderer)`
	left: 20px;
`;

interface IShieldQuarter {
	backgroundColor: string;
}
const ResultContainerShieldQuarter = styled(ResultContainerQuarter)<IShieldQuarter>`
	background-color: ${props => `#${props.backgroundColor}`}
`;
const ResultContainerResultQuarter = styled(ResultContainerQuarter)`
	background-color:#212121;
	color: #F89504;
`;

const NumberContainer = styled.div`
	margin: auto;
`;

const MatchResultContainer = styled.div`
	display: flex;
	flex-direction: row;
	max-width: 700px;
	margin: auto;
`;

class MatchGallery extends PureComponent<IMatchGalleryCombinedProps, { imgNumber: number }> {

	constructor(props: IMatchGalleryCombinedProps, context: any) {
		super(props, context);
		const imgNumber = Math.floor(Math.random() * 10) + 1;
		this.state = {
			imgNumber
		};
	}

	public componentDidMount() {
		this.props.dispatch(actions.setSectionName(EnumSection.matches));
	}

	private _renderTitleLogo = (matchScarf: IMatchScarvesGroup, title: string) => {
		if (!matchScarf.competition) {
			return null;
		}
		let code = `${matchScarf.competition.region_code}_${matchScarf.competition.competition_code}`;
		if (matchScarf.competition.isInternational) {
			code += `_${matchScarf.yearEnd}`;
		}
		return (
			<img
				title={title}
				src={`../../../img/shield/${code}.png`}
				alt={title}
			/>
		);
	}

	private _renderTitleText = (title: string) => {
		return (
			<TitleSectionContainer>{title}</TitleSectionContainer>
		);
	}

	private _renderTitle = (matchScarf: IMatchScarvesGroup) => {
		let title = `${matchScarf.yearEnd}`;

		if (matchScarf.competition) {
			title += ` ${Matches.parseCompetitionTitleByYear(matchScarf.competition, matchScarf.yearEnd)}`;
			if (matchScarf.matches.length > 0 && matchScarf.matches[0].round) {
				title += ` ${Matches.getNameOfPhase(matchScarf.matches[0].round)}`;
			}
		}
		return (
			<TitleContainer>
				<TitleSectionContainer>{this._renderTitleLogo(matchScarf, title)}</TitleSectionContainer>
				{this._renderTitleText(title)}
			</TitleContainer>
		);
	}

	private _renderResult = (match: IMatch, index: number, firstTeam?: ITeam, secondTeam?: ITeam) => {
		return (
			<ResultContainerRow>
				<ResultContainerResultQuarter>
					<NumberContainer>{match.localGoals}</NumberContainer>
					<Separator/>
					{
						match.isExtraTime
							? <TieContainer>
								<ExtraTimeContainer>{'aet'}</ExtraTimeContainer>
								{
									match.localPenaltyGoals || match.visitantPenaltyGoals
										? <PenaltyResultContainer>
											<LocalPenaltyRenderer>{`(${match.localPenaltyGoals || 0})`}</LocalPenaltyRenderer>
											<VisitantPenaltyRenderer>{`(${match.visitantPenaltyGoals || 0})`}</VisitantPenaltyRenderer>
										</PenaltyResultContainer>
										: null
								}
							</TieContainer>
							: null
					}
					
				</ResultContainerResultQuarter>
				<ResultContainerResultQuarter>
					<NumberContainer>{match.visitantGoals}</NumberContainer>
				</ResultContainerResultQuarter>
			</ResultContainerRow>
		);
	}

	private _renderShield = (yearEnd: number, match: IMatch, firstMatchTeam: ITeam, secondMatchTeam: ITeam) => {
		const localTeamColor = match.homeKitColor || firstMatchTeam.firstColor;
		const awayTeamColor = match.awayKitColor || (localTeamColor === secondMatchTeam.firstColor ? secondMatchTeam.secondColor : secondMatchTeam.firstColor);
		const localYearShield = [...firstMatchTeam.shields].reverse().find((shield: string) => Number.parseInt(shield) >= yearEnd);
		const visitantYearShield = [...secondMatchTeam.shields].reverse().find((shield: string) => Number.parseInt(shield) >= yearEnd);
		return (
			<ResultContainerRow>
				<ResultContainerShieldQuarter backgroundColor={localTeamColor}>
					<ShieldImageRenderer
						alt={firstMatchTeam.name}
						title={firstMatchTeam.name}
						src={`../../../img/shield/${firstMatchTeam.countryCode}_${firstMatchTeam.teamCode}${localYearShield ? `_${localYearShield}` : ''}.png`}
					/>
				</ResultContainerShieldQuarter>
				<ResultContainerShieldQuarter backgroundColor={awayTeamColor}>
					<ShieldImageRenderer
						alt={secondMatchTeam.name}
						title={secondMatchTeam.name}
						src={`../../../img/shield/${secondMatchTeam.countryCode}_${secondMatchTeam.teamCode}${visitantYearShield ? `_${visitantYearShield}` : ''}.png`}
					/>
				</ResultContainerShieldQuarter>
			</ResultContainerRow>
		);
	}

	private _renderMatchResult = (yearEnd: number, match: IMatch, index: number, firstTeam?: ITeam, secondTeam?: ITeam, thirdTeam?: ITeam, fourthTeam?: ITeam) => {
		if (firstTeam && secondTeam) {
			const firstMatchTeam = index === 0 ? firstTeam : secondTeam;
			const secondMatchTeam = index > 0 ? firstTeam : secondTeam;
			return (
				<ResultContainer key={index}>
					{this._renderShield(yearEnd, match, firstMatchTeam, secondMatchTeam)}
					{thirdTeam && fourthTeam
						? this._renderShield(yearEnd, match, thirdTeam, fourthTeam)
						: this._renderResult(match, index, firstTeam, secondTeam)
					}
				</ResultContainer>
			);
		}
		return null;
	}

	private _renderScarves = (matchGroup: IMatchScarvesGroup) => {
		return (
			<ScarfContainer>
				{
					matchGroup.scarfCodes.map((x, i) => {
						return (
							<ImageRenderer
								key={i}
								src={`../../../img/scarf/${x}.jpg`}
								title={`${matchGroup.firstTeam?.name} vs ${matchGroup.secondTeam?.name}`}
								alt={`${matchGroup.firstTeam?.name} vs ${matchGroup.secondTeam?.name}`}
								isMobileViewActive={this.props.isMobileViewActive}
							/>
						);
					})
				}
			</ScarfContainer>
		);
	}

	private _renderMatchScarves = (matchScarf: IMatchScarvesGroup, index: number) => {
		return (
			<GroupContainer key={index}>
				{this._renderTitle(matchScarf)}
				<MatchResultContainer>
					{matchScarf.matches.map((x, i) => this._renderMatchResult(matchScarf.yearEnd, x, i, matchScarf.firstTeam, matchScarf.secondTeam, matchScarf.thirdTeam, matchScarf.fourthTeam))}
				</MatchResultContainer>
				{this._renderScarves(matchScarf)}
			</GroupContainer>
		);
	}

	private _renderMetaTitle = () => {
		return (
			<title>{'Scarves from football games - FootballScarfMuseumCollection'}</title>
		);
	}
	
	public render() {
		return (
			<>
				<Helmet>
					{this._renderMetaTitle()}
				</Helmet>
				<GalleryContainer imgSrc={`M_${this.state.imgNumber}`}>
					<ContentContainer>
						{this.props.matchScarfGroups.map((x, i) => this._renderMatchScarves(x, i))}
					</ContentContainer>
				</GalleryContainer>
			</>
		);
	}
}

export default connect(rootSelector)(MatchGallery);

