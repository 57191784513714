
export const GET_SCARVES = '/GET_SCARVES';
export const GET_INFO_OF_COUNTRY = '/GET_INFO_OF_COUNTRY';
export const GET_BASIC_DATA = '/GET_BASIC_DATA';
export const PUT_BASIC_INFO = '/PUT_BASIC_INFO';
export const PUT_DB_INFO = '/PUT_DB_INFO';
export const SET_INFO_OF_COUNTRY = '/SET_INFO_OF_COUNTRY';
export const CHANGE_ORDER_CATEGORY = '/CHANGE_ORDER_CATEGORY';
export const INIT = '/INIT';
export const CHANGE_ORDER_ASCENDING = '/CHANGE_ORDER_ASCENDING';
export const SET_SECTION_NAME = '/SET_SECTION_NAME';
export const SET_MAP_DETAIL_TEAM = '/SET_MAP_DETAIL_TEAM';
export const SET_HOME_DETAIL_TEAM = '/SET_HOME_DETAIL_TEAM';
export const CHANGE_CURRENT_PAGE = '/CHANGE_CURRENT_PAGE';
export const CHANGE_ITEMS_PER_PAGE = '/CHANGE_ITEMS_PER_PAGE';
export const SHOW_FILTER_AREA = '/SHOW_FILTER_AREA';
export const UPDATE_VIEW_PORT = '/UPDATE_VIEW_PORT';
export const SHOW_TREE_MAP = '/SHOW_TREE_MAP';
export const HIDE_TREE_MAP = '/HIDE_TREE_MAP';
export const FILTER_COMPETITION = '/FILTER_COMPETITION';
export const FILTER_CONFEDERATION = '/FILTER_CONFEDERATION';
export const CHANGE_MATCH_ORDER = '/CHANGE_MATCH_ORDER';
export const CHANGE_MATCH_ORDER_ASCENDING = '/CHANGE_MATCH_ORDER_ASCENDING';
export const SET_HIGHLIGHT_POP_UP = '/SET_HIGHLIGHT_POP_UP';
export const LOAD_TEAM_IMAGES_ASYNC = '/LOAD_TEAM_IMAGES_ASYNC';
export const SET_TROPHY_TOOLTIP = '/SET_TROPHY_TOOLTIP';
export const GET_HIGHLIGHTS = '/GET_HIGHLIGHTS';
export const SET_HIGHLIGHTS = '/SET_HIGHLIGHTS';
export const SET_REDUCED_VIEW = '/SET_REDUCED_VIEW';
export const SELECT_BY_COUNTRY_CODE = '/SELECT_BY_COUNTRY_CODE';
export const CONFIRM_SELECT_BY_COUNTRY = '/CONFIRM_SELECT_BY_COUNTRY';
export const SET_HONOURS_VISIBILITY = '/SET_HONOURS_VISIBILITY';
export const ADD_COMPETITION = '/ADD_COMPETITION';
export const CHANGE_RANGE_OF_EVOLUTION = '/CHANGE_RANGE_OF_EVOLUTION';
export const SET_TITLE_DETAIL = '/SET_TITLE_DETAIL';
export const HIDE_DETAIL_TROPHY_TOOLTIP = '/HIDE_DETAIL_TROPHY_TOOLTIP';
