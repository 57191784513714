import { all, fork } from 'redux-saga/effects';

import fetchSaga from './fetch';

export default function rootSaga() {
	return function* rootSagaGenerator() {
		yield all([
			fork(fetchSaga())
		]);
	};
}
