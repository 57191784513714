import * as actionTypes from '../actionTypes';
import { IStore } from '../interfaces';
import { initialState } from '../modules/constants';

export default function highlightsReducer(state: Readonly<IStore> = initialState, action: any) {
	// LEGACY
	switch (action.type) {
		case actionTypes.SET_HIGHLIGHT_POP_UP: {
			// const typedAction = action as ISetHighlightPopUp;
			// const team = state.teams.find(x => x.teamId === typedAction.teamId);
			// if (team) {
			// 	return {
			// 		...state,
			// 		highlightPopUp: {
			// 			highlights: team.highlights,
			// 			name: team.name,
			// 			teamId: typedAction.teamId
			// 		},
			// 		trophyTooltip: null
			// 	};
			// }
			return {
				...state,
				ui: {
					highlightPopUp: null,
					trophyTooltip: null
				}
			};
		}
		case actionTypes.SET_HIGHLIGHTS: {
			return state;
			// const typedAction = action as ISetHighlights;

			// const teams: ITeam[] = [];
			// const teamsIndicesMap: Map<number, number> = new Map();

			// const existingTeams = state.teams.length > 0 ? state.teams : typedAction.teams;
			// for (let i = 0; i < existingTeams.length; i++) {
			// 	teamsIndicesMap.set(existingTeams[i].team_id, i);
			// 	teams.push(existingTeams[i]);
			// }
			// for (const highlight of typedAction.highlights) {
			// 	const teamIndex = teamsIndicesMap.get(highlight.teamId);
			// 	if (teamIndex) {
			// 		teams[teamIndex].highlights.push(highlight);
			// 	}

			// 	// Check existence of highlight in YouTube (only developement)
			// 	// var img = new Image();
			// 	// const highlightId = highlight.link.replace('https://www.youtube.com/embed/', '');
			// 	// img.src = `http://img.youtube.com/vi/${highlightId}/mqdefault.jpg`;
			// 	// }
			// }
			// const currentGroupScarves = [];
			// for (const currentGroupScarf of state.currentGroupScarves) {
			// 	const newCurrentGroupScarfGroup = { ...currentGroupScarf };
			// 	const teamIndex = teamsIndicesMap.get(currentGroupScarf.team.teamId);
			// 	if (teamIndex !== undefined) {
			// 		newCurrentGroupScarfGroup.team = teams[teamIndex];
			// 	}
			// 	currentGroupScarves.push(newCurrentGroupScarfGroup);
			// }


			// return {
			// 	...state,
			// 	teams,
			// 	currentGroupScarves
			// };
		}
		default: {
			return state;
		}
	}
}
