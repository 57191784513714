import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import onClickOutside from 'react-onclickoutside';
import styled from 'styled-components';

import { IDropDownItemsContainerProps } from '../interfaces';
import { DROPDOWN_CONTAINER_MIN_HEIGHT, DROPDOWN_CONTAINER_MAX_HEIGHT } from '../constants';

interface IStyledDropDownItemsContainerProps {
	top?: number;
	bottom?: number;
	left: number;
	width: number;
	maxHeight: number;
}
const StyledDropDownItemsContainer = styled.div<IStyledDropDownItemsContainerProps>`
	position: absolute;
	${props => props.top ? `top: ${props.top}px;` : ''}
	${props => props.bottom ? `bottom: ${props.bottom}px;` : ''}
	left: ${props => props.left}px;
	width: ${props => props.width}px;

	height: auto;
	max-height: ${props => props.maxHeight}px;

	overflow-x: hidden;
	overflow-y: auto;

	border: 1px solid rgba(248,149,4, 0.5);
	color: rgba(248,149,4,1);
	border-radius: 12px;
	background-color: black;

	z-index: 1111114;
`;

class DropDownItemsContainer extends PureComponent<IDropDownItemsContainerProps> {
	private _close = () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	public handleClickOutside = (e: React.MouseEvent) => {
		if (this.props.refElement) {
			const rect = this.props.refElement.getBoundingClientRect();

			if ((e.clientX < rect.left || e.clientX > rect.right || e.clientY < rect.top || e.clientY > rect.bottom)) {
				// clicked outside
				this._close();
			}
		}
	};

	private _onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		this._close();
	}

	private _renderDropDownItemsContainer = (): JSX.Element | null => {
		if (this.props.refElement) {
			const clientRect = this.props.refElement.getBoundingClientRect();
			if (clientRect) {
				let top: number | undefined;
				let bottom: number | undefined;
				let maxHeight = DROPDOWN_CONTAINER_MIN_HEIGHT;

				// determine whether the DropDownContainer should be opened below (default) or above
				const pxBelow = window.innerHeight - clientRect.bottom;
				if (pxBelow < DROPDOWN_CONTAINER_MIN_HEIGHT) {
					// not enough space to open the DropDownContainer below - try to open above
					const pxAbove = clientRect.top;
					if (pxAbove < DROPDOWN_CONTAINER_MIN_HEIGHT) {
						// even not enough space to open the DropDownContainer above
						// use default values
					} else {
						// enough space available to open the DropDownContainer above
						bottom = window.innerHeight - clientRect.top;
						maxHeight = Math.min(DROPDOWN_CONTAINER_MAX_HEIGHT, pxAbove);
					}
				} else {
					// enough space availabe to open the DropDownContainer below
					top = clientRect.bottom;
					maxHeight = Math.min(DROPDOWN_CONTAINER_MAX_HEIGHT, pxBelow);
				}

				return (
					<StyledDropDownItemsContainer
						data-copid={'DropDownContainer'}
						className={'coplanner__DropDownContainer'} // do not remove this prop as it is used for handleClickOutside(); there is no css involved
						top={top}
						bottom={bottom}
						maxHeight={maxHeight}
						left={clientRect.left}
						width={clientRect.width}
						onClick={this._onClick}
					>
						{this.props.children}
					</StyledDropDownItemsContainer>
				);
			}
		}
		return null;
	}

	public render(): JSX.Element | null {
		const rootNode = document.getElementById('root');
		if (rootNode) {
			return ReactDOM.createPortal(
				this._renderDropDownItemsContainer(),
				rootNode
			);
		}
		return null;
	}
}

export default onClickOutside(DropDownItemsContainer);
