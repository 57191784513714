import * as actionTypes from '../actionTypes';
import { IStore } from '../interfaces';
import { OrderMatchCompetition, ConfederationsEnum } from '../interfaces/clientInterfaces';
import fetchReducer from './fetch';
import uiReducer from './ui';
import orderAndFilterReducer from './orderAndFilter';
import highlightsReducer from './highlights';
import sectionsReducer from './sections';
import { initialState } from '../modules/constants';

export const matchCompetitionDefault = [
	OrderMatchCompetition.ucl,
	OrderMatchCompetition.uel,
	OrderMatchCompetition.ucw,
	OrderMatchCompetition.usc,
	OrderMatchCompetition.cwc,
	OrderMatchCompetition.fic,
	OrderMatchCompetition.fwc,
	OrderMatchCompetition.uec,
	OrderMatchCompetition.cit,
	OrderMatchCompetition.ces,
	OrderMatchCompetition.lib,
	OrderMatchCompetition.cat,
	OrderMatchCompetition.sud,
	OrderMatchCompetition.uco
];

export const initialConfederations = [
	ConfederationsEnum.eu,
	ConfederationsEnum.sa,
	ConfederationsEnum.na,
	ConfederationsEnum.af,
	ConfederationsEnum.as
];

export default (state: IStore = initialState, action: any) => {
	switch (action.type) {
		case actionTypes.INIT: {
			return initialState;
		}
		case actionTypes.CHANGE_MATCH_ORDER:
		case actionTypes.CHANGE_MATCH_ORDER_ASCENDING:
		case actionTypes.CHANGE_ORDER_ASCENDING:
		case actionTypes.CHANGE_ORDER_CATEGORY:
		case actionTypes.FILTER_COMPETITION:
		case actionTypes.FILTER_CONFEDERATION:
			return orderAndFilterReducer(state, action);
		case actionTypes.CHANGE_ITEMS_PER_PAGE:
		case actionTypes.CHANGE_CURRENT_PAGE:
		case actionTypes.SET_INFO_OF_COUNTRY:
		case actionTypes.PUT_DB_INFO: {
			return fetchReducer(state, action);
		}
		case actionTypes.SET_HONOURS_VISIBILITY:
		case actionTypes.SET_REDUCED_VIEW:
		case actionTypes.SHOW_FILTER_AREA:
		case actionTypes.SHOW_TREE_MAP:
		case actionTypes.HIDE_TREE_MAP:
		case actionTypes.GET_INFO_OF_COUNTRY:
		case actionTypes.SET_SECTION_NAME:
		case actionTypes.UPDATE_VIEW_PORT:
		case actionTypes.SET_TROPHY_TOOLTIP:
		case actionTypes.ADD_COMPETITION:
		case actionTypes.CHANGE_RANGE_OF_EVOLUTION:
		case actionTypes.SET_TITLE_DETAIL:
		case actionTypes.HIDE_DETAIL_TROPHY_TOOLTIP:
			return uiReducer(state, action);
		case actionTypes.SET_HIGHLIGHTS:
		case actionTypes.SET_HIGHLIGHT_POP_UP:
			return highlightsReducer(state, action);

		case actionTypes.SET_MAP_DETAIL_TEAM:
		case actionTypes.SET_HOME_DETAIL_TEAM:
		case actionTypes.SELECT_BY_COUNTRY_CODE:
		case actionTypes.CONFIRM_SELECT_BY_COUNTRY:
			return sectionsReducer(state, action);
		default: {
			return state;
		}
	}
};
