import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Overlay from './Overlay';
import styled from 'styled-components';

const OverlayClickSurface = styled.div`
	width: 100%;
	height: 100%;
`;

interface IPopUpProps {
	onOverlayClick?(): void;
}

export default class PopUp extends Component<IPopUpProps, { portalContainer: Element | null }> {

	private _onOverlayClick = () => {
		if (this.props.onOverlayClick) {
			this.props.onOverlayClick();
		}
	}

	public constructor(props: {}) {
		super(props);
		this.state = {
			portalContainer: null
		};
	}

	public _renderContent = () => {
		return (
			<Overlay>
				<OverlayClickSurface onClick={this._onOverlayClick}/>
				{this.props.children}
			</Overlay>
		);
	}
	
	public componentDidMount() {
		// Adapt root id if changed
		const portalContainer = document.getElementById('root');
		if (portalContainer) {
			this.setState({
				portalContainer
			});
		}
	}

	public render() {
		if (this.state.portalContainer) {
			return ReactDOM.createPortal(
				this._renderContent(),
				this.state.portalContainer
			);
		} else {
			return null;
		}
	}
}
