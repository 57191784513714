import { createSelector } from 'reselect';
import { IStore, IExchangeInjectedProps } from '../../interfaces';

const stateSelector = (state: any) => state ? state : {};

export default createSelector(
	[
		stateSelector
	],
	(
		state: IStore
	): IExchangeInjectedProps => {
		return {
			exchangeableScarves: state.scarves.exchangeableScarves,
			isMobileViewActive: state.ui.isMobileViewActive
		};
	}
);
