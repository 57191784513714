import { ILinkProp } from '../interfaces/clientInterfaces';

// Ranking: number of exchanges + mails + relation + topic + initiative
export const links: ILinkProp[] = [
	{
		name: 'Philipp',
		link: 'http://www.globalscarves.webador.de/',
		country: 'DE',
		team: 'DE_MAI',
		description: 'A growing collection of scarves from multiple sports with a focus on football. Exchange recommended.',
		ranking: 4
	},
	{
		name: 'Tomas',
		link: 'http://www.scarves-hrubec.cz/',
		country: 'CZ',
		description: 'Almost 3000 scarves from all over the world. Exchange recommended.',
		ranking: 5
	},
	{
		name: 'Jelmer',
		link: 'https://voetbalsjaals.de.tl/',
		country: 'NL',
		team: 'NL_HEE',
		description: 'Biggest collection of the Netherlands. Interesting collection of SC Heerenveen. Exchange recommended.',
		ranking: 5
	},
	{
		name: 'Rob',
		link: 'http://www.footballscarves.nl/',
		team: 'NL_UTR',
		country: 'NL',
		description: 'Big collection from all over the world with a large set of FC Utrecht´s scarves.',
		ranking: 2
	},
	{
		name: 'Sander',
		link: 'http://www.footballscarf.nl',
		team: 'NL_PSV',
		country: 'NL',
		description: 'Big collection from european clubs. Exchange recommended.',
		ranking: 3
	},
	{
		name: 'David',
		mail: 'david.alonso75@hotmail.es',
		country: 'ES',
		team: 'ES_VLL',
		description: 'Great source of rare scarves for exchange. Exchange recommended.',
		ranking: 5
	},
	{
		name: 'Dejan',
		link: 'https://salmania.page.tl/',
		country: 'RS',
		team: 'RS_VOZ',
		description: 'Biggest collection of ex-yugoslav clubs. Exchange recommended.',
		ranking: 4
	},
	{
		name: 'Antti',
		link: 'http://www.saturday3.com/',
		country: 'EE',
		description: 'Collection with scarves from all over the world. Biggest of finnish and estonian clubs.',
		ranking: 4
	},
	{
		name: 'Adrián',
		link: 'https://lasbufandasdeadrian.jimdofree.com/',
		team: 'ES_SEV',
		country: 'ES',
		description: 'Big collection of spanish clubs and Sevilla FC scarves.',
		ranking: 0
	},
	{
		name: 'Stéphane ',
		link: 'http://sg-collectors-echarpes.ch/',
		team: 'CH_SIO',
		country: 'CH',
		description: 'Big collection of swiss clubs among others.',
		ranking: 3
	},
	{
		name: 'Museo Blanco',
		link: 'http://museoblanco.com/',
		team: 'ES_RMD',
		country: 'ES',
		description: 'Impressive collection of pennants of Real Madrid.',
		ranking: 3
	},
	{
		name: 'Romano',
		link: 'https://collectionfootballsportscarves.it.gg/',
		team: 'IT_NAP',
		country: 'IT',
		description: 'Big collection from all over the world and italian clubs.',
		ranking: 0
	},
	{
		name: 'Cachecolglorioso',
		link: 'http://www.cachecolglorioso.com/',
		country: 'PT',
		team: 'PT_BEN',
		description: 'Biggest collection of Benfica scarves.',
		ranking: 0
	}
];

