import React, { PureComponent } from 'react';
import styled from 'styled-components';
import onClickOutside from 'react-onclickoutside';
import { IDetailTrophyForTooltip, IDetailTrophyTooltip, OrderByDetailTrophyTooltip } from '../../interfaces/clientInterfaces';

interface IDetailTrophyTooltipProps {
	detailTrophyTooltip: IDetailTrophyTooltip;
	closeCallback(): void;
	isMobileViewActive: boolean;
}

interface IDetailTrophyTooltipState {
	orderBy: OrderByDetailTrophyTooltip;
	showCompleteRunnerUps: boolean;
	detailTrophies: IDetailTrophyForTooltip[];
}

const DetailTrophyTooltipContainer = styled.div`
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom 0;
	width: 85%;
	display: inline-table;
	max-height: 70%;
	border-radius: 4px;
	border: 1px  solid #F89504;
	color: white;
	text-align: center;
	text-shadow: 2px 2px 2px gold;
	background-color: black;
	padding: 8px;
	user-select: none;
	z-index: 1111114;
	p {
		margin: 5px 0 0 0;
	}
`;

interface IImage {
	isRunnerUp: boolean;
	isMobileViewActive: boolean;
}
const Image = styled.img<IImage>`
	max-height: ${props => props.isMobileViewActive ? '56px' : '65px'};
	object-fit: contain;
	filter: ${props => props.isRunnerUp ? 'opacity(0.5)' : 'drop-shadow(1px 4px 4px gold)'};
`;

interface IImagesContainer {
	isMobileViewActive: boolean;
}
const ImagesContainer = styled.div<IImagesContainer>`
	overflow-y: auto;
	flex-wrap: wrap;
	display: flex;
	max-height: ${props => props.isMobileViewActive ? 450 : 700}px;
	overflow-y: auto;
	padding: 10px;
`;

const Title = styled.div`
	font-size: 18px;
	margin: 5px auto;
`;

const ImageContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 3px;
	margin: auto;
`;

const Year = styled.div`
	flex: 0;
	font-size: 12px;
`;

const SortIcon = styled.i`
	font-size: 18px;
	color: #F89504
	padding: 5px;
	text-shadow: none;
	cursor: pointer;
`;

//#endregion

class DetailTrophyTooltip extends PureComponent<IDetailTrophyTooltipProps, IDetailTrophyTooltipState> {

	constructor(props: IDetailTrophyTooltipProps) {
		super(props);
		this.state = {
			showCompleteRunnerUps: false,
			orderBy: props.detailTrophyTooltip.orderBy,
			detailTrophies: props.detailTrophyTooltip.orderBy === OrderByDetailTrophyTooltip.competition
				? props.detailTrophyTooltip.detailTrophiesByCompetition
				: props.detailTrophyTooltip.detailTrophiesByYearEnd
		};
	}

	public handleClickOutside = (e: React.MouseEvent) => {
		this.props.closeCallback();
	};

	private _onSortIconClick = () => {
		if (this.state.orderBy === OrderByDetailTrophyTooltip.chronologically) {
			this.setState({
				showCompleteRunnerUps: false,
				orderBy: OrderByDetailTrophyTooltip.competition,
				detailTrophies: this.props.detailTrophyTooltip.detailTrophiesByCompetition
			});
		} else {
			this.setState({
				showCompleteRunnerUps: false,
				orderBy: OrderByDetailTrophyTooltip.chronologically,
				detailTrophies: this.props.detailTrophyTooltip.detailTrophiesByYearEnd
			});
		}
	}

	private _onRunnerUpIconClick = () => {
		const showCompleteRunnerUps = !this.state.showCompleteRunnerUps;
		if (showCompleteRunnerUps) {
			this.setState({
				showCompleteRunnerUps,
				detailTrophies: this.state.orderBy === OrderByDetailTrophyTooltip.competition 
					? this.props.detailTrophyTooltip.completeDetailTrophiesByCompetition
					: this.props.detailTrophyTooltip.completeDetailTrophiesByYearEnd
			});
		} else {
			this.setState({
				showCompleteRunnerUps,
				detailTrophies: this.state.orderBy === OrderByDetailTrophyTooltip.competition 
					? this.props.detailTrophyTooltip.detailTrophiesByCompetition
					: this.props.detailTrophyTooltip.detailTrophiesByYearEnd
			});
		}
	}

	private _renderIcons = () => {
		return (
			<>
				<SortIcon
					className={this.state.orderBy === OrderByDetailTrophyTooltip.chronologically ? 'fa fa-clock' : 'fa fa-trophy'}
					title={`Click to order ${this.state.orderBy === OrderByDetailTrophyTooltip.chronologically ? 'by competition' : 'chronologically'}`}
					onClick={this._onSortIconClick}
				/>
				{
					this.state.orderBy === OrderByDetailTrophyTooltip.chronologically
						? <SortIcon
							className={this.state.showCompleteRunnerUps ? 'fa fa-list' : 'fa fa-award'}
							title={`Click to show ${this.state.showCompleteRunnerUps ? 'only relevant' : 'complete'} runner ups`}
							onClick={this._onRunnerUpIconClick}
						/>
						: null
				}
				
			</>
			
		);
	}

	public render() {
		return (
			<DetailTrophyTooltipContainer>
				<Title>
					{this.props.detailTrophyTooltip.title}
					{this._renderIcons()}
				</Title>
				<ImagesContainer isMobileViewActive={this.props.isMobileViewActive}>
					{this.state.detailTrophies.map(detailTrophy => {
						return (
							<ImageContainer>
								<Year>{detailTrophy.yearEndText}</Year>
								<Image
									title={`${detailTrophy.isRunnerUp ? 'Runner up ' : ''}${detailTrophy.text}`}
									src={`./../../img/trophy/${detailTrophy.trophy}.png`}
									isRunnerUp={detailTrophy.isRunnerUp}
									isMobileViewActive={this.props.isMobileViewActive}
								/>
							</ImageContainer>
						);
					})}
				</ImagesContainer>
			</DetailTrophyTooltipContainer>
		);
	}
}

export default onClickOutside(DetailTrophyTooltip);
