import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import rootSelector from '../selectors/link';
import { ILinkCombinedProps } from '../../interfaces';
import styled from 'styled-components';
import { links } from '../../utils/links';

const LinkContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	margin: auto;
	user-select: none;
	overflow-y: auto;
	user-select: none;
	background: url('../img/background/hof.jpg') no-repeat center center fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
`;

const DescriptionContainer = styled.div`
	max-width: 700px;
	margin: 20px auto 40px auto;
	text-align: justify;
	text-shadow: 1px 1px 1px black;
	font-family: Europa-regular;
	color: white;
	padding: 15px;
	b {
		font-weight: normal !important;
	}
`;

interface ICollectorContainer {
	isMobileViewActive: boolean;
}
const CollectorsContainer = styled.div<ICollectorContainer>`
	width: ${props => props.isMobileViewActive ? '90%' : '600px'};
	margin: 0 auto 0 auto;
	text-shadow: -2px -2px 2px black;
`;

const CollectorContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: auto auto 30px auto;
	font-family: Europa-regular;
`;

const CollectorSubContainer = styled.div`
	display: flex;
	flex-direction: row;
	height: 30px;
	margin: auto;
`;

const NameContainer = styled.div`
	font-size: 20px;
`;

const FlagImg = styled.img`
	max-height: 25px;
	margin-right: 10px;
`;

const ShieldImg = styled.img`
	max-height: 30px;
	margin-right: 10px;
`;

const CollectorLink = styled.a`
	color: white;
`;

const CollectorMail = styled.div`
	color: white;
	i {
		margin-right: 5px;
	}
`;

const CollectorDescriptionContainer = styled.div`
	font-size: 12px;
	font-style: italic;
`;

class Link extends PureComponent<ILinkCombinedProps, { }> {

	public render() {
		const collectors = links.sort((a, b) => a.ranking > b.ranking ? -1 : 1);
		return (
			<LinkContainer>
				<DescriptionContainer>
					<b>{'Here you can find a list of fellow collectors, that are either trustable for exchanges or have a remarkable collection worth to take a look at.'}</b>
				</DescriptionContainer>
				<CollectorsContainer isMobileViewActive={this.props.isMobileViewActive}>
					{collectors.map((x, i) => {
						return (
							<CollectorContainer key={`Links_${i}`}>
								<CollectorSubContainer>
									{x.country ? <FlagImg src={`../../../img/flag/${x.country}.png`}/> : null}
									{x.team ? <ShieldImg src={`../../../img/shield/${x.team}.png`}/> : null}
									<NameContainer>{x.name}</NameContainer>
								</CollectorSubContainer>
								<CollectorSubContainer>
									{x.link ? <CollectorLink href={x.link} target='_blank'>{x.link}</CollectorLink> : null}
									{x.mail ? <CollectorMail title={x.mail}><i className='far fa-envelope'/>{x.mail}</CollectorMail> : null}
								</CollectorSubContainer>
								<CollectorSubContainer>
									<CollectorDescriptionContainer>
										{x.description}
									</CollectorDescriptionContainer>
								</CollectorSubContainer>
							</CollectorContainer>
						);
					})}
				</CollectorsContainer>
			</LinkContainer>
		);
	}
}

export default connect(rootSelector)(Link);

