
import React from 'react';

import { useParams } from 'react-router-dom';
import { Localizer } from '../../utils/localizer';
import TeamGalleryContent from './TeamGalleryContent';

export default function TeamGalleryRoute(): JSX.Element {
	const { countryEnglishName } = useParams() as { countryEnglishName: string; };
	const localizedCountryCode = Localizer.getCountryCodeFromLocalizedEnglishName(countryEnglishName as string);
	return (
		<TeamGalleryContent
			code={localizedCountryCode}
			name={countryEnglishName}
		/>
	);
}

