import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { IDropDownItemProps } from '../interfaces';
import { DROPDOWN_ITEM_HEIGHT } from '../constants';

const StyledDropDownItem = styled.div`
	display: flex;
	align-items: center;
	height: ${DROPDOWN_ITEM_HEIGHT}px;
	line-height: ${DROPDOWN_ITEM_HEIGHT}px;
`;

export default class DropDownItem extends PureComponent<IDropDownItemProps> {
	private _divRef: HTMLDivElement | null = null;
	private _divOnGetRef = (div: HTMLDivElement | null) => this._divRef = div;

	public componentDidMount() {
		if (this.props.isSelected && this._divRef) {
			this._divRef.scrollIntoView({ block: 'center' });
		}
	}

	public componentDidUpdate(prevProps: IDropDownItemProps) {
		if (this.props.isSelected && !prevProps.isSelected && this._divRef) {
			this._divRef.scrollIntoView({ block: 'nearest' });
		}
	}

	private _onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (this.props.onClick) {
			this.props.onClick(this.props.item);
		}
	}

	public render(): JSX.Element {
		return (
			<StyledDropDownItem
				ref={this._divOnGetRef}
				data-copid={`DropDownItem_${this.props.index}`}
				onClick={this._onClick}
			>
				{this.props.children}
			</StyledDropDownItem>
		);
	}
}
