import { ISelectByCountryCode, ISetHomeDetailTeam, ISetMapDetailTeam } from '../actions';
import * as actionTypes from '../actionTypes';
import { IStore } from '../interfaces';
import { IScarfInfo } from '../interfaces/clientInterfaces';
import { initialState } from '../modules/constants';
import { GroupScarves } from '../utils/groupScarves';

export default function sectionsReducer(state: Readonly<IStore> = initialState, action: any) {
	switch (action.type) {
		case actionTypes.SET_MAP_DETAIL_TEAM: {
			const typedAction = action as ISetMapDetailTeam;
			const team = state.teams.teamsById[typedAction.teamId];
			if (team) {
				const scarves = state.scarves.scarvesByTeamMap[team.teamId];
				if (scarves && scarves.length > 0 && team.stadium && team.city) {
					return {
						...state,
						map: {
							...state.map,
							mapDetailScarfInfo: {
								team,
								scarves,
								stadium: team.stadium,
								city: team.city
							}
						},
						ui: {
							...state.ui,
							trophyTooltip: null
						}
					};
				}
			} else {
				return {
					...state,
					map: {
						...state.map,
						mapDetailScarfInfo: null
					},
					ui: {
						...state.ui,
						trophyTooltip: null
					}
				};
			}
			return state;
		}
		case actionTypes.SET_HOME_DETAIL_TEAM: {
			const typedAction = action as ISetHomeDetailTeam;
			const team = state.teams.teamsById[typedAction.teamId];
			if (team) {
				const scarves = state.scarves.scarvesByTeamMap[team.teamId];
				if (scarves && scarves.length > 0) {
					return {
						...state,
						general: {
							...state.general,
							homeDetailScarfInfo: {
								team,
								scarves,
								stadium: team.stadium,
								city: team.city
							},
							trophyTooltip: null
						}
					};
				}
			} else {
				return {
					...state,
					general: {
						...state.general,
						homeDetailScarfInfo: null,
						trophyTooltip: null
					}

				};
			}
			return state;
		}
		case actionTypes.SELECT_BY_COUNTRY_CODE: {
			const typedAction = action as ISelectByCountryCode;
			return {
				...state,
				orderAndPaging: {
					...state.orderAndPaging,
					orderBy: {
						...state.orderAndPaging.orderBy,
						countryCodes: {
							...state.orderAndPaging.orderBy.countryCodes,
							[typedAction.countryCode]: state.orderAndPaging.orderBy.countryCodes[typedAction.countryCode] ? false : true
						}
					}
				}
			};
		}
		case actionTypes.CONFIRM_SELECT_BY_COUNTRY: {
			let currentGroupScarves: IScarfInfo[] = [];
			for (const countryCode of state.general.countryCodes) {
				if (state.orderAndPaging.orderBy.countryCodes[countryCode]) {
					const teamsOfCountryCode = state.teams.teamsByCountryCode[countryCode];
					if (teamsOfCountryCode) {
						for (const team of teamsOfCountryCode) {
							const scarves = state.scarves.scarvesByTeamMap[team.teamId];
							if (scarves && scarves.length > 0) {
								currentGroupScarves.push({
									scarves,
									city: team.city,
									team,
									stadium: team.stadium
								});
							}
						}
					}
				}

			}
			currentGroupScarves = GroupScarves.sortScarvesInfo(currentGroupScarves, state.orderAndPaging.orderBy.category, state.orderAndPaging.orderBy.ascending);
			const totalPages = Math.ceil(currentGroupScarves.length / state.orderAndPaging.allPagingInfo.teamsPerPage);
			const currentPage = 0;

			const showTreeMap = state.ui.isMobileViewActive ? false : state.ui.showTreeMap;
			return {
				...state,
				scarves: {
					...state.scarves,
					currentGroupScarves
				},
				orderAndPaging: {
					...state.orderAndPaging,
					allPagingInfo: {
						...state.orderAndPaging.allPagingInfo,
						totalPages,
						currentPage
					}
				},
				ui: {
					...state.ui,
					showTreeMap,
					trophyTooltip: null
				}
			};
		}
		default: {
			return state;
		}
	}
}
