import { createSelector } from 'reselect';
import { IStore, IMatchGalleryInjected } from '../../interfaces';

const stateSelector = (state: any) => state ? state : {};

export default createSelector(
	[
		stateSelector
	],
	(
		state: IStore
	): IMatchGalleryInjected => {
		return {
			matchScarfGroups: state.matches.currentRMMatchScarfGroups,
			isMobileViewActive: state ? state.ui.isMobileViewActive : false
		};
	}
);
