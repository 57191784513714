import React, { useMemo } from 'react';
import styled from 'styled-components';
import { IStore } from '../../interfaces';
import { IAvailableCompetition, ISelectedEvolutions, ITeam } from '../../interfaces/clientInterfaces';
import { useSelector } from 'react-redux';

interface ITeamBar {
	name: string;
	teamCode: string;
	regionCode: string;
	seasonsOnTop: number;
	ranking: number;
	isInTopDivision: boolean;
	isDissolved: boolean;
}

//#region styled Components
interface IEvolutionContainer {
	isMobileViewActive: boolean;
}
const EvolutionContainer = styled.div<IEvolutionContainer>`
	display: flex;
	margin: 10px;
	flex: 1;
	flex-direction: column;
	overflow-y: auto;
	margin: ${props => props.isMobileViewActive ? 5 : 15}px;
	background-color: rgba(255, 255, 255, 0.4);
	border-radius: 4px;
	padding: 10px;
	color: black;
`;

const TotalSeasonsContainer = styled.div`
	flex-direction: row;
	margin: 10px 10px 10px 10px;
	display: flex;
	
`;

const TeamTimeHonourContainer = styled.div`
	flex-direction: row;
	margin: 10px;
	display: flex;
`;
interface ITeamTimeHonourNameAndImage {
	isMobileViewActive: boolean;
}
const TeamTimeHonourNameAndImage = styled.div<ITeamTimeHonourNameAndImage>`
	max-width: ${props => props.isMobileViewActive ? '140px' : '180px'};
	min-width: ${props => props.isMobileViewActive ? '140px' : '180px'};
	display: flex;
`;

interface ITeamShieldImage {
	isMobileViewActive: boolean;
	doesRegionFlagExist: boolean
}
const TeamShieldImage = styled.img<ITeamShieldImage>`
	max-height: ${props => props.isMobileViewActive ? '25px' : '30px'};
	margin: auto ${props => props.doesRegionFlagExist ? '0' : '3px'};;
`;

interface ITeamScarfImage {
	isMobileViewActive: boolean;
}
const TeamScarfImage = styled.img<ITeamScarfImage>`
	max-width: ${props => props.isMobileViewActive ? '120px' : '160px'};
	margin: auto;
`;

const LegendContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const TitleNameContainer = styled.div<ITeamScarfImage>`
	font-size: ${props => props.isMobileViewActive ? '14px' : '16px'};
	line-height: 30px;
	font-family: 'Europa-big';
	color: white;
	margin: 0 5px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	border-radius: 10px;
	text-shadow: 2px 2px 2px black;
`;

const TotalNameContainer = styled(TitleNameContainer)`
	margin-left: auto;
`;

const LegendContainerTop = styled(TitleNameContainer)`
	text-shadow: 2px 2px 2px white;
	color: black;
	line-height: 1;
	font-size: ${props => props.isMobileViewActive ? '10px' : '12px'};
	margin-bottom 5px;
`;

const LegendContainerDissolved = styled(TitleNameContainer)`
	font-style: italic;
	line-height: 1;
	font-size: ${props => props.isMobileViewActive ? '10px' : '12px'};
`;

const TeamNameContainer = styled.div<ITeamScarfImage>`
	font-size: ${props => props.isMobileViewActive ? '10px' : '12px'};
	line-height: 30px;
	font-family: 'Europa-big';
	margin: 0 5px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	border-radius: 10px;
`;

const TitlesBarContainer = styled.div`
	margin: 0 5px;
	height: 30px;
	width: 100%;
	position: relative;
`;

interface IChampionsBarContainer {
	width: number;
}
const ChampionsBarContainer = styled.div<IChampionsBarContainer>`
	position: relative;
	height: 100%;
	width: ${props => props.width}%;
	background-color: #F89504;
	-webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;
	display: flex;
	flex-direction: row;
`;
interface IContentOfBar {
	isInTopDivision: boolean;
	isDissolved: boolean;
}
const ContentOfBar = styled.div<IContentOfBar>`
	background-color: transparent;
	width: 100%;
	position: absolute;
	color: ${props => props.isInTopDivision ? 'black' : 'white'};
	text-shadow: 2px 2px 2px ${props => props.isInTopDivision ? 'white' : 'black'};
	display: flex;
	flex-direction: row;
	z-index: 10;
	padding: 0 2px;
	font-style: ${props => props.isDissolved ? 'italic' : 'normal'};
`;

interface ITitlesNumberContainer {
	left: number;
}

const TitlesNumberContainer = styled.div<ITitlesNumberContainer>`
	font-size: 14px;
	line-height: 30px;
	font-family: 'Europa-big';
`;

const RegionImage = styled.img`
	width: 20px;
	margin: auto 5px;
`;

interface IParticipationsInjectedProps {
	teams: ITeam[];
	availableCompetitions: IAvailableCompetition[];
	selectedEvolutions: ISelectedEvolutions[];
	isMobileViewActive: boolean;
}

export default function Participations() {
	const {
		teams,
		availableCompetitions,
		selectedEvolutions,
		isMobileViewActive,
	} = useSelector(
		(completeState: IStore): IParticipationsInjectedProps => {
			return {
				teams: completeState.teams.teams,
				availableCompetitions: completeState.competitions.availableCompetitions,
				selectedEvolutions: completeState.competitions.selectedEvolutions,
				isMobileViewActive: completeState.ui.isMobileViewActive
			}
		});

	const { orderedTeams, totalParticipations } = useMemo(
		(): {
			orderedTeams: ITeamBar[];
			totalParticipations: number;
		 } => {
			if (selectedEvolutions.length < 1) {
				return {
					orderedTeams: [],
					totalParticipations: 0,
				};
			}
			const competitionName = selectedEvolutions[0].competitionName;
			const competition = availableCompetitions.find(competition => competition.name === competitionName);
			if (!competition) {
				return {
					orderedTeams: [],
					totalParticipations: 0,
				};
			}
			const filteredTeams: ITeamBar[] = [];
			for (const team of teams) {
				if (competition.regionCode === 'DR' || competition.regionCode === 'UR' || competition.regionCode === 'ZK' || competition.regionCode === 'YU') {
					if (team.countryCode2 === competition.regionCode && team.seasonsTop2 && team.seasonsTop2 > 0) {
						filteredTeams.push({
							name: team.name,
							teamCode: `${team.countryCode}_${team.teamCode}`,
							regionCode: competition.regionCode === 'DR' ? '' : team.countryCode,
							seasonsOnTop: team.seasonsTop2,
							ranking: team.overallRanking,
							isInTopDivision: false,
							isDissolved: team.dissolved !== null
						});
					}

				} else {
					if (team.countryCode === competition.regionCode) {
						if (team.seasonsTop && team.seasonsTop > 0) {
							filteredTeams.push({
								name: team.name,
								teamCode: `${team.countryCode}_${team.teamCode}`,
								regionCode: team.countryCode2 === 'MC' ? 'MC' : '',
								seasonsOnTop: team.seasonsTop,
								ranking: team.overallRanking,
								isInTopDivision: team.category === 1,
								isDissolved: team.dissolved !== null
							});
						}
					} else if (team.countryCode2 === competition.regionCode) {
						if (team.seasonsTop2 && team.seasonsTop2 > 0) {
							filteredTeams.push({
								name: team.name,
								teamCode: `${team.countryCode}_${team.teamCode}`,
								regionCode: team.countryCode,
								seasonsOnTop: team.seasonsTop2,
								ranking: team.overallRanking,
								isInTopDivision: team.countryCode === 'WL' || team.countryCode === 'NI' ? team.category === 1 : false,
								isDissolved: team.dissolved !== null
							});
						}
					}
				}
			}
		
			return {
				orderedTeams: filteredTeams.sort((a, b) => {
					return a.seasonsOnTop > b.seasonsOnTop ? -1 : a.seasonsOnTop < b.seasonsOnTop ? 1 : a.ranking >= b.ranking ? -1 : 1;
				}),
				totalParticipations: competition.totalEditions ?? 0,
			};
		},
		[selectedEvolutions, availableCompetitions, teams]
	);

	return (
		<EvolutionContainer isMobileViewActive={isMobileViewActive}>
			<TotalSeasonsContainer>
				<LegendContainer>
					<LegendContainerTop isMobileViewActive={isMobileViewActive}>{'Team in top category'}</LegendContainerTop>
					<LegendContainerDissolved isMobileViewActive={isMobileViewActive}>{'Team dissolved'}</LegendContainerDissolved>
				</LegendContainer>
				<TotalNameContainer isMobileViewActive={isMobileViewActive}>{`Total Seasons: ${totalParticipations}`}</TotalNameContainer>
			</TotalSeasonsContainer>
			{
				orderedTeams.map(team => {
					const width = 100 * team.seasonsOnTop / totalParticipations;
					return (
						<TeamTimeHonourContainer>
							<TeamTimeHonourNameAndImage isMobileViewActive={isMobileViewActive}>
								<TeamScarfImage
									isMobileViewActive={isMobileViewActive}
									src={`./../../img/scarf/${team.teamCode}.jpg`}
								/>
							</TeamTimeHonourNameAndImage>
							<TitlesBarContainer>
								<ContentOfBar
									isInTopDivision={team.isInTopDivision}
									isDissolved={team.isDissolved}
								>
									{team.regionCode
										? <RegionImage
											src={`./../../img/flag/${team.regionCode}.png`}
										/>
										: null	
									}
									<TeamShieldImage
										doesRegionFlagExist={team.regionCode ? true : false}
										isMobileViewActive={isMobileViewActive}
										src={`./../../img/shield/${team.teamCode}.png`}
										title={team.name}
									/>
									<TeamNameContainer
										isMobileViewActive={isMobileViewActive}
									>{team.name}</TeamNameContainer>
									<TitlesNumberContainer left={width}>
										{`${team.seasonsOnTop} ${isMobileViewActive ? '' : 'seasons'}`}
									</TitlesNumberContainer>
								</ContentOfBar>
								<ChampionsBarContainer width={width}/>
							</TitlesBarContainer>
						</TeamTimeHonourContainer>
					);
				})
			}
		</EvolutionContainer>
	);
}

