import * as actionTypes from '../actionTypes';
import { ISetInfoOfCountry, ISetSectionNameAction, ISetTrophyTooltip, ISetReducedView, IUpdateViewPort, IAddCompetition, IChangeRangeOfEvolution, ISetTitleDetail } from '../actions';
import { IStore } from '../interfaces';
import { EnumSection, IDetailTrophyForTooltip, IDetailTrophyTooltip, ISelectedEvolutions, MatchOrderCategoriesEnum, OrderByDetailTrophyTooltip } from '../interfaces/clientInterfaces';
import { initialState } from '../modules/constants';
import { Competitions } from '../utils/competitions';

export default function uiReducer(state: Readonly<IStore> = initialState, action: any) {
	switch (action.type) {
		case actionTypes.SHOW_FILTER_AREA: {
			const showFilterArea = !state.ui.showFilterArea;
			return {
				...state,
				ui: {
					...state.ui,
					showFilterArea,
					showTreeMap: false,
					trophyTooltip: null
				}
			};
		}
		case actionTypes.SHOW_TREE_MAP: {
			return {
				...state,
				ui: {
					...state.ui,
					showTreeMap: true,
					showFilterArea: false,
					trophyTooltip: null
				}
			};
		}
		case actionTypes.HIDE_TREE_MAP: {
			return {
				...state,
				ui: {
					...state.ui,
					showTreeMap: false
				}
			};
		}
		case actionTypes.GET_INFO_OF_COUNTRY: {
			const typedAction = action as ISetInfoOfCountry;
			if (!typedAction.name) {
				return state;
			}
			return {
				...state,
				ui: {
					...state.ui,
					currentCountry: typedAction.name
				}
			};
		}
		case actionTypes.SET_SECTION_NAME: {
			const typedAction = action as ISetSectionNameAction;
			const showTreeMap = state.ui.isMobileViewActive ? false : state.ui.showTreeMap;

			const matchCategory = typedAction.section === EnumSection.rm
				? [MatchOrderCategoriesEnum.chronologically]
				: typedAction.section === EnumSection.matches
					? [MatchOrderCategoriesEnum.chronologically, MatchOrderCategoriesEnum.competition, MatchOrderCategoriesEnum.round]
					: state.orderAndPaging.orderBy.matchCategory;
			const matchAscending = typedAction.section === EnumSection.rm || typedAction.section === EnumSection.matches ? false : state.orderAndPaging.orderBy.ascending;
			return {
				...state,
				competitions: {
					...state.competitions,
					selectedEvolutions: []
				},
				general: {
					...state.general,
					homeDetailScarfInfo: null
				},
				ui: {
					...state.ui,
					section: typedAction.section,
					showTreeMap,
					highlightPopUp: null,
					showFilterArea: false
				},
				orderAndPaging: {
					...state.orderAndPaging,
					orderBy: {
						...state.orderAndPaging.orderBy,
						matchAscending,
						matchCategory
					}
				}
			};
		}
		case actionTypes.UPDATE_VIEW_PORT: {
			const typedAction = action as IUpdateViewPort;
			const isMobileViewActive = typedAction.width <= 834 && typedAction.height <= 1112;
			return {
				...state,
				ui: {
					...state.ui,
					viewPort: {
						width: typedAction.width,
						height: typedAction.height
					},
					showTreeMap: false,
					isMobileViewActive,
					trophyTooltip: null
				}
			};
		}
		case actionTypes.SET_TROPHY_TOOLTIP: {
			const typedAction = action as ISetTrophyTooltip;
			if (!typedAction.trophyTooltip) {
				return {
					...state,
					ui: {
						...state.ui,
						trophyTooltip: null
					}
				};
			}
			let x = typedAction.trophyTooltip.x;
			const viewPort = state.ui.viewPort;
			if (viewPort.width < x + 275) {
				x = viewPort.width - 300;
			}
			return {
				...state,
				ui: {
					...state.ui,
					trophyTooltip: {
						x,
						y: state.ui.isMobileViewActive ? typedAction.trophyTooltip.y - 60 : typedAction.trophyTooltip.y,
						title: typedAction.trophyTooltip.title,
						editions: typedAction.trophyTooltip.editions,
						description: typedAction.trophyTooltip.description
					}
				}
			};
		}

		case actionTypes.SET_REDUCED_VIEW: {
			const typedAction = action as ISetReducedView;
			const teamsPerPage = typedAction.isReduced ? 50 : state.orderAndPaging.allPagingInfo.teamsPerPage;
			const totalPages = Math.ceil(state.scarves.currentGroupScarves.length / teamsPerPage);
			return {
				...state,
				ui: {
					...state.ui,
					isReducedView: typedAction.isReduced,
					trophyTooltip: null
				},
				orderAndPaging: {
					...state.orderAndPaging,
					allPagingInfo: {
						...state.orderAndPaging.allPagingInfo,
						teamsPerPage,
						totalPages,
					}
				}
			};
		}
		case actionTypes.SET_HONOURS_VISIBILITY: {
			return {
				...state,
				ui: {
					...state.ui,
					areTeamHonoursVisible: !state.ui.areTeamHonoursVisible
				}
			};
		}
		case actionTypes.ADD_COMPETITION: {
			const typedAction = action as IAddCompetition;
			const evolution = state.competitions.evolutions[typedAction.competitionId];
			if (!evolution) {
				return state;
			}
			const selectedEvolution: ISelectedEvolutions = Competitions.getCompetitionEvolutionOfRange(
				0,
				evolution.editions.length - 1,
				evolution,
				state.teams.teamsById,
				state.competitions.competitionsDictionary[evolution.competitionId]
			);
			const selectedEvolutions: ISelectedEvolutions[] = [selectedEvolution];
			return {
				...state,
				competitions: {
					...state.competitions,
					selectedEvolutions
				}
			};
		}

		case actionTypes.CHANGE_RANGE_OF_EVOLUTION: {
			const typedAction = action as IChangeRangeOfEvolution;
			const currentEvolution = state.competitions.selectedEvolutions[typedAction.competitionIndex];
			if (!currentEvolution) {
				return state;
			}
			const selectedEvolution: ISelectedEvolutions = Competitions.getCompetitionEvolutionOfRange(
				typedAction.startIndex,
				typedAction.endIndex,
				currentEvolution.evolution,
				state.teams.teamsById,
				state.competitions.competitionsDictionary[currentEvolution.evolution.competitionId]
			);
			const selectedEvolutions: ISelectedEvolutions[] = state.competitions.selectedEvolutions.map((ev, idx) => {
				if (idx === typedAction.competitionIndex) {
					return selectedEvolution;
				}
				return ev;
			});
			return {
				...state,
				competitions: {
					...state.competitions,
					selectedEvolutions
				}
			};
		}
		case actionTypes.SET_TITLE_DETAIL: {
			const typedAction = action as ISetTitleDetail;

			const detailTrophiesByCompetition: IDetailTrophyForTooltip[] = [];
			const completeDetailTrophiesByCompetition: IDetailTrophyForTooltip[] = [];
			for (const champion of typedAction.team.honours.champion) {
				const competition = state.competitions.competitionsDictionary[champion.competitionId];
				if (competition) {
					for (const detailedTrophy of champion.detailedTrophies) {
						detailTrophiesByCompetition.push({
							trophy: `${competition.region_code}_${competition.competition_code}${detailedTrophy.trophy ? `_${detailedTrophy.trophy}` : ''}`,
							text: `${competition.name} ${detailedTrophy.text}`,
							yearEnd: detailedTrophy.yearEnd,
							yearEndText: detailedTrophy.yearEndText,
							isRunnerUp: false
						});
					}
				}
			}
			for (const champion of typedAction.team.honours.completeChampion) {
				const competition = state.competitions.competitionsDictionary[champion.competitionId];
				if (competition) {
					for (const detailedTrophy of champion.detailedTrophies) {
						const detailTrophy: IDetailTrophyForTooltip = {
							trophy: `${competition.region_code}_${competition.competition_code}${detailedTrophy.trophy ? `_${detailedTrophy.trophy}` : ''}`,
							text: `${competition.name} ${detailedTrophy.text}`,
							yearEnd: detailedTrophy.yearEnd,
							yearEndText: detailedTrophy.yearEndText,
							isRunnerUp: false
						};
						completeDetailTrophiesByCompetition.push(detailTrophy);
					}
				}
			}
			for (const runnerUp of typedAction.team.honours.runnerUp) {
				const competition = state.competitions.competitionsDictionary[runnerUp.competitionId];
				if (competition) {
					for (const detailedTrophy of runnerUp.detailedTrophies) {
						detailTrophiesByCompetition.push({
							trophy: `${competition.region_code}_${competition.competition_code}${detailedTrophy.trophy ? `_${detailedTrophy.trophy}` : ''}`,
							text: `${competition.name} ${detailedTrophy.text}`,
							yearEnd: detailedTrophy.yearEnd,
							yearEndText: detailedTrophy.yearEndText,
							isRunnerUp: true
						});
					}
				}
			}
			for (const runnerUp of typedAction.team.honours.completeRunnerUp) {
				const competition = state.competitions.competitionsDictionary[runnerUp.competitionId];
				if (competition) {
					for (const detailedTrophy of runnerUp.detailedTrophies) {
						completeDetailTrophiesByCompetition.push({
							trophy: `${competition.region_code}_${competition.competition_code}${detailedTrophy.trophy ? `_${detailedTrophy.trophy}` : ''}`,
							text: `${competition.name} ${detailedTrophy.text}`,
							yearEnd: detailedTrophy.yearEnd,
							yearEndText: detailedTrophy.yearEndText,
							isRunnerUp: true
						});
					}
				}
			}
			const detailTrophiesByYearEnd = detailTrophiesByCompetition.slice().sort((a, b) => {
				if (a.yearEnd < b.yearEnd) {
					return -1;
				} else {
					return 1;
				}
			});
			const completeDetailTrophiesByYearEnd = completeDetailTrophiesByCompetition.slice().sort((a, b) => {
				if (a.yearEnd < b.yearEnd) {
					return -1;
				} else {
					return 1;
				}
			});
			const detailTrophyTooltip: IDetailTrophyTooltip = {
				title: `Honours of ${typedAction.team.name}`,
				detailTrophiesByCompetition,
				detailTrophiesByYearEnd,
				completeDetailTrophiesByCompetition,
				completeDetailTrophiesByYearEnd,
				orderBy: OrderByDetailTrophyTooltip.chronologically,
				showCompleteRunnerUps: false
			};
			return {
				...state,
				ui: {
					...state.ui,
					detailTrophyTooltip
				}
			};
		}
		case actionTypes.HIDE_DETAIL_TROPHY_TOOLTIP: {
			return {
				...state,
				ui: {
					...state.ui,
					detailTrophyTooltip: null
				}
			};
		}
		default: {
			return state;
		}
	}
}
