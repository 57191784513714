import { IDBCity } from '../interfaces/dbInterfaces';

export class Localizer {

	public static getCountryCodeFromLocalizedEnglishName(englishCountryName: string): string {
		switch (englishCountryName) {
			case 'Albania':
				return 'AB';
			case 'Andorra':
				return 'AD';
			case 'Angola':
				return 'AO';
			case 'Argentina':
				return 'AR';
			case 'Armenia':
				return 'AM';
			case 'Austria':
				return 'AT';
			case 'Australia':
				return 'AU';
			case 'Azerbaijan':
				return 'AZ';
			case 'Bahrain':
				return 'BA';
			case 'Barbados':
				return 'BB';
			case 'Belize':
				return 'BZ';
			case 'Belgium':
				return 'BE';
			case 'Bolivia':
				return 'BO';
			case 'Bulgaria':
				return 'BG';
			case 'Bosnia and Herzegovina':
				return 'BH';
			case 'Brazil':
				return 'BR';
			case 'Belarus':
				return 'BY';
			case 'Cambodia':
				return 'KH';
			case 'Cameroon':
				return 'CM';
			case 'Canada':
				return 'CA';
			case 'Cape Verde':
				return 'CV';
			case 'Colombia':
				return 'CO';
			case 'Costa Rica':
				return 'CR';
			case 'Cuba':
				return 'CU';
			case 'Switzerland':
				return 'CH';
			case 'Chile':
				return 'CL';
			case 'China':
				return 'CN';
			case 'Cyprus':
				return 'CY';
			case 'Czech Republic':
				return 'CZ';
			case 'Germany':
				return 'DE';
			case 'Denmark':
				return 'DK';
			case 'Algeria':
				return 'DZ';
			case 'Ecuador':
				return 'EC';
			case 'Egypt':
				return 'EG';
			case 'England':
				return 'EN';
			case 'Estonia':
				return 'EE';
			case 'Ethiopia':
				return 'ET';
			case 'Faroe Islands':
				return 'FO';
			case 'France':
				return 'FR';
			case 'Ghana':
				return 'GH';
			case 'Guinea':
				return 'GN';
			case 'Spain':
				return 'ES';
			case 'Finland':
				return 'FI';
			case 'DR Congo':
				return 'CD';
			case 'Georgia':
				return 'GE';
			case 'Gibraltar':
				return 'GI';
			case 'Greece':
				return 'HE';
			case 'Greenland':
				return 'GL';
			case 'Kosovo':
				return 'XK';
			case 'Croatia':
				return 'HR';
			case 'Honduras':
				return 'HN';
			case 'Hong Kong':
				return 'HK';
			case 'Hungary':
				return 'HU';
			case 'Iceland':
				return 'IS';
			case 'India':
				return 'IN';
			case 'Indonesia':
				return 'ID';
			case 'Iran':
				return 'IR';
			case 'Iraq':
				return 'IQ';
			case 'Ireland':
				return 'IE';
			case 'Israel':
				return 'IL';
			case 'Italy':
				return 'IT';
			case 'Japan':
				return 'JP';
			case 'Kazakhstan':
				return 'KZ';
			case 'Kenya':
				return 'KE';
			case 'Latvia':
				return 'LV';
			case 'Lebanon':
				return 'LB';
			case 'Liechtenstein':
				return 'LI';
			case 'Lithuania':
				return 'LT';
			case 'Luxembourg':
				return 'LU';
			case 'Malaysia':
				return 'MY';
			case 'Maldives':
				return 'MV';
			case 'Mongolia':
				return 'MN';
			case 'Moldova':
				return 'MO';
			case 'Morocco':
				return 'MA';
			case 'New Zealand':
				return 'NZ';
			case 'Nicaragua':
				return 'NC';
			case 'North Macedonia':
				return 'MD';
			case 'Montenegro':
				return 'ME';
			case 'Malta':
				return 'MT';
			case 'Mexico':
				return 'MX';
			case 'Northern Ireland':
				return 'NI';
			case 'Netherlands':
				return 'NL';
			case 'Norway':
				return 'NO';
			case 'Oman':
				return 'OM';
			case 'Paraguay':
				return 'PY';
			case 'Peru':
				return 'PE';
			case 'Poland':
				return 'PL';
			case 'Portugal':
				return 'PT';
			case 'Qatar':
				return 'QT';
			case 'El Salvador':
				return 'SV';
			case 'Romania':
				return 'RO';
			case 'Serbia':
				return 'RS';
			case 'Syria':
				return 'SY';
			case 'Republic of the Congo':
				return 'CG';
			case 'Russia':
				return 'RU';
			case 'San Marino':
				return 'SM';
			case 'Saudi Arabia':
				return 'KS';
			case 'Scotland':
				return 'SC';
			case 'South Africa':
				return 'ZA';
			case 'South Korea':
				return 'KR';
			case 'Sweden':
				return 'SE';
			case 'Slovenia':
				return 'SI';
			case 'Slovakia':
				return 'SK';
			case 'Suriname':
				return 'SR';
			case 'Tunisia':
				return 'TN';
			case 'Turkey':
				return 'TK';
			case 'UAE':
				return 'AE';
			case 'Ukraine':
				return 'UA';
			case 'Uzbekistan':
				return 'UB';
			case 'United States':
				return 'US';
			case 'Uruguay':
				return 'UY';
			case 'Venezuela':
				return 'VE';
			case 'Vietnam':
				return 'VN';
			case 'Wales':
				return 'WL';
			case 'Ivory Coast':
				return 'CI';
			case 'Guatemala':
				return 'GT';
			case 'Trinidad and Tobago':
				return 'TT';
			case 'Nigeria':
				return 'NG';
			case 'Thailand':
				return 'TH';
			case 'Sudan':
				return 'SD';
			case 'Mali':
				return 'MI';
			case 'Uganda':
				return 'UG';
			case 'Haiti':
				return 'HT';
			case 'Zambia':
				return 'ZM';
			case 'Tanzania':
				return 'TZ';
			case 'Turkmenistan':
				return 'TM';
			case 'Tajikistan':
				return 'TJ';
			case 'Kuwait':
				return 'KW';
			case 'Yugoslavia':
				return 'YU';
			case 'Czechoslovakia':
				return 'ZK';
			case 'Soviet Union':
				return 'UR';
			default:
				return englishCountryName;

		}
	}

	public static getLocalizedCountryName(countryCode: string): string {
		switch (countryCode) {
			case 'AB':
				return 'Albania';
			case 'AD':
				return 'Andorra';
			case 'AE':
				return 'UAE';
			case 'AM':
				return 'Armenia';
			case 'AO':
				return 'Angola';
			case 'AR':
				return 'Argentina';
			case 'AT':
				return 'Austria';
			case 'AU':
				return 'Australia';
			case 'AZ':
				return 'Azerbaijan';
			case 'BA':
				return 'Bahrain';
			case 'BB':
				return 'Barbados';
			case 'BE':
				return 'Belgium';
			case 'BZ':
				return 'Belize';
			case 'BG':
				return 'Bulgaria';
			case 'BH':
				return 'Bosnia and Herzegovina';
			case 'BR':
				return 'Brazil';
			case 'BO':
				return 'Bolivia';
			case 'BY':
				return 'Belarus';
			case 'CA':
				return 'Canada';
			case 'CG':
				return 'Republic of the Congo';
			case 'CH':
				return 'Switzerland';
			case 'CL':
				return 'Chile';
			case 'CD':
				return 'DR Congo';
			case 'CM':
				return 'Cameroon';
			case 'CN':
				return 'China';
			case 'CO':
				return 'Colombia';
			case 'CR':
				return 'Costa Rica';
			case 'CV':
				return 'Cape Verde';
			case 'CY':
				return 'Cyprus';
			case 'CZ':
				return 'Czech Republic';
			case 'CU':
				return 'Cuba';
			case 'DE':
				return 'Germany';
			case 'DK':
				return 'Denmark';
			case 'DZ':
				return 'Algeria';
			case 'EC':
				return 'Ecuador';
			case 'EG':
				return 'Egypt';
			case 'EN':
				return 'England';
			case 'EE':
				return 'Estonia';
			case 'ES':
				return 'Spain';
			case 'ET':
				return 'Ethiopia';
			case 'FI':
				return 'Finland';
			case 'FR':
				return 'France';
			case 'FO':
				return 'Faroe Islands';
			case 'GE':
				return 'Georgia';
			case 'GH':
				return 'Ghana';
			case 'GN':
				return 'Guinea';
			case 'GI':
				return 'Gibraltar';
			case 'GL':
				return 'Greenland';
			case 'HE':
				return 'Greece';
			case 'HK':
				return 'Hong Kong';
			case 'HR':
				return 'Croatia';
			case 'HN':
				return 'Honduras';
			case 'HU':
				return 'Hungary';
			case 'ID':
				return 'Indonesia';
			case 'IE':
				return 'Ireland';
			case 'IL':
				return 'Israel';
			case 'IN':
				return 'India';
			case 'IR':
				return 'Iran';
			case 'IQ':
				return 'Iraq';
			case 'IS':
				return 'Iceland';
			case 'IT':
				return 'Italy';
			case 'JP':
				return 'Japan';
			case 'KE':
				return 'Kenya';
			case 'KH':
				return 'Cambodia';
			case 'KR':
				return 'South Korea';
			case 'KZ':
				return 'Kazakhstan';
			case 'LB':
				return 'Lebanon';
			case 'LI':
				return 'Liechtenstein';
			case 'LT':
				return 'Lithuania';
			case 'LU':
				return 'Luxembourg';
			case 'LV':
				return 'Latvia';
			case 'MA':
				return 'Morocco';
			case 'MD':
				return 'North Macedonia';
			case 'ME':
				return 'Montenegro';
			case 'MN':
				return 'Mongolia';
			case 'MO':
				return 'Moldova';
			case 'MT':
				return 'Malta';
			case 'MX':
				return 'Mexico';
			case 'MV':
				return 'Maldives';
			case 'MY':
				return 'Malaysia';
			case 'NI':
				return 'Northern Ireland';
			case 'NC':
				return 'Nicaragua';
			case 'NL':
				return 'Netherlands';
			case 'NZ':
				return 'New Zealand';
			case 'NO':
				return 'Norway';
			case 'OM':
				return 'Oman';
			case 'PE':
				return 'Peru';
			case 'PY':
				return 'Paraguay';
			case 'PL':
				return 'Poland';
			case 'PT':
				return 'Portugal';
			case 'RO':
				return 'Romania';
			case 'RS':
				return 'Serbia';
			case 'RU':
				return 'Russia';
			case 'QT':
				return 'Qatar';
			case 'KS':
				return 'Saudi Arabia';
			case 'SC':
				return 'Scotland';
			case 'SE':
				return 'Sweden';
			case 'SI':
				return 'Slovenia';
			case 'SK':
				return 'Slovakia';
			case 'SM':
				return 'San Marino';
			case 'SR':
				return 'Suriname';
			case 'SV':
				return 'El Salvador';
			case 'SY':
				return 'Syria';
			case 'TN':
				return 'Tunisia';
			case 'TK':
				return 'Turkey';
			case 'UA':
				return 'Ukraine';
			case 'UB':
				return 'Uzbekistan';
			case 'US':
				return 'United States';
			case 'UY':
				return 'Uruguay';
			case 'VE':
				return 'Venezuela';
			case 'VN':
				return 'Vietnam';
			case 'XK':
				return 'Kosovo';
			case 'WL':
				return 'Wales';
			case 'ZA':
				return 'South Africa';
			case 'CI':
				return 'Ivory Coast';
			case 'GT':
				return 'Guatemala';
			case 'TT':
				return 'Trinidad and Tobago';
			case 'NG':
				return 'Nigeria';
			case 'TH':
				return 'Thailand';
			case 'SD':
				return 'Sudan';
			case 'MI':
				return 'Mali';
			case 'UG':
				return 'Uganda';
			case 'HT':
				return 'Haiti';
			case 'ZM':
				return 'Zambia';
			case 'TZ':
				return 'Tanzania';
			case 'TM':
				return 'Turkmenistan';
			case 'TJ':
				return 'Tajikistan';
			case 'KW':
				return 'Kuwait';
			case 'YU':
				return 'Yugoslavia';
			case 'ZK':
				return 'Czechoslovakia';
			case 'UR':
				return 'Soviet Union';
			default:
				return countryCode;
		}
	}

	private static getCategoryOfArmenia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Armenian Premier League (I)';
			case 2:
				return 'Armenian First League (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfItaly(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Serie A (I)';
			case 2:
				return 'Serie B (II)';
			case 3:
				return 'Serie C (III)';
			case 4:
				return 'Serie D (IV)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfAustria(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Bundesliga (I)';
			case 2:
				return '2. Liga (II)';
			case 3:
				return 'Regionalliga (III)';
			case 4:
				return 'Landesliga (IV)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfArgentina(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Primera Divisón (I)';
			case 2:
				return 'Primera B Nacional (II)';
			case 3:
				if (city.region === 'Buenos Aires') {
					return 'Primera B Metropolitana (III)';
				} else {
					return 'Torneo Federal A (III)';

				}
			case 4:
				if (city.region === 'Buenos Aires') {
					return 'Primera C Metropolitana (IV)';
				} else {
					return 'Torneo Federal Amateur (IV)';

				}
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfAlbania(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Kategoria Superiore (I)';
			case 2:
				return 'Kategoria e Parë (II)';
			case 3:
				return 'Kategoria e Dytë (III)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfBelgium(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Belgian First Division A (I)';
			case 2:
				return 'Belgian First Division B (II)';
			case 3:
				return 'Belgian National Division 1 (III)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfBolivia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Primera División (I)';
			case 2:
				return 'Segunda División (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfSpain(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'La Liga (I)';
			case 2:
				return 'Segunda División (II)';
			case 3:
				return 'Primera División RFEF (III)';
			case 4:
				return 'Segunda División RFEF (IV)';
			case 5:
				return 'Tercera División RFEF (V)';
			default:
				return Localizer.getSpanishRegionalCategory(category, city, countryCode2);
		}
	}

	private static getSpanishRegionalCategory(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (city.region) {
			case 'Andalusia':
				return Localizer.getAndalusianRegionalCategory(category, city, countryCode2);
			case 'Aragon':
				return Localizer.getAragonRegionalCategory(category, city, countryCode2);
			case 'Asturias':
				return Localizer.getAsturiasRegionalCategory(category, city, countryCode2);
			case 'Balearic Islands':
				return Localizer.getBalearicRegionalCategory(category, city, countryCode2);
			case 'Basque Country':
				return Localizer.getBasqueRegionalCategory(category, city, countryCode2);
			case 'Canary Islands':
				return Localizer.getCanarianRegionalCategory(category, city, countryCode2);
			case 'Cantabria':
				return Localizer.getCantabrianRegionalCategory(category, city, countryCode2);
			case 'Castile and León':
				return Localizer.getCastilianLeonRegionalCategory(category, city, countryCode2);
			case 'Castile-La Mancha':
				return Localizer.getCastilianManchaRegionalCategory(category, city, countryCode2);
			case 'Catalonia':
				return Localizer.getCatalanRegionalCategory(category, city, countryCode2);
			case 'Community of Madrid':
				return Localizer.getMadridRegionalCategory(category, city, countryCode2);
			case 'Extremadura':
				return Localizer.getExtremaduranRegionalCategory(category, city, countryCode2);
			case 'Galicia':
				return Localizer.getGalicianRegionalCategory(category, city, countryCode2);
			case 'La Rioja':
				return Localizer.getRiojaRegionalCategory(category, city, countryCode2);
			case 'Region of Murcia':
				return Localizer.getMurcianRegionalCategory(category, city, countryCode2);
			case 'Navarre':
				return Localizer.getNavarreRegionalCategory(category, city, countryCode2);
			case 'Valencian Community':
				return Localizer.getValencianRegionalCategory(category, city, countryCode2);
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getValencianRegionalCategory(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 6:
				return 'Regional Preferente Valencia (VI)';
			case 7:
				return 'Primera Regional Valencia (VII)';
			case 8:
				return 'Segunda Regional Valencia (VIII)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getNavarreRegionalCategory(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 6:
				return 'Primera Autonómica Navarra (VI)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getMurcianRegionalCategory(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 6:
				return 'Preferente Autonómica Murcia (VI)';
			case 7:
				return 'Primera Autonómica Murcia (VII)';
			case 8:
				return 'Segunda Autonómica Murcia (VIII)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getRiojaRegionalCategory(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 6:
				return 'Regional Preferente La Rioja (VI)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getGalicianRegionalCategory(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 6:
				return 'Preferente Autonómica Galicia (VI)';
			case 7:
				return 'Primera Autonómica Galicia (VII)';
			case 8:
				return 'Segunda Autonómica Galicia (VIII)';
			case 9:
				return 'Tercera Autonómica Galicia (IX)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getExtremaduranRegionalCategory(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 6:
				return 'Primera División Extremadura (VI)';
			case 7:
				return 'Segunda División Extremadura (VII)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getMadridRegionalCategory(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 6:
				return 'Preferente Madrid (VI)';
			case 7:
				return 'Primera Aficionados Madrid (VII)';
			case 8:
				return 'Segunda Aficionados Madrid (VIII)';
			case 9:
				return 'Tercera Aficionados Madrid (IX)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCatalanRegionalCategory(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 6:
				return 'Primera Catalana (VI)';
			case 7:
				return 'Segona Catalana (VII)';
			case 8:
				return 'Tercera Catalana (VIII)';
			case 9:
				return 'Cuarta Catalana (IX)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCastilianManchaRegionalCategory(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 6:
				return 'Primera Preferente C. La Mancha (VI)';
			case 7:
				return 'Primera Autonómica C. La Mancha (VII)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCastilianLeonRegionalCategory(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 6:
				return 'Primera Regional C. León (VI)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCantabrianRegionalCategory(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 6:
				return 'Regional Preferente Cantabra (VI)';
			case 7:
				return 'Primera Regional Cantabra (VII)';
			case 8:
				return 'Segunda Regional Cantabra (VIII)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCanarianRegionalCategory(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 6:
				return 'Interinsular Preferente (VI)';
			case 7:
				return 'Primera Regional (VII)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getBasqueRegionalCategory(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 6:
				return 'División de Honor Vasca (VI)';
			case 7:
				return 'Regional Preferente Vasca (VII)';
			case 8:
				return 'Primera Regional Vasca (VIII)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getBalearicRegionalCategory(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 6:
				return 'Regional Preferente (VI)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getAsturiasRegionalCategory(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 6:
				return 'Regional Preferente Asturiana (VI)';
			case 7:
				return 'Primera Regional Asturiana (VII)';
			case 8:
				return 'Segunda Regional Asturiana (VIII)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getAragonRegionalCategory(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 6:
				return 'Regional Preferente Aragonesa (VI)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getAndalusianRegionalCategory(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 6:
				return 'División de Honor Andaluza (VI)';
			case 7:
				return 'Primera Andaluza (VII)';
			case 8:
				return 'Segunda Andaluza (VIII)';
			case 9:
				return 'Tercera Andaluza (IX)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfEngland(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Premier League (I)';
			case 2:
				return 'EFL Championship (II)';
			case 3:
				return 'EFL League One (III)';
			case 4:
				return 'EFL League Two (IV)';
			case 5:
				return 'National League (V)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfGermany(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Bundesliga (I)';
			case 2:
				return '2. Bundesliga (II)';
			case 3:
				return '3. Liga (III)';
			case 4:
				return 'Regionalliga (IV)';
			case 5:
				return 'Oberliga (V)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfBosnia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Premier League (I)';
			case 2: {
				if (city.region === 'Republika Srpska') {
					return 'First League of RS (II)';
				} else {
					return 'First League of BiH (II)';
				}
			}
			case 3: {
				if (city.region === 'Republika Srpska') {
					return 'Second League of RS (II)';
				} else {
					return 'Second League of BiH (II)';
				}
			}
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfSwitzerlandAndLiech(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Swiss Super League (I)';
			case 2:
				return 'Swiss Challenge League (II)';
			case 3:
				return 'Swiss Promotion League (III)';
			case 4:
				return 'Swiss 1. Liga (IV)';
			case 5:
				return 'Swiss 2. Liga Interregional (V)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfCroatia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Croatian Football League (I)';
			case 2:
				return 'Prva NL (II)';
			case 3:
				return 'Druga NL (III)';
			case 4:
				return 'Treća NL (IV)';
			case 5:
				return '1. ŽNL (V)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfSlovenia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return '1.SNL (I)';
			case 2:
				return '2.SNL (II)';
			case 3:
				return '3.SNL (III)';
			case 4:
				return '1.MNZ (IV)';
			case 5:
				return '2.MNZ (V)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfBrazil(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Série A (I)';
			case 2:
				return 'Série B (II)';
			case 3:
				return 'Série C (III)';
			case 4:
				return 'Série D (IV)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfBulgaria(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'First Professional League (I)';
			case 2:
				return 'Second Professional League (II)';
			case 3:
				return 'Third Amateur League (III)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfAngola(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Girabola (I)';
			case 2:
				return 'Segundona (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfBelarus(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Belarusian Premier League (I)';
			case 2:
				return 'Belarusian First League (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfNorthAmerica(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Major League Soccer (I)';
			case 2:
				return 'USL Championship (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfChile(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Primera División (I)';
			case 2:
				return 'Primera B (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfChina(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Chinese Super League (I)';
			case 2:
				return 'Chinese League One (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfCyprus(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Cypriot First Division (I)';
			case 2:
				return 'Cypriot Second Division (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfCzechRep(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Czech First League (I)';
			case 2:
				return 'Czech National League (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfDenmark(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Danish Superliga (I)';
			case 2:
				return 'Danish 1st Division (II)';
			case 3:
				return 'Danish 2nd Division (III)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfAlgeria(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Ligue Professionnelle 1 (I)';
			case 2:
				return 'Ligue Professionnelle 2 (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfEcuador(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Ecuadorian Serie A (I)';
			case 2:
				return 'Ecuadorian Serie B (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfEgypt(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Egyptian Premier League (I)';
			case 2:
				return 'Egyptian Second Division (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfEstonia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Meistriliiga (I)';
			case 2:
				return 'Esiliiga (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfFinland(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Veikkausliiga (I)';
			case 2:
				return 'Ykkönen (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfFrance(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Ligue 1 (I)';
			case 2:
				return 'Ligue 2 (II)';
			case 3:
				return 'Championnat National (III)';
			case 4:
				return 'Championnat National 2 (IV)';
			case 5:
				return 'Championnat National 3 (V)';
			case 6:
				return 'Regional 1 (VI)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfGreece(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Super League Greece (I)';
			case 2:
				return 'Super League Greece 2 (II)';
			case 3:
				return 'Football League Greece (III)';
			case 4:
				return 'Gamma Ethniki (IV)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfHonduras(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Honduran Liga Nacional (I)';
			case 2:
				return 'Honduran Liga de Ascenso (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfHungary(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Nemzeti Bajnokság I (I)';
			case 2:
				return 'Nemzeti Bajnokság II (II)';
			case 3:
				return 'Nemzeti Bajnokság III (III)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfIndonesia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Liga 1 (I)';
			case 2:
				return 'Liga 2 (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfIreland(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'L. Ireland Premier Division (I)';
			case 2:
				return 'L. Ireland First Division (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfIsrael(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Israeli Premier League (I)';
			case 2:
				return 'Liga Leumit (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfIran(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Persian Gulf Pro League	(I)';
			case 2:
				return 'Azadegan League (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfMorocco(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Botola (I)';
			case 2:
				return 'Botola 2 (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfMacedonia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Macedonian First League (I)';
			case 2:
				return 'Macedonian Second League (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfMontenegro(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Montenegrin First League (I)';
			case 2:
				return 'Montenegrin Second League (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfMalta(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Maltese Premier League (I)';
			case 2:
				return 'Maltese First Division (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfMexico(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Liga MX (I)';
			case 2:
				return 'Liga de Expansión MX (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfNorthIreland(category: number, city: IDBCity, countryCode2?: string): string | null {
		if (countryCode2) {
			return Localizer.getCategoryOfIreland(category, city, countryCode2);
		}
		switch (category) {
			case 1:
				return 'NIFL Premiership (I)';
			case 2:
				return 'NIFL Championship (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfNetherlands(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Eredivisie (I)';
			case 2:
				return 'Eerste Divisie (II)';
			case 3:
				return 'Tweede Divisie (III)';
			case 4:
				return 'Derde Divisie (IV)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfNorway(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Eliteserien (I)';
			case 2:
				return '1. divisjon (II)';
			case 3:
				return '2. divisjon (III)';
			case 4:
				return '3. divisjon (IV)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfParaguay(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Primera División (I)';
			case 2:
				return 'Segunda División (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfPoland(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Ekstraklasa (I)';
			case 2:
				return 'I liga (II)';
			case 3:
				return 'II liga (III)';
			case 4:
				return 'III liga (IV)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfPortugal(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Primeira Liga (I)';
			case 2:
				return 'LigaPro (II)';
			case 3:
				return 'Campeonato de Portugal (III)';
			case 4:
				return 'District Championship (IV)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfRomania(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Liga I (I)';
			case 2:
				return 'Liga II (II)';
			case 3:
				return 'Liga III (III)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfSerbia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Serbian SuperLiga (I)';
			case 2:
				return 'Serbian First League (II)';
			case 3:
				return 'Serbian League (III)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfRussia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Russian Premier League (I)';
			case 2:
				return 'Russian National League (II)';
			case 3:
				return 'Russian Professional League (III)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfSaudiArabia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Saudi Pro League (I)';
			case 2:
				return 'MS League (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfScotland(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Scottish Premiership (I)';
			case 2:
				return 'Scottish Championship (II)';
			case 3:
				return 'Scottish League One (III)';
			case 4:
				return 'Scottish League Two (IV)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfSweden(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Allsvenskan (I)';
			case 2:
				return 'Superettan (II)';
			case 3:
				return 'Division 1 (III)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfSlovakia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Slovak Super Liga (I)';
			case 2:
				return 'Slovak 2. Liga (II)';
			case 3:
				return 'Slovak 3. Liga (III)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfTunisia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Ligue Professionnelle 1 (I)';
			case 2:
				return 'Ligue Professionnelle 2 (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfTurkey(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Süper Lig (I)';
			case 2:
				return 'TFF First League (II)';
			case 3:
				return '2. Lig (III)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfUkraine(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Ukrainian Premier League (I)';
			case 2:
				return 'Ukrainian First League (II)';
			case 3:
				return 'Ukrainian Second League (III)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfUzbekistan(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Uzbekistan Super League (I)';
			case 2:
				return 'Uzbekistan Pro League (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfUruguay(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Primera División (I)';
			case 2:
				return 'Segunda División (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfWales(category: number, city: IDBCity, countryCode2?: string): string | null {
		if (countryCode2) {
			return `English ${Localizer.getCategoryOfEngland(category, city, countryCode2)}`;
		}
		switch (category) {
			case 1:
				return 'Cymru Premier (I)';
			case 2:
				return 'Cymru North/South (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfPeru(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Liga 1 (I)';
			case 2:
				return 'Liga 2 (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfColombia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Categoria Primera A (I)';
			case 2:
				return 'Categoria Primera B (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfUAE(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'UAE Pro League (I)';
			case 2:
				return 'UAE First Division League (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfVenezuela(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Primera División (I)';
			case 2:
				return 'Segunda División (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfAndorra(category: number, city: IDBCity, countryCode2?: string): string | null {
		if (countryCode2 && countryCode2 === 'ES') {
			return `Spanish ${Localizer.getCategoryOfSpain(category, city, countryCode2)}`;
		}
		switch (category) {
			case 1:
				return 'Primera Divisió (I)';
			case 2:
				return 'Segona Divisió (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfSanMarino(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Campionato Sammarinese (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfJapan(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'J1 League (I)';
			case 2:
				return 'J2 League (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfSouthKorea(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'K League 1 (I)';
			case 2:
				return 'K League 2 (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfIndia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Indian Super League (I)';
			case 2:
				return 'I-League (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfIceland(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Úrvalsdeild karla  (I)';
			case 2:
				return '1. deild karla (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfFaroe(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Faroe Islands Premier League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfGibraltar(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'A Lyga (I)';
			case 2:
				return 'I Lyga (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfLithuania(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Gibraltar Premier Division (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfKosovo(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Football Superleague of Kosovo (I)';
			case 2:
				return 'First Football League of Kosovo (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfSouthAfrica(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'South Africa Premier Division (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfKazakhstan(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Kazakhstan Premier League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfAustralia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'A-League (I)';
			case 2:
				return 'National Premier League (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfLebanon(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Lebanese Premier League (I)';
			case 2:
				return 'Lebanese Second Division (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfGeorgia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Georgian Erovnuli Liga (I)';
			case 2:
				return 'Georgian Erovnuli Liga 2 (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfLuxembourg(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Luxembourg Nationaldivisioun (I)';
			case 2:
				return 'Luxembourg Division of Honour (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfLatvia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Latvian Virslīga (I)';
			case 2:
				return 'Latvian First League (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfQatar(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Qatar Stars League (I)';
			case 2:
				return 'Qatar Second Division (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfAzerbaijan(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Azerbaijan Premier League (I)';
			case 2:
				return 'Azerbaijan First Division (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfVietnam(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Vietnamese V.League 1 (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfCambodia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Cambodian C-League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfBahrain(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Bahraini Premier League	(I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfBelize(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Premier League of Belize (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfEthiopia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Ethiopian Premier League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfGreenland(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Greenlandic Coca-Cola GM (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfIraq(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Iraqi Premier League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfMoldova(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Moldovan Divizia Națională (I)';
			case 2:
				return 'Moldovan Divizia "A" (II)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfMongolia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Mongolian National Premier League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfMaldives(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Dhivehi Premier League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfMalaysia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Malaysia Premier League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfOman(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Oman Professional League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfCameroon(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Cameroon Elite 1 (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfCostaRica(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Liga FPD (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfCongoRepublic(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Congolese Division 1 (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfDRCongo(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Vodacom Ligue 1 (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfBarbados(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Barbados Premier League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfHongKong(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Hong Kong Premier League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfGhana(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Ghana Premier League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfGuinea(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Guinée Ligue 1 Pro (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfNewZealand(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'New Zealand Football Championship (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfSuriname(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Suriname Eerste Divisie (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfNicaragua(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Liga Primera Nicaragua (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfElSalvador(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Primera División de El Salvador (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfCapeVerde(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Cape Verdean Football Championship (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}


	private static getCategoryOfIvory(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Ivorian Ligue 1 (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfGuatemala(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Guatemala Liga Nacional (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfThailand(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Thai Premier League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfNigeria(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Nigerian Profesional Football League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfSudan(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Sudan Premier League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	private static getCategoryOfHaiti(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Ligue Haïtienne (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfMali(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Malian Première Division (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfUganda(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Uganda Premier League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfTrinidad(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Trinidad TT Pro League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfSyria(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Syrian Premier League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfKenya(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Kenya Premier League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfTanzania(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Tanzania Premier League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfZambia(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Zambian Super League (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfCuba(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Campeonato Nacional Cubano (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfTurkmenistan(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Turkmenistan Ýokary Liga (I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfTaijiskistan(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Taijiskistan Premier League(I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}
	private static getCategoryOfKuwait(category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (category) {
			case 1:
				return 'Kuwait Premier League(I)';
			default:
				return category ? `${category.toString()}th` : null;
		}
	}

	public static getCategoryString(countryCode: string, category: number, city: IDBCity, countryCode2?: string): string | null {
		switch (countryCode) {
			case 'AD':
				return Localizer.getCategoryOfAndorra(category, city, countryCode2);
			case 'AM':
				return Localizer.getCategoryOfArmenia(category, city, countryCode2);
			case 'AU':
				return Localizer.getCategoryOfAustralia(category, city, countryCode2);
			case 'AE':
				return Localizer.getCategoryOfUAE(category, city, countryCode2);
			case 'AZ':
				return Localizer.getCategoryOfAzerbaijan(category, city, countryCode2);
			case 'BA':
				return Localizer.getCategoryOfBahrain(category, city, countryCode2);
			case 'BB':
				return Localizer.getCategoryOfBarbados(category, city, countryCode2);
			case 'BZ':
				return Localizer.getCategoryOfBelize(category, city, countryCode2);
			case 'IT':
				return Localizer.getCategoryOfItaly(category, city, countryCode2);
			case 'AT':
				return Localizer.getCategoryOfAustria(category, city, countryCode2);
			case 'AR':
				return Localizer.getCategoryOfArgentina(category, city, countryCode2);
			case 'AB':
				return Localizer.getCategoryOfAlbania(category, city, countryCode2);
			case 'BE':
				return Localizer.getCategoryOfBelgium(category, city, countryCode2);
			case 'BO':
				return Localizer.getCategoryOfBolivia(category, city, countryCode2);
			case 'CD':
				return Localizer.getCategoryOfDRCongo(category, city, countryCode2);
			case 'CO':
				return Localizer.getCategoryOfColombia(category, city, countryCode2);
			case 'CG':
				return Localizer.getCategoryOfCongoRepublic(category, city, countryCode2);
			case 'CR':
				return Localizer.getCategoryOfCostaRica(category, city, countryCode2);
			case 'ES':
				return Localizer.getCategoryOfSpain(category, city, countryCode2);
			case 'EN':
				return Localizer.getCategoryOfEngland(category, city, countryCode2);
			case 'DE':
				return Localizer.getCategoryOfGermany(category, city, countryCode2);
			case 'ET':
				return Localizer.getCategoryOfEthiopia(category, city, countryCode2);
			case 'CH':
			case 'LI':
				return Localizer.getCategoryOfSwitzerlandAndLiech(category, city, countryCode2);
			case 'BH':
				return Localizer.getCategoryOfBosnia(category, city, countryCode2);
			case 'HR':
				return Localizer.getCategoryOfCroatia(category, city, countryCode2);
			case 'SI':
				return Localizer.getCategoryOfSlovenia(category, city, countryCode2);
			case 'BR':
				return Localizer.getCategoryOfBrazil(category, city, countryCode2);
			case 'BG':
				return Localizer.getCategoryOfBulgaria(category, city, countryCode2);
			case 'AO':
				return Localizer.getCategoryOfAngola(category, city, countryCode2);
			case 'BY':
				return Localizer.getCategoryOfBelarus(category, city, countryCode2);
			case 'CA':
			case 'US':
				return Localizer.getCategoryOfNorthAmerica(category, city, countryCode2);
			case 'CL':
				return Localizer.getCategoryOfChile(category, city, countryCode2);
			case 'CM':
				return Localizer.getCategoryOfCameroon(category, city, countryCode2);
			case 'CN':
				return Localizer.getCategoryOfChina(category, city, countryCode2);
			case 'CV':
				return Localizer.getCategoryOfCapeVerde(category, city, countryCode2);
			case 'CY':
				return Localizer.getCategoryOfCyprus(category, city, countryCode2);
			case 'CZ':
				return Localizer.getCategoryOfCzechRep(category, city, countryCode2);
			case 'DK':
				return Localizer.getCategoryOfDenmark(category, city, countryCode2);
			case 'DZ':
				return Localizer.getCategoryOfAlgeria(category, city, countryCode2);
			case 'EC':
				return Localizer.getCategoryOfEcuador(category, city, countryCode2);
			case 'EG':
				return Localizer.getCategoryOfEgypt(category, city, countryCode2);
			case 'EE':
				return Localizer.getCategoryOfEstonia(category, city, countryCode2);
			case 'FI':
				return Localizer.getCategoryOfFinland(category, city, countryCode2);
			case 'FR':
				return Localizer.getCategoryOfFrance(category, city, countryCode2);
			case 'GE':
				return Localizer.getCategoryOfGeorgia(category, city, countryCode2);
			case 'GL':
				return Localizer.getCategoryOfGreenland(category, city, countryCode2);
			case 'GH':
				return Localizer.getCategoryOfGhana(category, city, countryCode2);
			case 'GN':
				return Localizer.getCategoryOfGuinea(category, city, countryCode2);
			case 'HE':
				return Localizer.getCategoryOfGreece(category, city, countryCode2);
			case 'HK':
				return Localizer.getCategoryOfHongKong(category, city, countryCode2);
			case 'HN':
				return Localizer.getCategoryOfHonduras(category, city, countryCode2);
			case 'HU':
				return Localizer.getCategoryOfHungary(category, city, countryCode2);
			case 'ID':
				return Localizer.getCategoryOfIndonesia(category, city, countryCode2);
			case 'IE':
				return Localizer.getCategoryOfIreland(category, city, countryCode2);
			case 'IL':
				return Localizer.getCategoryOfIsrael(category, city, countryCode2);
			case 'IR':
				return Localizer.getCategoryOfIran(category, city, countryCode2);
			case 'IQ':
				return Localizer.getCategoryOfIraq(category, city, countryCode2);
			case 'KZ':
				return Localizer.getCategoryOfKazakhstan(category, city, countryCode2);
			case 'LB':
				return Localizer.getCategoryOfLebanon(category, city, countryCode2);
			case 'LT':
				return Localizer.getCategoryOfLithuania(category, city, countryCode2);
			case 'LU':
				return Localizer.getCategoryOfLuxembourg(category, city, countryCode2);
			case 'LV':
				return Localizer.getCategoryOfLatvia(category, city, countryCode2);
			case 'MA':
				return Localizer.getCategoryOfMorocco(category, city, countryCode2);
			case 'MD':
				return Localizer.getCategoryOfMacedonia(category, city, countryCode2);
			case 'ME':
				return Localizer.getCategoryOfMontenegro(category, city, countryCode2);
			case 'MN':
				return Localizer.getCategoryOfMongolia(category, city, countryCode2);
			case 'MO':
				return Localizer.getCategoryOfMoldova(category, city, countryCode2);
			case 'MT':
				return Localizer.getCategoryOfMalta(category, city, countryCode2);
			case 'MX':
				return Localizer.getCategoryOfMexico(category, city, countryCode2);
			case 'MV':
				return Localizer.getCategoryOfMaldives(category, city, countryCode2);
			case 'MY':
				return Localizer.getCategoryOfMalaysia(category, city, countryCode2);
			case 'NI':
				return Localizer.getCategoryOfNorthIreland(category, city, countryCode2);
			case 'NC':
				return Localizer.getCategoryOfNicaragua(category, city, countryCode2);
			case 'NL':
				return Localizer.getCategoryOfNetherlands(category, city, countryCode2);
			case 'NO':
				return Localizer.getCategoryOfNorway(category, city, countryCode2);
			case 'NZ':
				return Localizer.getCategoryOfNewZealand(category, city, countryCode2);
			case 'OM':
				return Localizer.getCategoryOfOman(category, city, countryCode2);
			case 'PE':
				return Localizer.getCategoryOfPeru(category, city, countryCode2);
			case 'PY':
				return Localizer.getCategoryOfParaguay(category, city, countryCode2);
			case 'PL':
				return Localizer.getCategoryOfPoland(category, city, countryCode2);
			case 'PT':
				return Localizer.getCategoryOfPortugal(category, city, countryCode2);
			case 'QT':
				return Localizer.getCategoryOfQatar(category, city, countryCode2);
			case 'RO':
				return Localizer.getCategoryOfRomania(category, city, countryCode2);
			case 'RS':
				return Localizer.getCategoryOfSerbia(category, city, countryCode2);
			case 'RU':
				return Localizer.getCategoryOfRussia(category, city, countryCode2);
			case 'KS':
				return Localizer.getCategoryOfSaudiArabia(category, city, countryCode2);
			case 'SC':
				return Localizer.getCategoryOfScotland(category, city, countryCode2);
			case 'SE':
				return Localizer.getCategoryOfSweden(category, city, countryCode2);
			case 'SK':
				return Localizer.getCategoryOfSlovakia(category, city, countryCode2);
			case 'SR':
				return Localizer.getCategoryOfSuriname(category, city, countryCode2);
			case 'SV':
				return Localizer.getCategoryOfElSalvador(category, city, countryCode2);
			case 'TN':
				return Localizer.getCategoryOfTunisia(category, city, countryCode2);
			case 'TK':
				return Localizer.getCategoryOfTurkey(category, city, countryCode2);
			case 'UA':
				return Localizer.getCategoryOfUkraine(category, city, countryCode2);
			case 'UB':
				return Localizer.getCategoryOfUzbekistan(category, city, countryCode2);
			case 'UY':
				return Localizer.getCategoryOfUruguay(category, city, countryCode2);
			case 'VE':
				return Localizer.getCategoryOfVenezuela(category, city, countryCode2);
			case 'WL':
				return Localizer.getCategoryOfWales(category, city, countryCode2);
			case 'FO':
				return Localizer.getCategoryOfFaroe(category, city, countryCode2);
			case 'GI':
				return Localizer.getCategoryOfGibraltar(category, city, countryCode2);
			case 'IN':
				return Localizer.getCategoryOfIndia(category, city, countryCode2);
			case 'IS':
				return Localizer.getCategoryOfIceland(category, city, countryCode2);
			case 'JP':
				return Localizer.getCategoryOfJapan(category, city, countryCode2);
			case 'KH':
				return Localizer.getCategoryOfCambodia(category, city, countryCode2);
			case 'VN':
				return Localizer.getCategoryOfVietnam(category, city, countryCode2);
			case 'KR':
				return Localizer.getCategoryOfSouthKorea(category, city, countryCode2);
			case 'SM':
				return Localizer.getCategoryOfSanMarino(category, city, countryCode2);
			case 'XK':
				return Localizer.getCategoryOfKosovo(category, city, countryCode2);
			case 'ZA':
				return Localizer.getCategoryOfSouthAfrica(category, city, countryCode2);
			case 'CI':
				return Localizer.getCategoryOfIvory(category, city, countryCode2);
			case 'GT':
				return Localizer.getCategoryOfGuatemala(category, city, countryCode2);
			case 'TT':
				return Localizer.getCategoryOfTrinidad(category, city, countryCode2);
			case 'NG':
				return Localizer.getCategoryOfNigeria(category, city, countryCode2);
			case 'TH':
				return Localizer.getCategoryOfThailand(category, city, countryCode2);
			case 'SD':
				return Localizer.getCategoryOfSudan(category, city, countryCode2);
			case 'MI':
				return Localizer.getCategoryOfMali(category, city, countryCode2);
			case 'UG':
				return Localizer.getCategoryOfUganda(category, city, countryCode2);
			case 'HT':
				return Localizer.getCategoryOfHaiti(category, city, countryCode2);
			case 'SY':
				return Localizer.getCategoryOfSyria(category, city, countryCode2);
			case 'KE':
				return Localizer.getCategoryOfKenya(category, city, countryCode2);
			case 'CU':
				return Localizer.getCategoryOfCuba(category, city, countryCode2);
			case 'TZ':
				return Localizer.getCategoryOfTanzania(category, city, countryCode2);
			case 'ZM':
				return Localizer.getCategoryOfZambia(category, city, countryCode2);
			case 'TJ':
				return Localizer.getCategoryOfTaijiskistan(category, city, countryCode2);
			case 'TM':
				return Localizer.getCategoryOfTurkmenistan(category, city, countryCode2);
			case 'KW':
				return Localizer.getCategoryOfKuwait(category, city, countryCode2);
			default:
				return category ? category.toString() : null;
		}
	}
}
