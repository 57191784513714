export class CallApiError {
	constructor(statusCode: number, statusMessage: string, data: any) {
		let message: string;
		if (data) {
			if (typeof data === 'string') {
				message = data;
			} else if (data.message && typeof data.message === 'string' && data.message.length > 0) {
				message = data.message;
			} else if (data.error_description && typeof data.error_description === 'string' && data.error_description.length > 0) {
				message = data.error_description;
			} else if (data.error && typeof data.error === 'string' && data.error.length > 0) {
				message = data.error;
			} else {
				message = statusMessage;
			}
		} else {
			message = statusMessage;
		}

		if (message.length <= 0) {
			message = 'Unknown error';
		}

		this.message = message;
		this.name = 'CallApiError';
		this.statusCode = statusCode;
		this.statusMessage = statusMessage;
		this.data = data;
	}

	public readonly message: string;
	public readonly name: string;
	public readonly statusCode: number;
	public readonly statusMessage: string;
	public readonly data: any;
}
