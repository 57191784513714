import React, { PureComponent } from 'react';

import styled from 'styled-components';

// Overlay Dialog Component is the most visible component in coplanner-forms (z-index)
const OverlayComponent = styled.div`
	top: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1111113;
	background-color: rgba(154,154,154,0.6);
`;

export default class Overlay extends PureComponent {
	public render() {
		return (
			<OverlayComponent>
				{this.props.children}
			</OverlayComponent>
		);
	}
}
