import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import ImageRenderer from './ImageRenderer';
import { useSelector } from 'react-redux';
import { IStore } from '../../interfaces';
import { ITeam } from '../../interfaces/clientInterfaces';


//#region styled Components

const GalleryContainer = styled.div`
	flex: 0;
	margin: auto;
`;

const TeamFlexContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
`;

const TeamShieldsContainer = styled.div`
	flex: 1;
	display: flex;
`;

interface IShieldStyleContainer {
	isReducedView: boolean;
	isMobileViewActive: boolean;
}
const ShieldStyleContainer = styled.div<IShieldStyleContainer>`
	display: flex;
	margin-right: 10px;
	filter: drop-shadow(1px 1px 3px silver);
	img {
		max-height: ${props => props.isReducedView ? '50px' : props.isMobileViewActive ? '68px' : '75px'};
	}
`;

const NavigateIcon = styled.i`
	text-shadow: 2px 2px 2px black;
`;

const NavigatorContainer = styled.div`
	font-size: 20px;
	cursor: pointer;
	z-index: 5;
	width: 20px;
	margin: auto;
`;

const ShieldText = styled.div`
	font-size: 14px;
	text-align: center;
	text-shadow: 2px 2px 2px black;
`;

//#endregion
interface IShieldRendererProps {
	teamId: number;
}

interface IShieldRendererInjectedProps {
	isReducedView: boolean;
	isMobileViewActive: boolean;
}

export default function ShieldsRenderer({
	teamId,
}: Readonly<IShieldRendererProps>) {

	const [index, setIndex] =
		useState(0);

	const team = useSelector(
		(
			completeState: IStore,
		): ITeam | null => {
			return completeState.teams.teamsById[teamId] ?? null;
		},
	);

	const {
		isReducedView,
		isMobileViewActive
	} = useSelector(
		(
			completeState: IStore,
		): IShieldRendererInjectedProps => {
			return {
				isReducedView: completeState.ui.isReducedView,
				isMobileViewActive: completeState.ui.isMobileViewActive
			}
		},
	);


	const { shieldUrl, shieldsLength, shieldYear } = useMemo((): {
		shieldUrl: string;
		shieldYear: string;
		shieldsLength: number;
	 } => {
		if (!team) {
			return {
				shieldUrl: '',
				shieldsLength: 0,
				shieldYear: '',
			}
		}
		const shields = team.shields;
		const teamCode = `${team.countryCode}_${team.teamCode}`;

		if (index < 0 && index > shields.length) {
			return {
				shieldUrl: teamCode,
				shieldsLength: 1,
				shieldYear: '',
			}
		}
		const year = index === 0 ? '' : `${shields[index - 1]}`;
		const yearSuffix = index === 0 ? '' : `_${shields[index - 1]}`;

		return {
			shieldUrl: `${teamCode}${yearSuffix}`,
			shieldsLength: 1 + shields.length,
			shieldYear: year,

		};
	}, [index, team]);

	const navigateShieldLeft = useCallback(
		() => {
			setIndex(index - 1);
		},
		[index],
	);

	const navigateShieldRight = useCallback(
		() => {
			setIndex(index + 1);
		},
		[index],
	);

	if (!shieldUrl) {
		return null;
	}

	return (
		<GalleryContainer>
			<TeamFlexContainer>
				{
					index > 0
						? <NavigatorContainer onClick={navigateShieldLeft}>
							<NavigateIcon className='fa fa-caret-left'/>
						</NavigatorContainer>
						: null
				}			
				<TeamShieldsContainer>
					<ShieldStyleContainer
						isReducedView={isReducedView}
						isMobileViewActive={isMobileViewActive}
					>
						<ImageRenderer
							imagePath={`../../../img/shield/${shieldUrl}.png`}
						/>
					</ShieldStyleContainer>
				</TeamShieldsContainer>
				{
					index < shieldsLength - 1
						? <NavigatorContainer onClick={navigateShieldRight}>
							<NavigateIcon className='fa fa-caret-right'/>
						</NavigatorContainer>
						: null
				}	
			</TeamFlexContainer>
			{
				shieldYear ? <ShieldText>{`Until ${shieldYear}`}</ShieldText> : null
			}
		</GalleryContainer>
	);
}


