
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import rootSelector from '../selectors/home';
import { IHomeCombinedProps } from '../../interfaces';
import { EnumSection, IScarfUpdate, ITeam } from '../../interfaces/clientInterfaces';
import * as actions from '../../actions';
import { LoadingIcon, LoadingIconContainer, LoadingIconDesc, StyledAnimatedOnHover } from './SideTree';
import TeamInfoRenderer from './TeamInfoRenderer';
import PopUp from './PopUp';
import { PopUpContainerDiv, PopUpIcon } from './HighlightsPopUp';
import { Link } from 'react-router-dom';
import { StyledHeader } from './Header';
import TrophyTooltip from './TrophyTooltip';
import { Localizer } from '../../utils/localizer';
import DetailTrophyTooltip from './DetailTrophyTooltip';

const HomeContainer = styled.div`
	font-family: Europa-regular;
	flex: 1;
	display: flex;
	font-size: 18px;
	overflow-y: auto;
	user-select: none;
	background: url('../img/background/home.jpg') no-repeat center center fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	text-shadow: -1px -1px 1px lightgrey;
	color: black;
	scroll-behavior: smooth;
`;

const GalleryContainer = styled.div`
	margin: 30px auto 30px auto;
	max-width: 675px;
	padding: 15px;
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const UpdatesContainer = styled.div`
	margin-bottom: 25px;
`;

interface IUpdateContainer {
	isMobileViewActive: boolean;
}
const UpdateContainer = styled.div`
	display: block;
	cursor: pointer;
	margin: 30px auto 0 auto;
`;
interface INameDescription {
	isMobileViewActive: boolean;
}
const NameDescription = styled.div<INameDescription>`
	margin: 0 auto 5px auto;
	display: flex;
	justify-content: center;
	font-size: ${props => props.isMobileViewActive ? '15px' : '18px'};
`;

const FlagOrLogoImage = styled.img`
	height: 20px;
	margin-right: 5px;
`;

interface IUpdateContainer {
	isMobileViewActive: boolean;
}
const UpdateImage = styled.img<IUpdateContainer>`
	display: block;
	width: ${props => props.isMobileViewActive ? '90%' : '600px'};
	margin: auto;
`;

const ScrollButtonContainer = styled(StyledAnimatedOnHover)`
	display: flex;
	margin: 10px 0 20px 0;
	cursor: pointer;
	padding: 5px;
	border-radius: 4px;
	font-family: Europa-big;
	i {
		margin: 0 5px 0 5px;
	}
	:hover {
		background-color: #F89504;
		color: black;
	}
`;

interface IHomeStyledLink {
	isMobileViewActive: boolean;
}
const HomeStyledLink = styled(StyledHeader)<IHomeStyledLink>`
	font-size: ${props => props.isMobileViewActive ? '14px' : '18px'};
`;

interface IntroDescriptionInterface {
	isMobileView: boolean;
}
const IntroDescriptionContainer = styled.div<IntroDescriptionInterface>`
	position: relative;
	text-align: center;
	${props => props.isMobileView ? 'p { margin-bottom: 0; font-size: 16px; }' : '' };
`;

const UpdatesTitle = styled.div`
	font-family: Europa-big;
	top: 15px;
	position: relative;
	text-align: center;
`;

interface ILongDescriptionContainerInterface {
	isMobileView: boolean;
}
const LongDescriptionContainer = styled.div<ILongDescriptionContainerInterface>`
	padding: 50px 0 50px 0;
	text-align: center;
	${props => props.isMobileView ? 'p { font-size: 16px; }' : '' };

`;

const LinksContainer = styled.div`
	display: flex;
`;

const ImageLinkContainer = styled.img`
	max-height: 15px;
	margin-right: 5px;
`;

class Home extends PureComponent<IHomeCombinedProps, {}> {
	private _scrollDivRef: HTMLDivElement | null = null;
	private _scrollDivOnGetRef = (div: HTMLDivElement | null) => this._scrollDivRef = div;

	public componentDidMount() {
		this.props.dispatch(actions.setSectionName(EnumSection.home));
	}

	private _renderMetaTitle = () => {
		return (
			<title>{'Football scarf collection´s home - FootballScarfMuseumCollection'}</title>
		);
	}

	private _renderUpdateScarf = (scarfUpdate: IScarfUpdate, index: number) => {
		const dateString = scarfUpdate.date.toLocaleString('default', { month: 'long' }) + '/' + scarfUpdate.date.getFullYear();
		return (
			<UpdateContainer key={index} onClick={() => this.props.dispatch(actions.setHomeDetailTeam(scarfUpdate.teamId))}>
				<NameDescription isMobileViewActive={this.props.isMobileViewActive}>
					{!scarfUpdate.isMatch ? <FlagOrLogoImage src={`../../../img/flag/${scarfUpdate.countryOrCompetitionCode}.png`} alt={scarfUpdate.name} /> : null}
					<div>{scarfUpdate.name + ' - ' + dateString}</div>
				</NameDescription>
				<UpdateImage
					isMobileViewActive={this.props.isMobileViewActive}
					src={`../../../img/scarf/${scarfUpdate.code}.jpg`}
					alt={`${scarfUpdate.name} scarf`}
					title={`${scarfUpdate.name} scarf`}
				/>
			</UpdateContainer>
		);
	}

	private _renderMobileHomeDescription = () => {
		return this._renderDescriptionLong();
	}

	private _renderDescriptionLong = () => {
		return (
			<LongDescriptionContainer ref={this._scrollDivOnGetRef} isMobileView={this.props.isMobileViewActive}> 
				<p>{'It all started on a trip to Lisbon with my girldfriend, where I found serious troubles deciding between a scarf from one of the three main clubs of the city (Benfica, Belenenses and Sporting) to take back home as a souvenir. As the day to come back home was approaching, the time to take a decision arrived, and I finally opted to take the three scarves with me, which at the same time triggered the collector in me.'}</p>
				<p>{'There was a point where the collection grew and grew, and I came up with the necessity of having a place to exhibit all the pieces of the collection. As the plans for a physical museum seem for the moment far away, I took advantage of the lockdown produced by the Covid-19 pandemic to start developing this site.'}</p>
				<p>{'As you may experience, this is not a regular collection site, but a site where you can find some info about the clubs present in my collection, as well as the relevant highlights of their history, and importance in their country, conference and whole world thanks to the available filters.'}</p>
				<p>{'You can as well search for your team in the map, or take a geographical look at each country or region. Do not forget to visit the Exchange site, where you will find further details about exchanging scarves.'}</p>
			</LongDescriptionContainer>
		);
	}

	private _onScrollToContainer = () => {
		if (this._scrollDivRef) {
			this._scrollDivRef.scrollIntoView();
		}
	}

	private _renderScrollButton = () => {
		return (
			<React.Fragment>
				<ScrollButtonContainer onClick={this._onScrollToContainer}>
					<i className='fa fa-caret-down'/>	
					<div>{'READ MORE'}</div>
					<i className='fa fa-caret-down'/>	
				</ScrollButtonContainer>
			</React.Fragment>
			
		);
	}

	private _renderDescriptionIntro = () => {
		return (
			<IntroDescriptionContainer isMobileView={this.props.isMobileViewActive}>
				<p>{'Welcome to my scarves´ museum site. My name is Álvaro, from Spain but living in Austria, and here you can find all the scarves I have been collecting for a few years now.'}</p>
			</IntroDescriptionContainer>
		);
	}
	private _renderNewestScarves = () => {
		if (this.props.updates.length > 0) {
			return (
				this.props.updates.map((x, i) => this._renderUpdateScarf(x, i))
			);
		}
		return (
			<LoadingIconContainer>
				<LoadingIcon/>
				<LoadingIconDesc>{'LOADING SCARVES...'}</LoadingIconDesc>
			</LoadingIconContainer>
		);
	}

	private _closeTeamRenderer = () => {
		this.props.dispatch(actions.setHomeDetailTeam(-1));
	}

	private _onTrophyClickCallback = (x: number, y: number, title: string, editions: string, description: string | undefined) => {
		// if (this.props.isMobileViewActive) {
		this.props.dispatch(actions.setTrophyTooltip({ x, y, title, editions, description }));
		// }
	}

	private _closeTrophyTooltipCallback = () => {
		this.props.dispatch(actions.setTrophyTooltip(null));
	}

	private _renderTrophyTooltip = () => {
		if (!this.props.trophyTooltip) {
			return null;
		}
		return (
			<TrophyTooltip
				isMobileViewActive={this.props.isMobileViewActive}
				x={this.props.trophyTooltip.x}
				y={this.props.trophyTooltip.y}
				title={this.props.trophyTooltip.title}
				editions={this.props.trophyTooltip.editions}
				description={this.props.trophyTooltip.description}
				closeCallback={this._closeTrophyTooltipCallback}
			/>
		);
	}

	private _closeDetailTrophyTooltipCallback = () => {
		this.props.dispatch(actions.hideDetailTrophyTooltip());
	}

	private _renderDetailTrophyTooltip = () => {
		if (!this.props.detailTrophyTrophy) {
			return null;
		}
		return (
			<DetailTrophyTooltip
				detailTrophyTooltip={this.props.detailTrophyTrophy}
				closeCallback={this._closeDetailTrophyTooltipCallback}
				isMobileViewActive={this.props.isMobileViewActive}
			/>
		);
	}

	private _onSetDetailTrophyTooltip = (team: ITeam) => {
		this.props.dispatch(actions.onSetTitleDetail(team));
	}

	private _renderDetailOfUpdate = () => {
		if (!this.props.homeDetailScarfInfo) {
			return null;
		}
		const countryName = Localizer.getLocalizedCountryName(this.props.homeDetailScarfInfo.team.countryCode);
		return (
			<>
				<PopUp onOverlayClick={this._closeTeamRenderer}>
					<PopUpContainerDiv isMobileViewForPopUp={this.props.isMobileViewForPopUp}>
						<PopUpIcon
							className='fa fa-times'
							onClick={this._closeTeamRenderer}
						/>
						<TeamInfoRenderer
							scarfInfo={this.props.homeDetailScarfInfo}
							isMapInfo={false}
							closeTeamRenderer={this._closeTeamRenderer}
							isMobileViewActive={this.props.isMobileViewActive}
							section={EnumSection.home}
							onTrophyClickCallback={this._onTrophyClickCallback}
							onSetDetailTrophyTooltip={this._onSetDetailTrophyTooltip}
						/>
						<LinksContainer>
							<HomeStyledLink isMobileViewActive={this.props.isMobileViewActive}>
								<Link to={`/map?center=${this.props.homeDetailScarfInfo.team.name}`}>
									{'See on map'}
								</Link>
							</HomeStyledLink>
							<HomeStyledLink isMobileViewActive={this.props.isMobileViewActive}>
								<ImageLinkContainer src={`../../../img/flag/${this.props.homeDetailScarfInfo.team.countryCode}.png`} alt={countryName} />
								<Link to={`/${countryName}`}>
									{'See Scarves of country'}
								</Link>
							</HomeStyledLink>
						</LinksContainer>
					</PopUpContainerDiv>
				</PopUp>
				{this._renderTrophyTooltip()}
				{this._renderDetailTrophyTooltip()}
			</>
		);
	}

	public render() {
		return (
			<>
				<Helmet>
					{this._renderMetaTitle()}
					<link rel='canonical' href='https://www.scarfmuseumflavio.com' />
					<meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
					<meta name='description' content='Football scarf collection from all over the world. Football club trophies and titles shown museum alike.' />
					<meta name='viewport' content='width=device-width, initial-scale=1.0'/>
				</Helmet>
				<HomeContainer>
					<GalleryContainer>
						{this._renderDescriptionIntro()}
						{this._renderScrollButton()}
						{this._renderDetailOfUpdate()}
						<UpdatesContainer>
							<UpdatesTitle>{'NEWEST SCARVES'}</UpdatesTitle>
							{this._renderNewestScarves()}
						</UpdatesContainer>
						{this._renderMobileHomeDescription()}
					</GalleryContainer>
				</HomeContainer>
			</>
			
		);
	}
}

export default connect(rootSelector)(Home);

