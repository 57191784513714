import * as actionTypes from '../actionTypes';
import * as actions from '../actions';
import { takeEvery, call, put } from 'redux-saga/effects';
import { callApi } from '../api';
import { IDBCity, IDBCompetition, IDBEdition, IDBScarf, IDBScarfMatch, IDBStadium, IDBTeam } from '../interfaces/dbInterfaces';

function* getBasicData() {
	try {
		const info: {
			teams: IDBTeam[],
			editions: IDBEdition[],
			cities: IDBCity[],
			competitions: IDBCompetition[],
			scarves: IDBScarf[],
			stadiums: IDBStadium[],
			matchScarves: IDBScarfMatch[]
		} = yield call(callApi, 'info');
		yield put(actions.putDBInfo(info.teams, info.editions, info.cities, info.competitions, info.scarves, info.stadiums, info.matchScarves));
	} catch (error) {
	}
}

export default function fetchSaga() {
	return function* fetchSagaGenerator() {
		yield takeEvery(actionTypes.GET_BASIC_DATA, getBasicData);
	};
}
