import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import rootSelector from '../selectors';
import * as actions from '../../actions';

import SideTree from './SideTree';
import Map from './Map';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import Header from './Header';
import Home from './Home';
import { COMPETITION_LINK, MATCH_LINK, REAL_MADRID, SELECTED_COUNTRIES } from '../constants';
import TeamGalleryRoute from './TeamGalleryRoute';
import MatchGallery from './MatchGallery';
import RMMatchGallery from './RMMatchGallery';
import FilterArea from './FilterArea';
import Exchange from './Exchange';
import Link from './Link';
import CompetitionContent from './NewCompetitions';

import { IAppCombinedProps, IAppState } from '../../interfaces';
import queryString from 'query-string';

const AppContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-color: black;
	color: white;
`;

const HeaderContainer = styled.div`
	flex: 0 1 60px;
	display: flex;
	border-bottom: 1px solid #F89504
`;

const ContentContainer = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
	overflow: hidden;
	position: relative;
`;

interface IFilterAreaContaienr {
	padding: string;
}
const FilterAreaContainer = styled.div<IFilterAreaContaienr>`
	position: absolute;
	height: 100%;
	padding: ${props => props.padding};	
	display: flex;
	flex-direction: column;
	right: 0;
	transition: min-width 0.75s;
	border-left: 1px solid #F89504;
	background-color: black;
	background: url('../img/background/bck_theme.jpg') no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
`;

let _ignoreGA = false;
const history = createBrowserHistory();
ReactGA.initialize('UA-180270300-1');
history.listen((location, action) => {
	if (process.env.NODE_ENV !== 'development' && !_ignoreGA) {
		ReactGA.pageview(location.pathname + location.search);
	}
});

class App extends PureComponent<IAppCombinedProps, IAppState> {
	//#region LifeCycle
	constructor(props: IAppCombinedProps, context: any) {
		super(props, context);
		_ignoreGA = this._checkQuery();
		this.props.dispatch(actions.init());
	}


	public componentDidMount() {
		this.props.dispatch(actions.getBasicData());
		this.props.dispatch(actions.updateViewPort(window.innerWidth, window.innerHeight));
		window.addEventListener('resize', this._updateViewPort);
	}

	private _checkQuery = (): boolean => {
		const parsedQuery = queryString.parse(window.location.search);
		for (const key of Object.keys(parsedQuery)) {
			if (key.toLowerCase() === 'ignorega') {
				return true;
			}
		}
		return false;
	}

	public componentWillUnmount() {
		window.removeEventListener('resize', this._updateViewPort);
	}

	private _updateViewPort = () => {
		this.props.dispatch(actions.updateViewPort(window.innerWidth, window.innerHeight));
	}
	
	public render() {
		return (
			<AppContainer>
				<AppRouter
					shouldRenderTreeMap={!this.props.isMobileViewActive ? true : this.props.showTreeMap}
					showFilterArea={this.props.showFilterArea}
					isMobileViewActive={this.props.isMobileViewActive}
				/>
			</AppContainer>
		);
	}
}


export class AppRouter extends PureComponent<{ shouldRenderTreeMap: boolean, showFilterArea: boolean, isMobileViewActive: boolean }, { }> {

	private _renderFilterArea = () => {
		if (!this.props.showFilterArea) {
			return null;
		}
		return (
			<FilterAreaContainer padding={this.props.showFilterArea ? '20px' : '0'}>
				<FilterArea/>
			</FilterAreaContainer>
		);
	}

	public render() {
		return (
			<Router history={history}>
				<HeaderContainer>
					<Header/>
				</HeaderContainer>
				<ContentContainer>
					<Switch>
						<Route path={'/exchange'}>
							{this.props.shouldRenderTreeMap ? <SideTree/> : null}
							<Exchange/>
						</Route>
						<Route path={'/links'}>
							{this.props.shouldRenderTreeMap ? <SideTree/> : null}
							<Link/>
						</Route>
						<Route path={'/map'}>
							{this.props.shouldRenderTreeMap && this.props.isMobileViewActive ? <SideTree/> : null}
							<Map/>
						</Route>
						<Route path={`/${MATCH_LINK}`}>
							{this.props.shouldRenderTreeMap ? <SideTree/> : null}
							<MatchGallery/>
						</Route>
						<Route path={`/${REAL_MADRID}`}>
							{this.props.shouldRenderTreeMap ? <SideTree/> : null}
							<RMMatchGallery/>
						</Route>
						<Route path={`/${COMPETITION_LINK}`}>
							{this.props.shouldRenderTreeMap ? <SideTree/> : null}
							<CompetitionContent/>
						</Route>
						<Route path={'/:countryEnglishName'}>
							{this.props.shouldRenderTreeMap ? <SideTree/> : null}
							<TeamGalleryRoute/>
						</Route>
						<Route path={`/${SELECTED_COUNTRIES}`}>
							{this.props.shouldRenderTreeMap ? <SideTree/> : null}
							<TeamGalleryRoute/>
						</Route>
						<Route path={'/'}>
							{this.props.shouldRenderTreeMap ? <SideTree/> : null}
							<Home/>
						</Route>
					</Switch>
					{this.props.showFilterArea ? this._renderFilterArea() : null}
				</ContentContainer>
			</Router>
		);
		
	}
}

export default connect(rootSelector)(App);
