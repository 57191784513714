import { createSelector } from 'reselect';
import { IStore, IAppInjectedProps } from '../../interfaces';
import { EnumSection } from '../../interfaces/clientInterfaces';

const stateSelector = (state: any) => state ? state : {};

export default createSelector(
	[
		stateSelector
	],
	(
		state: IStore
	): IAppInjectedProps => {
		if (!state || !state.ui) {
			return {
				showFilterArea: false,
				isMobileViewActive: false,
				showTreeMap: false,
				teams: []
			};
		}
		return {
			showFilterArea: state.ui.showFilterArea && (state.ui.section === EnumSection.gallery || state.ui.section === EnumSection.matches || state.ui.section === EnumSection.rm),
			isMobileViewActive: state ? state.ui.isMobileViewActive : false,
			showTreeMap: state.ui.showTreeMap,
			teams: state.teams.teams
		};
	}
);
