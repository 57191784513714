import React, { PureComponent } from 'react';
import styled from 'styled-components';
import onClickOutside from 'react-onclickoutside';

interface ITrophyTooltipProps {
	x: number;
	y: number;
	title: string;
	editions: string;
	description: string | undefined;
	isMobileViewActive: boolean;
	closeCallback(): void;
}

interface ITrophyTooltipState {
	isInfoVisible: boolean;
}

interface ITrophyTooltipContainer {
	x: number;
	y: number;
}
const TrophyTooltipContainer = styled.div<ITrophyTooltipContainer>`
	position: absolute;
	left: ${props => props.x}px;
	top: ${props => props.y}px;
	font-size: 12px;
	width: 275px;
	min-height: 60px;
	border-radius: 4px;
	border: 1px  solid #F89504;
	color: white;
	text-align: center;
	text-shadow: 2px 2px 2px gold;
	background-color: black;
	padding: 8px;
	opacity: 0.8;
	user-select: none;
	z-index: 1111114;
	p {
		margin: 5px 0 0 0;
	}
`;

interface IDescriptionContainer {
	isMobileViewActive: boolean;
}
const DescriptionContainer = styled.div<IDescriptionContainer>`
	min-height: 50px;
	max-height: ${props => props.isMobileViewActive ? '60px' : '100px'};
	overflow-y: auto;
	margin-bottom: 8px;
`;

const Title = styled.div`
	font-size: 14px;
	margin-bottom: 8px;
	flex: 1;
	display: flex;
`;
const TitleText = styled.div`
	display: flex;
	margin: auto;
`;

const InfoIconContainer = styled.div`
	display: flex;
	flex: 0;
`;

const InfoIcon = styled.i`
	margin: auto;
	position: relative; 
	font-size: 15px;
	color: #F89504;
	background-color: black;
	cursor: pointer;
	padding: 2px;
	text-shadow: none;
	:hover {
		color: #F89504;
	}
`;

//#endregion

class TrophyTooltip extends PureComponent<ITrophyTooltipProps, ITrophyTooltipState> {

	constructor(props: ITrophyTooltipProps) {
		super(props);
		this.state = {
			isInfoVisible: false
		};
	}

	public handleClickOutside = (e: React.MouseEvent) => {
		this.props.closeCallback();
	};

	private _renderDescription = () => {
		if (!this.props.description || !this.state.isInfoVisible) {
			return null;
		}
		return (
			<DescriptionContainer
				isMobileViewActive={this.props.isMobileViewActive}
			>
				{this.props.description}
			</DescriptionContainer>
		);
	}

	private _onInfoButtonClick = () => {
		this.setState({
			isInfoVisible: !this.state.isInfoVisible
		});
	}

	private _renderInfoButton = () => {
		if (!this.props.description) {
			return null;
		}
		return (
			<InfoIconContainer title={'Information about comepetition'}>
				<InfoIcon
					className={'fa fa-solid fa-info'}
					onClick={this._onInfoButtonClick}
				/>
			</InfoIconContainer>
		);
	}

	public render() {
		return (
			<TrophyTooltipContainer
				x={this.props.x}
				y={this.props.y}
			>
				<Title>
					<TitleText>{this.props.title}</TitleText>
					{this._renderInfoButton()}
				</Title>
				{this._renderDescription()}
				{this.props.editions}
			</TrophyTooltipContainer>
		);
	}
}

export default onClickOutside(TrophyTooltip);
