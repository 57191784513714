import React, { PureComponent } from 'react';

interface IImageRendererProps {
	imagePath: string;
	maxWidth?: number;
	onLoadCallback?(): void;
	onUnmountCallback?(): void;
}

interface IImageRendererState {
	loaded: boolean;
	image: string;
}

export default class ImageRenderer extends PureComponent<IImageRendererProps, IImageRendererState> {
	constructor(props: IImageRendererProps, context: any) {
		super(props, context);
		this.state = {
			loaded: false,
			image: ''
		};
	}

	private _onLoad = () => {
		this.setState({ loaded: true });
		if (this.props.onLoadCallback) {
			this.props.onLoadCallback();
		}
	}
	private _onError = (e: any) => {
		if (this.props.onUnmountCallback) {
			this.props.onUnmountCallback();
		}
	}
  
	render() {
		return (
			<img
				alt={''}
				src={this.props.imagePath}
				onLoad={this._onLoad}
				onError={this._onError}
				title={''}
				style={{ maxWidth: this.props.maxWidth ? this.props.maxWidth : 1000, margin: 'auto', display: 'flex' }}
			/>
		);
	}
}
