import React from 'react';
import './index.css';
import App from './modules/components';
import { Provider } from 'react-redux';
import { render } from 'react-snapshot';

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/css/v4-shims.css';

// Store configuration for development and production.
const configureStore = process.env.NODE_ENV === 'production'
	? require('./stores/configureStore.prod').default
	: require('./stores/configureStore.dev').default;

const store = configureStore();

render(
	(
		<Provider store={store}>
			<App/>
		</Provider>
	),
	document.getElementById('root')
);

