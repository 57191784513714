import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import rootSelector from '../selectors/exchange';
import { IExchangeCombinedProps } from '../../interfaces';
import styled from 'styled-components';

const ExchangeContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	margin: auto;
	user-select: none;
	font-famnily: Europa-regular;
	background: url('../img/background/exchange.jpg') no-repeat center center fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	overflow-y: auto;
`;

const DescriptionContainer = styled.div`
	max-width: 700px;
	margin: 20px auto 40px auto;
	text-align: justify;
	text-shadow: 2px 2px 2px lightgrey;
	color: black;
	padding: 15px;
	background-color: rgba(255, 255, 255, 0.4);
	border-radius: 10px;
	b {
		font-weight: normal !important;
	}
`;

const ScarvesContainer = styled.div`
	width: 100%;
`;

const ScarfContainer = styled.div`
	margin: auto auto 30px auto;
	max-width: 600px;
`;

const NumberContainer = styled.div`
	font-size: 18px;
	text-shadow: 2px 2px 2px #F89504;
	color: black;
	text-align: center;
`;
interface IScarfImgContainer {
	isMobileViewActive: boolean;
}
const ScarfImgContainer = styled.div<IScarfImgContainer>`
	img {
		width: ${props => props.isMobileViewActive ? '90%' : '600px'};
		display: block;
		margin: auto;
	}
`;

const MailLink = styled.a`
	text-shadow: 1px 1px 1px #F89504;
	color: black;
`;

const NumberSquare = styled.div`
	border-radius: 4px;
	background-color: black;
	font-family: Europa-Big;
	color: white;
	display: inline-block;
	padding: 2px 4px;
	margin-bottom: 4px;
`;

class Exchange extends PureComponent<IExchangeCombinedProps, { }> {

	public render() {
		return (
			<ExchangeContainer>
				<DescriptionContainer>
					<b>{'The following scarves are available for exchange. If you have interest in other scarves not present in this list, write me either way in case we can reach an agreement.'}</b><br/>
					<b>{'Contact mail: '}<em><MailLink href="mailto:exchange@scarfmuseumflavio.com">exchange@scarfmuseumflavio.com</MailLink></em></b>
				</DescriptionContainer>
				<ScarvesContainer>
					{
						this.props.exchangeableScarves.map((x, i) => {
							return (
								<ScarfContainer key={`Exchange_${i}`}>
									<NumberContainer><NumberSquare>{i + 1}</NumberSquare></NumberContainer>
									<ScarfImgContainer isMobileViewActive={this.props.isMobileViewActive}>
										<img src={`../../../img/scarf/${x}.jpg`} alt={x} />
									</ScarfImgContainer>
								</ScarfContainer>
							);	
						})
					}
				</ScarvesContainer>
			</ExchangeContainer>
		);
	}
}

export default connect(rootSelector)(Exchange);

