import { INumberDictionary, IStringDictionary, ISideTreeEntry } from '../interfaces';
import { IMatchScarvesGroup } from '../interfaces/clientInterfaces';
import { IScarf, ITeam } from '../interfaces/clientInterfaces';
import { ALL_COUNTRIES_NAME, COMPETITION_LINK, COMPETITION_NAME, MATCH_NAME, REAL_MADRID, SELECT_BY_COUNTRIES, SELECT_BY_COUNTRIES_NAME } from '../modules/constants';
import { Localizer } from './localizer';

export class SideTree {

	public static getTeamSideTreeEntries = (
		countryCodes: string[],
		scarvesByTeamMap: INumberDictionary<IScarf[]>,
		teamsByCountryCode: IStringDictionary<ITeam[]>,
	): ISideTreeEntry[] => {
		const unorderedEntries = [];
		const all: ISideTreeEntry = {
			countryCode: ALL_COUNTRIES_NAME,
			name: ALL_COUNTRIES_NAME,
			totalNumber: 0,
			totalTeamsNumber: 0
		};
		for (const country of countryCodes) {
			const teamsOfCountry = teamsByCountryCode[country];
			if (teamsOfCountry) {
				let numberOfScarves = 0;
				let numberOfTeams = 0;
				for (const team of teamsOfCountry) {
					const scarvesOfTeam = scarvesByTeamMap[team.teamId];
					if (scarvesOfTeam) {
						numberOfTeams++;
						numberOfScarves += scarvesOfTeam.length;
						all.totalNumber += scarvesOfTeam.length;

					}
				}
				all.totalTeamsNumber += numberOfTeams;
				unorderedEntries.push({
					countryCode: country,
					name: Localizer.getLocalizedCountryName(country),
					totalNumber: numberOfScarves,
					totalTeamsNumber: numberOfTeams
				});

			}
		}
		return [all].concat(unorderedEntries.sort((a: ISideTreeEntry, b: ISideTreeEntry) => a.name > b.name ? 1 : -1));
	}

	public static getMatchesSideTreeEntries = (
		matchScarfGroups: IMatchScarvesGroup[],
		rMMatchScarfGroups: IMatchScarvesGroup[]
	): ISideTreeEntry[] => {
		const matches = {
			countryCode: MATCH_NAME,
			name: MATCH_NAME,
			totalNumber: matchScarfGroups.length,
			totalTeamsNumber: 0
		};
		const rMMatches = {
			countryCode: REAL_MADRID,
			name: REAL_MADRID,
			totalNumber: rMMatchScarfGroups.length,
			totalTeamsNumber: 0
		};
		const selectCountries = {
			countryCode: SELECT_BY_COUNTRIES,
			name: SELECT_BY_COUNTRIES_NAME,
			totalNumber: 0,
			totalTeamsNumber: 0
		};
		const competitions = {
			countryCode: COMPETITION_LINK,
			name: COMPETITION_NAME,
			totalNumber: 0,
			totalTeamsNumber: 0
		};
		return [matches, rMMatches, selectCountries, competitions];
	}

	public static getSideTreeEntries = (
		teamSideTreeEntries: ISideTreeEntry[],
		matchesSideEntries: ISideTreeEntry[]
	): ISideTreeEntry[] => {
		if (teamSideTreeEntries.length > 0) {
			return [teamSideTreeEntries[0], ...matchesSideEntries,].concat(teamSideTreeEntries.slice(1, teamSideTreeEntries.length));
		}
		return [...matchesSideEntries];
	}
}
