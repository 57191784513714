import { CallApiError } from './Exceptions';

export const isSecure = window.location.protocol === 'https:';

export const host = process.env.NODE_ENV === 'development'
	? 'http://localhost:8081'
	: '..';

export async function callApi(endpoint: string, method?: string, param?: any): Promise<any | null> {
	const fullUrl = `${host}/${endpoint}`;

	let response: Response;
	try {
		response = await fetch(fullUrl, {
			method: method || 'GET',
			cache: 'no-cache',
			mode: process.env.NODE_ENV === 'production' ? 'cors' : 'cors',
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
				'Accept': 'application/json; charset=utf-8',
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache'
			},
			body: JSON.stringify(param)
		});
	} catch (error) {
		throw new CallApiError(0, 'Error during API call.', { message: 'Error during API call.' });
	}

	let responseText: string;
	try {
		responseText = await response.text();
	} catch (error) {
		throw new CallApiError(response.status, response.statusText, { message: 'Error while reading response text.' });
	}

	let finalResult: any | null;
	if (responseText) {
		const contentType = response.headers.get('Content-Type');
		if (contentType && contentType.toLowerCase().indexOf('text/plain') > -1) {
			finalResult = responseText;
		} else { // Assume JSON
			try {
				finalResult = JSON.parse(responseText);
			} catch (error) {
				throw new CallApiError(response.status, response.statusText, { message: 'Error while parsing JSON.' });
			}
		}
	} else {
		finalResult = null;
	}

	if (response.ok) {
		return finalResult;
	} else {
		throw new CallApiError(response.status, response.statusText, finalResult);
	}
}
