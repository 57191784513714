import React, { PureComponent } from 'react';
import styled from 'styled-components';
import memoize from 'memoize-one';
import onClickOutside from 'react-onclickoutside';

import DropDownItemsContainer from './DropDownItemsContainer';
import DropDownItem from './DropDownItem';
import { IDropDownProps, IDropDownState, IDropDownItem } from '../interfaces';

interface IStyledDropDownProps {
	isOpen: boolean;
	disabled?: boolean;
}
const StyledDropDown = styled.div<IStyledDropDownProps>`
	display: flex;
	align-items: center;
	border-radius: 12px;
	height: 30px;
	color: black;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	border: 1px solid rgb(248, 149,	4);

	font-size: ${props => props.isOpen ? '13px' : 'inherit'};

	transition-delay: 0s, 0s;
	transition-duration: 0.15s, 0.15s;
	transition-property: border-color, box-shadow;
	transition-timing-function: ease-in-out, ease-in-out;

	background-color: rgb(255, 255, 255);

	&:focus-within {
		${props => props.disabled
		? ''
		: 'border-color: #24a7d3; box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #f89504; outline: 0;'}
	}
`;

const StyledValueContainer = styled.div`
	display: flex;
	align-items: center;
	flex: 1 1 auto;
	height: 100%;
	overflow: hidden;
	outline: 0;
	color: rgba(248,149,4,1);
	background-color: inherit;
`;

interface IStyledValueDivProps {
	disabled?: boolean;
}
const StyledValueDiv = styled.div<IStyledValueDivProps>`
	padding: 0 0 0 8px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	border: none;
	outline: 0;
	cursor: pointer;
	color: inherit;
	background-color: inherit;

	&:hover {
		${props => props.disabled ? 'opacity: 0.8; cursor: not-allowed;' : ''}
	}
`;

interface IStyledPlaceholderDivProps {
	disabled?: boolean;
}
const StyledPlaceholderDiv = styled.div<IStyledPlaceholderDivProps>`
	padding: 0 8px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	border: none;
	outline: 0;
	cursor: pointer;
	color: #ccc;
	background-color: inherit;

	&:hover {
		color: #f89504;
		${props => props.disabled ? 'opacity: 0.8; cursor: not-allowed;' : ''}
	}
`;

interface IStyledTextInputProps {
	isOpen: boolean;
}
const StyledTextInput = styled.input<IStyledTextInputProps>`
	width: 100%;
	height: 100%;

	padding: 0 8px;
	border: none;
	outline: 0;

	color: inherit;
	background-color: inherit;

	cursor: ${props => props.isOpen ? 'text' : 'pointer'};

	&::placeholder,
	&:active::placeholder,
	&:focus::placeholder,
	&:focus-within::placeholder {
		color: #ccc;
		opacity: 1;
	}

	&:hover::placeholder {
		color: #f89504;
		opacity: 1;
	}
`;

interface IStyledButtonsContainerProps {
	disabled?: boolean;
}
const StyledButtonsContainer = styled.div<IStyledButtonsContainerProps>`
	display: flex;
	align-items: center;
	flex: 0 0 auto;

	height: 100%

	${props => props.disabled ? 'opacity: 0.8; cursor: not-allowed;' : 'cursor: pointer;'}
`;

const StyledButton = styled.div`
	width: 20px;
	text-align: center;
`;

const StyledClearIcon = styled.i`
	vertical-align: middle;
	color: rgb(153, 153, 153);
`;

const StyledCaret = styled.svg`
	width: 10px;
	height: 5px;
	vertical-align: middle;
	fill: rgba(248,149,4,1);
`;

interface IStyledDropDownItemProps {
	isSelected?: boolean;
}
const StyledDropDownItem = styled.div<IStyledDropDownItemProps>`
	flex: 1 1 auto;
	height: inherit;
	padding: 0 8px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: default;

	color: ${props => props.isSelected ? 'black' : 'inherit'};
	background-color: ${props => props.isSelected ? 'rgba(248, 149,	4, 0.8)' : 'inherit'};

	&:hover {
		color: black;
		background-color: rgb(248, 149, 4);
	}
`;

const SVG_PATH_ARROW_DOWN = 'M0 0 L5 5 L10 0 Z';
const SVG_PATH_ARROW_UP = 'M0 5 L5 0 L10 5 Z';


class DropDown extends PureComponent<IDropDownProps, IDropDownState> {
	constructor(props: IDropDownProps) {
		super(props);
		this.state = {
			query: '',
			isOpen: false,
			selectedIndex: -1,
			shouldFocus: false
		};
	}

	public componentDidUpdate() {
		if (this.state.shouldFocus) {
			this._focus();
		} else {
			this._blur();
		}
	}

	private _dropDownElement: HTMLDivElement | null = null;
	private _dropDownOnGetRef = (div: HTMLDivElement | null) => this._dropDownElement = div;

	private _inputElement: HTMLInputElement | null = null;
	private _inputOnGetRef = (input: HTMLInputElement | null) => this._inputElement = input;

	private _valueContainerElement: HTMLDivElement | null = null;
	private _valueContainerOnGetRef = (div: HTMLDivElement | null) => this._valueContainerElement = div;

	private _focus = () => {
		if (this._inputElement) {
			this._inputElement.focus();
		} else if (this._valueContainerElement) {
			this._valueContainerElement.focus();
		}
	}

	private _blur = () => {
		if (this._valueContainerElement) {
			this._valueContainerElement.blur();
		}
	}

	private _showContainer = () => {
		this.setState({ isOpen: true });
	}

	private _hideContainer = () => {
		this.setState({ isOpen: false });
	}

	private _onChange = (value: string | null) => {
		if (this.props.onChange && !this.props.disabled) {
			this.props.onChange(value);
		}
	}

	private _itemOnClick = (item: IDropDownItem) => {
		this._onChange(item.value);
		this.setState({ shouldFocus: false });
	}

	private _getFilteredItems = memoize(
		(items: IDropDownItem[], query: string): IDropDownItem[] => {
			return items.filter(item => item.text.toLowerCase().includes(query.toLowerCase()));
		}
	);

	private _containerOnClose = () => {
		this._hideContainer();
		this.setState({ shouldFocus: false });
	}

	private _getItemsToRender = () => this._getFilteredItems(this.props.options, this.state.query);

	private _preventContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.preventDefault();
	}

	private _inputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this._scrollToIndex(0);
		this.setState({ query: e.target.value });
	}

	private _valueContainerOnClick = () => {
		if (this.props.disabled) {
			return;
		}
		if (this.state.isOpen) {
			this._hideContainer();
			this.setState({ shouldFocus: false });
		} else {
			this._showContainer();
			this._scrollToIndex(this._getIndexByValue(this.props.options, this.props.value));
			this.setState({
				query: '',
				shouldFocus: true
			});
		}
	}

	private _clearButtonOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (this.props.disabled) {
			return;
		}
		this._hideContainer();
		this.setState({ shouldFocus: false });
		this._onChange(null);
	}

	private _toggleButtonOnClick = () => {
		this._valueContainerOnClick();
	}

	private _scrollToIndex = (index: number) => {
		this.setState({ selectedIndex: index });
	}

	private _valueContainerOnKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (this.props.disabled) {
			e.stopPropagation();
			return;
		}
		if (e.key === 'ArrowDown') {
			e.preventDefault();
			if (this.state.isOpen) {
				if (this.state.selectedIndex < this._getItemsToRender().length - 1) {
					this._scrollToIndex(this.state.selectedIndex + 1);
				}
			} else {
				this._showContainer();
				this._scrollToIndex(this._getIndexByValue(this.props.options, this.props.value));
				this.setState({ query: '' });
			}
		} else if (e.key === 'ArrowUp') {
			e.preventDefault();
			if (this.state.isOpen) {
				if (this.state.selectedIndex > 0) {
					this._scrollToIndex(this.state.selectedIndex - 1);
				}
			} else {
				this._showContainer();
				this._scrollToIndex(this._getIndexByValue(this.props.options, this.props.value));
				this.setState({ query: '' });
			}
		} else if (e.key === 'Tab') {
			e.preventDefault();
			e.stopPropagation();
		} else {
			e.stopPropagation();
		}
	}

	private _valueContainerOnKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (this.props.disabled) {
			return;
		}
		if (e.key === 'Enter') {
			e.stopPropagation();
			if (this.state.isOpen) {
				this._hideContainer();
				const selectedItem = this._getItemsToRender()[this.state.selectedIndex];
				const selectedValue = selectedItem ? selectedItem.value : null;
				this._onChange(selectedValue);
			} else {
				this._showContainer();
				this._scrollToIndex(this._getIndexByValue(this.props.options, this.props.value));
				this.setState({ query: '' });
			}
		} else if (e.key === 'Escape' || e.key === 'Tab') {
			e.stopPropagation();
			if (this.state.isOpen) {
				this._hideContainer();
			} else {
				this.setState({ shouldFocus: false });
			}
		}
	}

	private _getIndexByValue = (items: IDropDownItem[], value: string): number => {
		if (items) {
			return items.findIndex(i => i.value === value);
		}
		return -1;
	}

	private _getItemByValue = (items: IDropDownItem[], value: string): IDropDownItem | null => {
		if (items) {
			const item = items.find(i => i.value === value);
			if (item) {
				return item;
			}
		}
		return null;
	}

	public handleClickOutside = (e: React.MouseEvent) => {
		this.setState({ shouldFocus: false });
	};

	private _renderDropDownItem = (item: IDropDownItem, index: number): JSX.Element => {
		const isSelected = this.state.selectedIndex === index;
		if (this.props.renderItem) {
			// individual rendering
			return (
				<DropDownItem
					key={index.toString()}
					index={index}
					isSelected={isSelected}
					item={item}
					onClick={this._itemOnClick}
				>
					{this.props.renderItem(item, isSelected)}
				</DropDownItem>
			);
		}

		// default rendering
		return (
			<DropDownItem
				key={index.toString()}
				index={index}
				isSelected={isSelected}
				item={item}
				onClick={this._itemOnClick}
			>
				<StyledDropDownItem
					title={item.text}
					isSelected={isSelected}
				>
					{item.text}
				</StyledDropDownItem>
			</DropDownItem>
		);
	}

	private _renderDropDownItems = (items: IDropDownItem[]): JSX.Element[] => {
		const dropDownItems: JSX.Element[] = [];
		for (let i = 0; i < items.length; i++) {
			dropDownItems.push(this._renderDropDownItem(items[i], i));
		}
		return dropDownItems;
	}

	private _renderClearButton = (): JSX.Element | null => {
		if (!this.state.isOpen &&
			!this.props.disabled &&
			this.props.clearable &&
			this.props.value) {
			return (
				<StyledButton
					onClick={this._clearButtonOnClick}
				>
					<StyledClearIcon
						className='fas fa-times'
					/>
				</StyledButton>
			);
		}
		return null;
	}

	private _renderToggleButton = (): JSX.Element | null => {
		if (this.props.hideToggleButton) {
			// do not show the ToggleButton
			return null;
		}

		const path = this.state.isOpen ? SVG_PATH_ARROW_UP : SVG_PATH_ARROW_DOWN;
		return (
			<StyledButton
				onClick={this._toggleButtonOnClick}
			>
				<StyledCaret>
					<path d={path} />
				</StyledCaret>
			</StyledButton >
		);
	}

	private _renderButtonsContainer = (): JSX.Element => {
		return (
			<StyledButtonsContainer
				disabled={this.props.disabled}
			>
				{this._renderClearButton()}
				{this._renderToggleButton()}
			</StyledButtonsContainer>
		);
	}

	private _renderValueDiv = (): JSX.Element | null => {
		const item = this._getItemByValue(this.props.options, this.props.value);
		if (!item) {
			return null;
		}

		if (this.props.renderValue) {
			// individual rendering of value
			return this.props.renderValue(item);
		}

		// default rendering of value
		return (
			<StyledValueDiv
				disabled={this.props.disabled}
				title={item.text}
			>
				{item.text}
			</StyledValueDiv>
		);
	}

	private _renderTextInput = (): JSX.Element | null => {
		return (
			<StyledTextInput
				data-copid='DropDown_Search'
				ref={this._inputOnGetRef}
				isOpen={this.state.isOpen}
				placeholder={this.props.placeholder}
				onChange={this._inputOnChange}
			/>
		);
	}

	private _renderPlaceholderDiv = (): JSX.Element | null => {
		return (
			<StyledPlaceholderDiv
				disabled={this.props.disabled}
				title={this.props.placeholder}
			>
				{this.props.placeholder}
			</StyledPlaceholderDiv>
		);
	}

	private _renderValueContainerContent = (): JSX.Element | null => {
		const searchable = this.props.searchable === undefined ? true : this.props.searchable;
		if (this.state.isOpen && searchable) {
			return this._renderTextInput();
		} else if (this.props.value) {
			return this._renderValueDiv();
		} else {
			return this._renderPlaceholderDiv();
		}
	}

	private _renderValueContainer = (): JSX.Element => {
		return (
			<StyledValueContainer
				ref={this._valueContainerOnGetRef}
				onClick={this._valueContainerOnClick}
				onKeyDown={this._valueContainerOnKeyDown}
				onKeyUp={this._valueContainerOnKeyUp}
				tabIndex={-1}
			>
				{this._renderValueContainerContent()}
			</StyledValueContainer>
		);
	}

	private _renderItemsContainer = (): JSX.Element | null => {
		if (this.state.isOpen) {
			const itemsToRender = this._getItemsToRender();
			if (itemsToRender.length > 0) {
				return (
					<DropDownItemsContainer
						refElement={this._dropDownElement}
						onClose={this._containerOnClose}
					>
						{this._renderDropDownItems(itemsToRender)}
					</DropDownItemsContainer>
				);
			}
		}
		return null;
	}

	public render(): JSX.Element {
		return (
			<>
				<StyledDropDown
					data-copid='DropDown'
					ref={this._dropDownOnGetRef}
					className={this.props.className}
					isOpen={this.state.isOpen}
					disabled={this.props.disabled}
					onContextMenu={this._preventContextMenu}
				>
					{this._renderValueContainer()}
					{this._renderButtonsContainer()}
				</StyledDropDown>
				{this._renderItemsContainer()}
			</>
		);
	}
}

export default onClickOutside(DropDown);
